import React from 'react';
import {StatusContextValue, useContextStatus} from '../common/ContextStatus';
import {useOktaAuth} from '@okta/okta-react';
import {useDeviceContext} from "../TelematicsDevice/TelematicsDeviceProvider";
import {DeviceStateApi, OutputModeEnum, OutputPolarityEnum} from "../TelematicsDevice/DeviceStateApi";
import {useAccessibilityContext} from "../AccessibilityProvider/AccessibilityProvider";


type RelayConfigurationContextValue = StatusContextValue & {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
};

const RelayConfigurationContext = React.createContext<
  RelayConfigurationContextValue | undefined
>(undefined);

type RelayConfigurationProviderProps = {
  children: React.ReactNode;
};

const RelayConfigurationProvider = (props: RelayConfigurationProviderProps) => {
  const [enabled, setEnabled] = React.useState<boolean>(false);

  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });

  const value: RelayConfigurationContextValue = React.useMemo(
    () => ({
      enabled,
      setEnabled,
      contextStatus,
      setContextStatus
    }),
    [enabled,contextStatus]
  );
  return <RelayConfigurationContext.Provider value={value} {...props} />;
};

const useRelayConfigurationContext = () => {
  const context = React.useContext(RelayConfigurationContext);
  const { authState } = useOktaAuth();
  const { identity } = useDeviceContext();
  const { accessibility } = useAccessibilityContext();

  if (!context) {
    throw new Error(
      'useRelayConfigurationContext must be used within an RelayConfigurationProvider'
    );
  }

  const status = useContextStatus(context);
  const { enabled, setEnabled, contextStatus } = context;

  const save = (requestedPolarity: OutputPolarityEnum) => {
    if (!accessibility?.relayConfiguration.visible) {
      return Promise.reject('User not allowed to toggle the relay configuration mode.');
    }
      DeviceStateApi.updateState(authState?.accessToken, identity.id,
        {
          io: {
            output1: {
              mode: OutputModeEnum.KEEP_CURRENT,
              polarity: requestedPolarity,
              period: "KEEP_CURRENT"
            }
          }
        })
        .then(() => {
          setEnabled(requestedPolarity === OutputPolarityEnum.INVERT);
        })
  };

  return {
    enabled,
    save,
    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { RelayConfigurationProvider, useRelayConfigurationContext };
