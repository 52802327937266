import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Switch, TextField} from '@material-ui/core';
import {useDeviceContext} from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import {useAnalytics} from 'components/analytics/analytics';
import {AnalyticsEvents} from 'components/analytics/analyticsEvents';
import './InputConfiguration.scss';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {withOktaAuth} from "@okta/okta-react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";
import {
    DEFAULT_THRESHOLD_HIGH, DEFAULT_THRESHOLD_LOW,
    hasPending,
    InputConfig,
    millisToVolts,
    saveInputConfiguration,
    switchToProtocol6
} from "../../stores/InputConfiguration/InputConfigurationProvider";
import {StateStatus} from "../DeviceState/StateStatus";
import {formatDateTime} from "../../../../components/TimeAndDate";
import {DeviceState, Gateway, ReportProtocolEnum} from "../../stores/TelematicsDevice/DeviceStateApi";

interface InputRow {
    index: number;
    adcVoltage: number | undefined;
    current: InputConfig
    reported: InputConfig
    updatedAt: Date | undefined;
    inputConfigState: InputConfigState;
}

type InputConfigState = InputConfig & {
    setHigh: (high: string | undefined) => void,
    setLow: (low: string | undefined) => void,
    setInvert: (high: boolean | undefined) => void
}

const useInputConfigState = () : InputConfigState => {
    const [high, setHigh] = React.useState<string | undefined>(undefined)
    const [low, setLow] = React.useState<string | undefined>(undefined)
    const [invert, setInvert] = React.useState<boolean | undefined>(undefined)

    return {high, setHigh, low, setLow, invert, setInvert};
}

const InputConfiguration: React.FC<IOktaContext> = props => {
    const deviceContext = useDeviceContext();
    const analytics = useAnalytics();
    const [showInputConfig, setShowInputConfig] = React.useState(false);
    const [showFailed, setShowFailed] = React.useState(false);
    const [inputFields, setInputFields] = React.useState<InputRow[]>([])
    const input1ConfigState = useInputConfigState()
    const input2ConfigState = useInputConfigState()
    const input3ConfigState = useInputConfigState()
    const input4ConfigState = useInputConfigState()
    const input5ConfigState = useInputConfigState()
    const input6ConfigState = useInputConfigState()

    const { accessibility } = useAccessibilityContext();

    React.useEffect(() => {
          const commonInputs = [
              {
                  index: 1,
                  adcVoltage: deviceContext.telemetry.io.input1?.voltage ? deviceContext.telemetry.io.input1.voltage : undefined,
                  current: {
                      ...deviceContext.state.desired.io?.input1?.thresholds,
                      invert: deviceContext.state.desired.io?.input1?.invert?.enabled === "true"
                  },
                  reported: {
                      ...deviceContext.state.reported.io?.input1?.thresholds,
                      invert: deviceContext.state.reported.io?.input1?.invert?.enabled === "true"
                  },
                  updatedAt: new Date(),
                  inputConfigState: input1ConfigState
              },
              {
                  index: 2,
                  adcVoltage: deviceContext.telemetry.io.input2?.voltage ? deviceContext.telemetry.io.input2.voltage : undefined,
                  current: {
                      ...deviceContext.state.desired.io?.input2?.thresholds,
                      invert: deviceContext.state.desired.io?.input2?.invert?.enabled === "true"
                  },
                  reported: {
                      ...deviceContext.state.reported.io?.input2?.thresholds,
                      invert: deviceContext.state.reported.io?.input2?.invert?.enabled === "true"
                  },
                  updatedAt: new Date(),
                  inputConfigState: input2ConfigState
              },
              {
                  index: 3,
                  adcVoltage: deviceContext.telemetry.io.input3?.voltage ? deviceContext.telemetry.io.input3.voltage : undefined,
                  current: {
                      ...deviceContext.state.desired.io?.input3?.thresholds,
                      invert: deviceContext.state.desired.io?.input3?.invert?.enabled === "true"
                  },
                  reported: {
                      ...deviceContext.state.reported.io?.input3?.thresholds,
                      invert: deviceContext.state.reported.io?.input3?.invert?.enabled === "true"
                  },
                  updatedAt: new Date(),
                  inputConfigState: input3ConfigState
              },
              {
                  index: 4,
                  adcVoltage: deviceContext.telemetry.io.input4?.voltage ? deviceContext.telemetry.io.input4.voltage : undefined,
                  current: {
                      ...deviceContext.state.desired.io?.input4?.thresholds,
                      invert: deviceContext.state.desired.io?.input4?.invert?.enabled === "true"
                  },
                  reported: {
                      ...deviceContext.state.reported.io?.input4?.thresholds,
                      invert: deviceContext.state.reported.io?.input4?.invert?.enabled === "true"
                  },
                  updatedAt: new Date(),
                  inputConfigState: input4ConfigState
              }];
          if( deviceContext.isGriffinDevice(deviceContext.identity)) {
              setInputFields([
                  ...commonInputs,
                  {
                      index: 5,
                      adcVoltage: deviceContext.telemetry.io.input5?.voltage ? deviceContext.telemetry.io.input5.voltage : undefined,
                      current: {
                          ...deviceContext.state.desired.io?.input5?.thresholds,
                          invert: deviceContext.state.desired.io?.input5?.invert?.enabled === "true"
                      },
                      reported: {
                          ...deviceContext.state.reported.io?.input5?.thresholds,
                          invert: deviceContext.state.reported.io?.input5?.invert?.enabled === "true"
                      },
                      updatedAt: new Date(),
                      inputConfigState: input5ConfigState
                  },
                  {
                      index: 6,
                      adcVoltage: deviceContext.telemetry.io.input6?.voltage ? deviceContext.telemetry.io.input6.voltage : undefined,
                      current: {
                          ...deviceContext.state.desired.io?.input6?.thresholds,
                          invert: deviceContext.state.desired.io?.input6?.invert?.enabled === "true"
                      },
                      reported: {
                          ...deviceContext.state.reported.io?.input6?.thresholds,
                          invert: deviceContext.state.reported.io?.input6?.invert?.enabled === "true"
                      },
                      updatedAt: new Date(),
                      inputConfigState: input6ConfigState
                  }]);
          }
          else {
              setInputFields(commonInputs);
          }
        }
        , [deviceContext.identity.serialNumber, deviceContext.state.desired.io, deviceContext.telemetry.io]
    );

    const showInputConfigDialog = () => {
        analytics.logEvent(AnalyticsEvents.UnitInputConfigSave, {
            serialNumber: deviceContext.identity.serialNumber
        });

        resetInputConfigState(input1ConfigState);
        resetInputConfigState(input2ConfigState);
        resetInputConfigState(input3ConfigState);
        resetInputConfigState(input4ConfigState);
        resetInputConfigState(input5ConfigState);
        resetInputConfigState(input6ConfigState);
        setShowInputConfig(true);
    }

    const resetInputConfigState = (inputConfigState: InputConfigState) => {
        inputConfigState.setHigh(undefined);
        inputConfigState.setLow(undefined);
        inputConfigState.setInvert(undefined);
    }

    const close = () => {
        setShowInputConfig(false);
    }

    const closeFailed = () => {
        setShowFailed(false);
    }

    const save = () => {
        saveInputConfiguration(
          props.authState,
          deviceContext.identity.id,
          deviceContext.state.desired,
          [
              input1ConfigState,
              input2ConfigState,
              input3ConfigState,
              input4ConfigState,
              input5ConfigState,
              input6ConfigState])
          .then(() => {
              deviceContext.reloadState();
          })
          .catch(ex =>
          {
              setShowFailed(true);
          });
    };

    const enableVoltageReporting = () => {
        switchToProtocol6(props.authState, deviceContext.identity.id)
          .then(() => {
            deviceContext.reloadState();
          })
          .catch(ex =>
          {
              setShowFailed(true);
          });
    }

    const wasLastStateThroughIrisGateway = (state: DeviceState)  => {
        return deviceContext.state.metadata.desired.io?.input1?.thresholds?.gateway === Gateway.IRIS;
    }

    const regexpDecimalNumber = /^(\\d*[\\.,])?\\d+$'/;

    return (
            <>
            <Button
                onClick={showInputConfigDialog}
                variant="contained"
                size="large"
                color="primary">
                Inputs config
            </Button>

            {showInputConfig && (
              <>

                    <Dialog
                        open={showInputConfig}
                        onClose={close}
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description">
                        <DialogTitle id="dialog-title">{`Inputs configuration`}</DialogTitle>
                        <DialogContent>
                            <FormHelperText>
                                Last updated
                                at: {deviceContext.telemetry.connectivity.transmissions.latestReceptionTime ? formatDateTime(deviceContext.telemetry.connectivity.transmissions.latestReceptionTime) : ''}
                            </FormHelperText>
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <Grid container spacing={2}
                                      justifyContent="center"
                                      alignItems="center">
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={3} style={{textAlign: 'center'}}>Real [V]</Grid>
                                    <Grid item xs={3} style={{textAlign: 'center'}}>High [V]</Grid>
                                    <Grid item xs={3} style={{textAlign: 'center'}}>Low [V]</Grid>
                                    <Grid item xs={2} style={{textAlign: 'center'}}>Invert</Grid>
                                    {inputFields.map(inputField => (
                                      <Grid item xs={12} key={inputField.index + "_input"}>
                                          <Grid container spacing={2} justifyContent="center"
                                                alignItems="center">
                                              <Grid item xs={1} key={inputField.index + "_input"}>
                                                  {inputField.index}
                                              </Grid>
                                              <Grid item xs={3} className="adcInput" key={inputField.index + "_actual"}>
                                                  <div className={true ? 'enabled' : 'disabled'}>
                                                      {inputField.adcVoltage !== undefined
                                                        ? `${millisToVolts(inputField.adcVoltage)}`
                                                        : ''}
                                                  </div>
                                              </Grid>

                                              <Grid item xs={3} key={inputField.index + "_high"}>
                                                  <TextField
                                                    className={wasLastStateThroughIrisGateway(deviceContext.state) && inputField.current.high && inputField.current.high !== inputField.reported.high ? "pendingInput" : ""}
                                                    id={"highThreshold" + inputField.index}
                                                    type="number"
                                                    disabled={accessibility?.inputConfig.readonly}
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={millisToVolts(inputField.current.high ?? inputField.reported.high ?? DEFAULT_THRESHOLD_HIGH)}
                                                    onChange={evt => {
                                                        inputField.inputConfigState.setHigh(evt.target.value);
                                                    }}
                                                    error={inputField.inputConfigState.high !== undefined && inputField.inputConfigState.high !== "" && !regexpDecimalNumber.test(inputField.inputConfigState.high)}
                                                  />
                                              </Grid>

                                              <Grid item xs={3} key={inputField.index + "_low"}>
                                                  <TextField
                                                    className={wasLastStateThroughIrisGateway(deviceContext.state) && inputField.current.low && inputField.current.low !== inputField.reported.low ? "pendingInput" : ""}
                                                    id={"lowThreshold" + inputField.index}
                                                    type="number"
                                                    disabled={accessibility?.inputConfig.readonly}
                                                    variant="outlined"
                                                    fullWidth
                                                    defaultValue={millisToVolts(inputField.current.low ?? inputField.reported.low ?? DEFAULT_THRESHOLD_LOW)}
                                                    onChange={evt => {
                                                        inputField.inputConfigState.setLow(evt.target.value);
                                                    }}
                                                    error={inputField.inputConfigState.low !== undefined && inputField.inputConfigState.high !== "" && !regexpDecimalNumber.test(inputField.inputConfigState.low)}
                                                  />
                                              </Grid>

                                              <Grid item xs={2} style={{textAlign: 'center'}}
                                                    key={inputField.index + "_invert"}>
                                                  <Switch
                                                    className={wasLastStateThroughIrisGateway(deviceContext.state) && inputField.current.invert !== inputField.reported.invert ? "pendingInput" : ""}
                                                    defaultChecked={inputField.current.invert ?? inputField.reported.invert ?? false}
                                                    disabled={accessibility?.inputConfig.readonly}
                                                    onChange={(evt) => {
                                                        inputField.inputConfigState.setInvert(evt.target.checked);
                                                    }}
                                                    color="primary"
                                                  />
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                    ))}

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={4} style={{fontWeight: 'bold'}}>Light sensor</Grid>
                                    <Grid item xs={8}>{deviceContext.telemetry.sensor.light.active ? "Detected" : "Not detected"}</Grid>

                                    <Grid item xs={4} style={{fontWeight: 'bold'}}>Active relay</Grid>
                                    <Grid item xs={8}>{deviceContext.telemetry.io.output1.loadDetect ? "Detected" : "Not detected"}</Grid>
                                </Grid>
                                <br />
                                <FormHelperText>
                                    {accessibility?.inputConfig.readonly && ("Can only be changed by owner. ")}
                                </FormHelperText>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Grid container spacing={2}
                                  alignItems="center">
                                <Grid item xs={12} sm={12} className="gridRightAlign">
                                    <div className="pendingWrapper">
                                        {!deviceContext.isGriffinDevice(deviceContext.identity) &&
                                      <>
                                          {deviceContext.state.reported.protocol?.report?.version === ReportProtocolEnum.P6
                                            ? <div className="state"><div className="done"><div>Voltage reporting enabled</div></div></div>
                                            : deviceContext.state.desired.protocol?.report?.version === ReportProtocolEnum.P6
                                              ? <div className="pendingWrapper"><div className="state"><div className="pending"><div>Enabling voltage reporting</div></div></div></div>
                                              : <></>}
                                      </>
                                    }
                                    <StateStatus
                                      getIdentityFromDesiredState={() => hasPending(deviceContext.state) ? "pending" : "" }
                                      getIdentityFromReportedState={() => ""}
                                      getGatewayUsedFromDesiredMetadataState={() => deviceContext.state.metadata.desired.io?.input1?.thresholds?.gateway}
                                      pendingText="Pending configurations"
                                      showDone={true}
                                      doneText="Successfully configured"
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7} className="gridRightAlign">
                                {!deviceContext.isGriffinDevice(deviceContext.identity) && deviceContext.state.desired.protocol?.report?.version !== ReportProtocolEnum.P6 &&
                                  <Button
                                    variant="contained"
                                    size="large"
                                    onClick={enableVoltageReporting}>
                                      Enable voltage reporting
                                  </Button>
                                }
                            </Grid>
                            <Grid item xs={12} sm={5} className="gridRightAlign">
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={close}
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    onClick={save}
                                    disabled={accessibility?.inputConfig.readonly}
                                    autoFocus
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={showFailed}
                    onClose={closeFailed}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                      <DialogTitle id="dialog-title">Save failed</DialogTitle>
                      <DialogContent>
                          Check the threshold values.
                          <br />
                          <br />* HIGH should be greater than LOW
                          <br />* HIGH and LOW is between 0 and 60
                      </DialogContent>
                      <DialogActions>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={closeFailed}
                          >
                              Close
                          </Button>
                      </DialogActions>
                  </Dialog>
              </>)
            }

        </>
    );
};

export default withOktaAuth(InputConfiguration);