import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface AlertDialogProps {
  title?: string;
  message: string;
  onDismis?: () => void;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
}

export const AlertDialog: React.FC<AlertDialogProps> = props => {
  const {
    title,
    message,
    onDismis,
    disableBackdropClick,
    disableEscapeKeyDown
  } = props;
  const [open, setOpen] = React.useState<boolean>(true);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            onDismis && onDismis();
          }}
          color="primary"
          autoFocus
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
