import {AccessToken} from "@okta/okta-auth-js";

export function getUserInfo(accessToken: AccessToken | undefined) {
  if (!accessToken) {
    return null;
  }
  const decompiled = decompileToken(accessToken.accessToken);

  return {
    userId: decompiled.userId,
    customerId: decompiled.customerId,
    login: decompiled.sub
  };
}

export function decompileToken(
  token: string
): { customerId: number; userId: number; exp: number; sub: string } {
  const tokenWithoutHeader = token.split('.')[1];
  if (!tokenWithoutHeader) {
    throw new Error('Tried to interpret invalid token');
  }
  const decoded = decodeURIComponent(atob(tokenWithoutHeader).replace(/(.)/g, (m, p) => {
    let code = p.charCodeAt(0).toString(16).toUpperCase();
    if (code.length < 2) {
      code = "0" + code;
    }
    return "%" + code;
  }));

  return JSON.parse(decoded);
}

export function isTrackUnitUser(accessToken: AccessToken | undefined) {
  if (!accessToken) {
    return null;
  }
  return [
    {customerId: 3}        // TU

  ].filter(
      r => r.customerId === getUserInfo(accessToken)?.customerId
  ).length > 0;
}