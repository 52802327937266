import React from 'react';
import { StatusContextValue, useContextStatus } from '../common/ContextStatus';
import { LegacyTelematicsDeviceApi, TelematicsDevice } from './LegacyTelematicsDeviceApi';
import {
  HALLink,
  HALLLinkResolver,
  HALLinkUpdate
} from '../common/HALLinkResolver';

import { useOktaAuth } from '@okta/okta-react';

type LegacyTelematicsDeviceContextValue = StatusContextValue & {
  telematicsDevice: TelematicsDevice | undefined;
  setTelematicsDevice: (telematicsDevice: TelematicsDevice) => void;

  updates: HALLinkUpdate[];
  setUpdates: (updates: HALLinkUpdate[]) => void;
};

const LegacyTelematicsDeviceContext = React.createContext<
  LegacyTelematicsDeviceContextValue | undefined
>(undefined);

type LegacyTelematicsDeviceProviderProps = {
  children: React.ReactNode;
};

const LegacyTelematicsDeviceProvider = (props: LegacyTelematicsDeviceProviderProps) => {
  const [telematicsDevice, setTelematicsDevice] = React.useState<
    TelematicsDevice | undefined
  >(undefined);

  const [updates, setUpdates] = React.useState<HALLinkUpdate[]>([]);

  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });

  const value: LegacyTelematicsDeviceContextValue = React.useMemo(
    () => ({
      telematicsDevice,
      setTelematicsDevice,
      updates,
      setUpdates,
      contextStatus,
      setContextStatus
    }),
    [updates, telematicsDevice, contextStatus]
  );

  return <LegacyTelematicsDeviceContext.Provider value={value} {...props} />;
};

const useLegacyTelematicsDeviceContext = () => {
  const context = React.useContext(LegacyTelematicsDeviceContext);
  const { authState } = useOktaAuth();

  if (!context) {
    throw new Error(
      'useTelematicsDeviceContext must be used within a TelematicsDeviceProvider'
    );
  }

  const status = useContextStatus(context);

  const {
    telematicsDevice,
    setTelematicsDevice,
    updates,
    setUpdates,
    contextStatus
  } = context;

  const isFirmwareVersionEqualOrHigher = (testFirmwareVersion: number) => {
    const firmwareVersion = telematicsDevice && telematicsDevice.firmwareVersion ?
        telematicsDevice.firmwareVersion : 0;
    return firmwareVersion !== 0 && firmwareVersion >= testFirmwareVersion;
  }

  const load = (serialNumber: string): Promise<void> => {
    status.loadingStarted();

    return LegacyTelematicsDeviceApi.getTelematicsDevice(
      authState?.accessToken,
      serialNumber
    )
      .then((result: TelematicsDevice) => {
        setTelematicsDevice(result);
        status.succeed();
      })
      .catch(err => {
        status.failed(err.message);
        return Promise.reject();
      });
  };

  const save = (serialNumber: string): Promise<void> => {
    status.savingStarted();

    return Promise.all(
      updates.map(update =>
        HALLLinkResolver.set(authState?.accessToken, update.link, update.body)
      )
    )
      .then(() => {
        status.succeed();
        load(serialNumber); // We have to reload to get the Requested On for the Power Profile
        return Promise.resolve();
      })
      .catch(err => {
        console.log('error', err);
        status.failed(err);
        return Promise.reject();
      });
  };

  const get = <T extends any>(link: HALLink): Promise<T> => {
    status.loadingStarted();

    return HALLLinkResolver.get<T>(authState?.accessToken, link).finally(() =>
      status.succeed()
    );
  };

  const queueUpdate = (link: HALLink, body: {}): void => {
    setUpdates([
      ...updates.filter(u => u.link.title !== link.title),
      { link, body }
    ]);
  };

  return {
    telematicsDevice,

    load,
    save,

    get,

    queueUpdate,
    isFirmwareVersionEqualOrHigher,

    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { LegacyTelematicsDeviceProvider, useLegacyTelematicsDeviceContext };
