import React from 'react';
import { Button, LinearProgress, TextField } from '@material-ui/core';
import { AlertDialog } from '../AlertDialog';
import { useGroupContext } from 'pages/Unitlookup/stores/Group/GroupProvider';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useInputFilterContext } from 'pages/Unitlookup/stores/InputFiltering/InputFilteringProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { useOutputModeContext } from 'pages/Unitlookup/stores/OutputMode/OutputModeProvider';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getUserInfo } from '../../../Login/AuthenticationService';
import { useMachineInfoContext } from '../../stores/MachineInfo/MachineInfoProvider';
import { withOktaAuth } from '@okta/okta-react';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import ReplaceButton from "../Replace/ReplaceButton";

const UnitSearch: React.FC<IOktaContext & RouteComponentProps> = props => {
  const [lastFoundSerialNumber, setLastFoundSerialNumber] = React.useState<string>('');
  const [serialNumber, setSerialNumber] = React.useState<string>('');

  const unitInfoContext = useDeviceContext();
  const groupContext = useGroupContext();
  const inputFilteringContext = useInputFilterContext();
  const outputModeContext = useOutputModeContext();
  const telematicsDeviceContext = useLegacyTelematicsDeviceContext();
  const analytics = useAnalytics();
  const machineInfoContext = useMachineInfoContext();

  const handleSearch = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (event) event.preventDefault();

    search(serialNumber);
  };

  const search = (searchArg: string) => {
    const { pathname } = props.history.location;
    const urlSearchArg = pathname.substr(pathname.lastIndexOf('/') + 1);
    if (urlSearchArg !== searchArg) {
      props.history.push(`/${searchArg}`);
      return;
    }

    // authState might be loaded later
    // This seems to happen on full page post back
    if (props.authState) {
      const userInfo = getUserInfo(props.authState?.accessToken);
      if (!userInfo) {
        throw new Error('User login not found');
      }

      setSerialNumber(searchArg);

      if (searchArg) {
        analytics.traceEvent(
          unitInfoContext.load(searchArg).then(loadingResult => {
            return Promise.all([
              groupContext.load(loadingResult.id),
              inputFilteringContext.load(searchArg),
              outputModeContext.load(searchArg),
              telematicsDeviceContext.load(searchArg),
              machineInfoContext.load(searchArg),
              setLastFoundSerialNumber(searchArg)
            ]);
          }),
          AnalyticsEvents.FindUnit,
          {
            serial: searchArg
          }
        );
      }
    }
  };

  React.useEffect(() => {
    const { pathname } = props.history.location;
    const searchArg = pathname.substr(pathname.lastIndexOf('/') + 1);
    if (searchArg && searchArg.length > 0) {
      search(searchArg);
    }
  }, [props.history.location, props.authState]);

  return (
    <div className="fullWidth">
      <form className="flex-grid">
        <TextField
          id="search"
          label="Serial Number"
          fullWidth
          variant="outlined"
          value={serialNumber}
          onChange={evt => setSerialNumber(evt.target.value)}
        />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSearch}
          type="submit"
        >
          Find
        </Button>
          {unitInfoContext.contextStatus.hasContent && serialNumber === lastFoundSerialNumber &&
          <ReplaceButton />  }
      </form>
      {(unitInfoContext.contextStatus.loading ||
        groupContext.contextStatus.loading ||
        inputFilteringContext.contextStatus.loading ||
        telematicsDeviceContext.contextStatus.loading) && <LinearProgress />}
      {unitInfoContext.contextStatus.error && (
        <AlertDialog
          title={unitInfoContext.contextStatus.error.title}
          message={unitInfoContext.contextStatus.error.message}
          onDismis={() => unitInfoContext.dismissError()}
        />
      )}
      {groupContext.contextStatus.error && (
        <AlertDialog
          title={groupContext.contextStatus.error.title}
          message={groupContext.contextStatus.error.message}
          onDismis={() => groupContext.dismissError()}
        />
      )}
    </div>
  );
};

export default withOktaAuth(withRouter(UnitSearch));
