import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LoginCallback, Security} from '@okta/okta-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { getEnviroment } from './env';
import { LocationService } from './components/Location/LocationService';
import { BrandedSecureRoute } from './components/Authentication/BrandedSecureRoute';
import {OktaAuth} from "@okta/okta-auth-js";
import {Helmet} from "react-helmet";

const REACT_APP_OKTA_ORG_URL = 'https://trackunit-portal.okta.com';

const oktaConfig = {
  issuer: `${REACT_APP_OKTA_ORG_URL}/oauth2/${getEnviroment().oktaApiKey}`,
  clientId: getEnviroment().oktaClientId,
  pkce: true,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'fms']
}

const oktaAuth = new OktaAuth(oktaConfig);

const getLogo = () => {
  if (
    LocationService.isManitouTestUrl() ||
    LocationService.isServedOnManitou()
  ) {
    return 'branding/manitou/logo.png';
  }

  return 'branding/default/logo.png';
};

const AppSecurity: React.FC = props => {
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    window.location.replace(originalUri);
  };

  return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
        <Helmet>
          <meta httpEquiv="Content-Security-Policy"
                content={"img-src 'self' data:; default-src 'self' 'unsafe-inline' " + getEnviroment().cspAllowedUrls.join(" ")}
          />
        </Helmet>
        <Switch>
          <Route path="/implicit/callback" component={LoginCallback} />
          <BrandedSecureRoute path="/" component={App} logo={getLogo()} oktaConfig={oktaConfig} />
        </Switch>
      </Security>)
}

ReactDOM.render((<Router><AppSecurity /></Router>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
