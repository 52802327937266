import React from 'react';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import GenericConfigButton from '../GenericConfiguration/GenericConfigButton';
import { AnalyticsEvents } from '../../../../components/analytics/analyticsEvents';
import { withOktaAuth } from '@okta/okta-react';
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import {
  ConfigurationTemplate,
  TelematicsDeviceConfigurationV2Api
} from '../../stores/TelematicsDeviceConfiguration/TelematicsDeviceConfigurationV2Api';
import {
  IdleCalculationModeId, ProcessingConfigurationDataMappingField,
  TelematicsDeviceInputConfigurationApi
} from '../../stores/TelematicsDeviceConfiguration/TelematicsDeviceInputConfigurationApi';
import { ConfigurationEnum } from "./Configuration";

const HiltiServiceVanConfiguration: React.FC<IOktaContext> = props => {
  const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
  const [configurationTemplate, setConfigurationTemplate] = React.useState<ConfigurationTemplate | null>(null);

  React.useEffect(() => {
    if (telematicsDevice) {
      TelematicsDeviceConfigurationV2Api.getDeviceConfigurationTemplates(
        props.authState?.accessToken,
        telematicsDevice.serialNumber
      ).then((configurationTemplates: ConfigurationTemplate[]) => {
        if( configurationTemplates) {
          const serviceVanTemplates = configurationTemplates.filter(configurationTemplate => configurationTemplate.href.includes(ConfigurationEnum.HILTI_SERVICE_VAN_CONFIGURATION_NAME));
          if (serviceVanTemplates.length === 1) {
            setConfigurationTemplate(serviceVanTemplates[0]);
          }
        }
      });
    } else {
      setConfigurationTemplate(null);
    }
  }, [telematicsDevice]);

  const executeSetupHiltiServiceVanConfiguration = () => {
    if (!!configurationTemplate && !!telematicsDevice) {
      const configuration$ = TelematicsDeviceConfigurationV2Api.pushConfigurationToDevice(props.authState?.accessToken, configurationTemplate, telematicsDevice.serialNumber);
      const inputProcessingConfiguration$ = TelematicsDeviceInputConfigurationApi.updateInputProcessingConfiguration(props.authState?.accessToken, telematicsDevice.serialNumber, {
        idleCalculationMode: IdleCalculationModeId.CAR,
        dataMappings: {
          operatingHours: ProcessingConfigurationDataMappingField.RUN_ODO,
          productiveHours: ProcessingConfigurationDataMappingField.NONE
        }
      });

      return Promise.all([configuration$, inputProcessingConfiguration$])
          .then(() => Promise.resolve())
          .catch(() => Promise.reject(new Error(`${configurationTemplate?.name} configuration failed.`)));
    }
    return Promise.reject(new Error('Missing required data to perform device setup.'));
  };

  return (
      <GenericConfigButton
        key={configurationTemplate?.href}
        visible={!!configurationTemplate}
        buttonLabel={configurationTemplate?.name ?? ""}
        confirmLabel={`Are you sure you want to execute configuration: ${configurationTemplate?.name}?`}
        analyticsEvent={AnalyticsEvents.HiltiServiceVanConfiguration}
        performConfiguration={() => executeSetupHiltiServiceVanConfiguration()}
      />
  );
};

export default withOktaAuth(HiltiServiceVanConfiguration);
