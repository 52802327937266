import React from 'react';

export interface Branding {
  company: string;
  logo: string;
  title: string;
  welcome: string;
}

export const trackunitBrand: Branding = {
  company: 'Trackunit',
  logo: '/branding/default/logo.png',
  title: 'Verify',
  welcome: 'Verify my Trackunit'
};

export const manitouBrand: Branding = {
  company: 'Manitou',
  logo: '/branding/manitou/logo.png',
  title: 'Verify my Manitou',
  welcome: 'Verify my Manitou'
};

export const BrandingContext = React.createContext<Branding>(trackunitBrand);
