export interface ContextStatus {
  loading: boolean;
  saving: boolean;
  hasContent: boolean;
  success?: { title?: string; message: string };
  error?: { title?: string; message: string };
}

export type StatusContextValue = {
  contextStatus: ContextStatus;
  setContextStatus: (ContextStatus: ContextStatus) => void;
};

export const useContextStatus = (status: StatusContextValue) => {
  const loadingStarted = () =>
    status.setContextStatus({
      loading: true,
      saving: false,
      hasContent: false
    });

  const savingStarted = () =>
    status.setContextStatus({
      loading: false,
      saving: true,
      hasContent: true
    });

  const succeed = (message?: string, title?: string) =>
    status.setContextStatus({
      loading: false,
      saving: false,
      hasContent: true,
      success: message ? { title, message } : undefined
    });

  const failed = (message?: string, title?: string) =>
    status.setContextStatus({
      loading: false,
      saving: false,
      hasContent: status.contextStatus.saving ? true : false,
      error: message ? { title, message } : undefined
    });

  const dismissError = () =>
    status.setContextStatus({
      ...status.contextStatus,
      error: undefined
    });

  const dismissSuccess = () =>
    status.setContextStatus({
      ...status.contextStatus,
      success: undefined
    });

  return {
    loadingStarted,
    savingStarted,
    succeed,
    failed,
    dismissError,
    dismissSuccess
  };
};
