import axios from 'axios';
import { defaultAxiosOptions } from './DefaultAxiosOptions';
import {AccessToken} from "@okta/okta-auth-js";

function get(
  accessToken: AccessToken | undefined,
  endpoint: string,
  onSuccess?: (result: any | undefined) => any
) {
  if( !accessToken)
    throw new Error("User is not authenticated");

  return axios
    .get<any>(endpoint, defaultAxiosOptions(accessToken))
    .then(result => {
      if (result.status >= 200 && result.status < 300 && result.data) {
        return onSuccess && onSuccess(result);
      } else if (result.status === 404) {
        return onSuccess && onSuccess(undefined);
      } else {
        console.error('Unexpected response: ', result);
        throw new Error(
          `An unexpected error occurred while trying to get data`
        );
      }
    })
    .catch(error => {
      if( error.response && error.response.data && error.response.data.message) {
        console.error(error.response.data.message)
        throw new Error(error.response.data.message);
      }
      else {
        throw new Error('An unexpected error occurred while trying to post data');
      }
    });
}

function put(
  accessToken: AccessToken | undefined,
  endpoint: string,
  data?: any,
  onSuccess?: (result: any) => any
) {
  if( !accessToken)
    throw new Error("User is not authenticated");

  return axios
    .put<any>(endpoint, data, defaultAxiosOptions(accessToken))
    .then(result => {
      if (result.status >= 200 && result.status < 300) {
        return onSuccess && onSuccess(result);
      } else {
        console.error('Unexpected response: ', result);
        throw new Error(
          `An unexpected error occurred while trying to put data`
        );
      }
    })
    .catch(error => {
      if( error.response && error.response.data && error.response.data.message) {
        console.error(error.response.data.message)
        throw new Error(error.response.data.message);
      }
      else {
        throw new Error('An unexpected error occurred while trying to post data');
      }
    });
}

function post(
  accessToken: AccessToken | undefined,
  endpoint: string,
  data?: any,
  onSuccess?: (result: any) => any
) {
  if( !accessToken)
    throw new Error("User is not authenticated");

  return axios
    .post<any>(endpoint, data, defaultAxiosOptions(accessToken))
    .then(result => {
      if (result.status >= 200 && result.status < 300) {
        return onSuccess && onSuccess(result);
      } else {
        console.error('Unexpected response: ', result);
        throw new Error(
          `An unexpected error occurred while trying to post data`
        );
      }
    })
    .catch(error => {
      if( error.response && error.response.data && error.response.data.message) {
        console.error(error.response.data.message)
        throw new Error(error.response.data.message);
      }
      else {
        throw new Error('An unexpected error occurred while trying to post data');
      }
    });
}

function remove(
  accessToken: AccessToken | undefined,
  endpoint: string,
  data?: any,
  onSuccess?: (result: any) => any
) {
  if( !accessToken)
    throw new Error("User is not authenticated");

  return axios
    .delete(endpoint, defaultAxiosOptions(accessToken))
    .then(result => {
      if (result.status >= 200 && result.status < 300) {
        return onSuccess && onSuccess(result);
      } else {
        console.error('Unexpected response: ', result);
        throw new Error(
          `An unexpected error occurred while trying to delete data`
        );
      }
    })
    .catch(error => {
      if( error.response && error.response.data && error.response.data.message) {
        console.error(error.response.data.message)
        throw new Error(error.response.data.message);
      }
      else {
        throw new Error('An unexpected error occurred while trying to post data');
      }
    });
}

export const AxiosRequest = { get, put, post, remove };
