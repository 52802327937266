import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { withOktaAuth } from '@okta/okta-react';
import FormHelperText from '@material-ui/core/FormHelperText';
import './CanProfiles.scss';
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

import { StateStatus } from '../DeviceState/StateStatus';
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";
import { configurationAccessType, DocumentationParameter } from 'pages/Unitlookup/stores/TelematicsDevice/DeviceStateApi';

const CanProfiles: React.FC<IOktaContext> = props => {
  const {
    can1ProfileId,
    setCan1ProfileId,
    can2ProfileId,
    setCan2ProfileId,
    isGriffinDevice,
    state,
    identity,
    stateDocumentation
  } = useDeviceContext();

  const { accessibility } = useAccessibilityContext();


  React.useEffect(() => {
    let isCancelled = false;

    return () => {
      isCancelled = true;
    };
  }, [props.authState?.accessToken]);

  return (
    <div>
      <div className="stateWrapper">
        <FormControl variant="outlined" fullWidth margin="normal" disabled={accessibility?.canProfile1.readonly}>
          <InputLabel
            htmlFor="can-profiles">{isGriffinDevice(identity) ? 'CAN #1 Profile' : 'CAN Profile'}</InputLabel>
          <Select
            value={can1ProfileId}
            input={
              <OutlinedInput labelWidth={isGriffinDevice(identity) ? 106 : 82} name="canProfiles"
                             id="can-profiles"/>
            }
            onChange={(evt: any) => {
              const newCanProfileId = evt.target.value;
              setCan1ProfileId(newCanProfileId);
            }
            }
          >
            <MenuItem key={0} value={undefined}>
              No CAN Profile
            </MenuItem>
            {stateDocumentation && stateDocumentation.canbus?.profiles && stateDocumentation.canbus.profiles
              .sort((a: DocumentationParameter, b: DocumentationParameter) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
              .map((profileItem: DocumentationParameter) => (
                <MenuItem key={profileItem.id.toString()} value={profileItem.id.toString()}>
                  {profileItem.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {!isGriffinDevice(identity) && (
          <StateStatus
            getIdentityFromDesiredState={() => state.desired.canbus?.profile && state.desired.canbus?.profile.id?.toString()}
            getIdentityFromReportedState={() => state.reported.canbus?.profile && state.reported.canbus?.profile.id?.toString()}
            getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.canbus?.profile?.gateway}
          />
        )}
        {isGriffinDevice(identity) && (
          <StateStatus
            getIdentityFromDesiredState={() => state.desired.canbus?.can1?.profile && state.desired.canbus?.can1?.profile.id?.toString()}
            getIdentityFromReportedState={() => state.reported.canbus?.can1?.profile && state.reported.canbus?.can1?.profile.id?.toString()}
            getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.canbus?.can1?.profile?.gateway}
          />
        )}
      </div>

      {accessibility?.canProfile2.visible && (
        <div className="stateWrapper">
          <FormControl variant="outlined" fullWidth margin="normal" disabled={accessibility.canProfile2.readonly}>
            <InputLabel htmlFor="can2-profiles">CAN #2 Profile</InputLabel>
            <Select
              value={can2ProfileId}
              input={
                <OutlinedInput labelWidth={106} name="can2Profiles" id="can2-profiles"/>
              }
              onChange={(evt: any) => {
                const newCanProfileId = evt.target.value;
                setCan2ProfileId(newCanProfileId);
              }
              }
            >
              <MenuItem key={0} value={undefined}>
                No CAN Profile
              </MenuItem>
              {stateDocumentation && stateDocumentation.canbus?.profiles && stateDocumentation.canbus.profiles
                .sort((a: DocumentationParameter, b: DocumentationParameter) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                .map((profileItem: DocumentationParameter) => (
                  <MenuItem key={profileItem.id.toString()} value={profileItem.id.toString()}>
                    {profileItem.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <StateStatus
            getIdentityFromDesiredState={() => state.desired.canbus?.can2?.profile && state.desired.canbus?.can2?.profile.id?.toString()}
            getIdentityFromReportedState={() => state.reported.canbus?.can2?.profile && state.reported.canbus?.can2?.profile.id?.toString()}
            getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.canbus?.can2?.profile?.gateway}
          />
        </div>
      )}

      {(accessibility?.canProfile1.readonly) && 
        (
          <FormHelperText id="no-can-support-help-text">{
            accessibility?.canProfile1.access === configurationAccessType.LOCKED_FOR_CHANGES 
              ? "CAN profile is locked for changes because you do not have access to the assigned CAN profile" 
              : "You do not have the necessary permissions to make this change"}</FormHelperText>
        )}
    </div>
  );

};

export default withOktaAuth(CanProfiles);
