import { AxiosRequestConfig } from 'axios';
import {AccessToken} from "@okta/okta-auth-js";

export const defaultAxiosOptions = (accessToken: AccessToken | undefined) => {
  if( !accessToken )
    throw new Error("User is not authenticated");

  return {
    headers: {
      Authorization: `Bearer ${accessToken.accessToken}`
    }
  } as AxiosRequestConfig;
};

export const axiosNoCacheOptions = (accessToken: AccessToken | undefined) => {
  if( !accessToken )
    throw new Error("User is not authenticated");

  return {
    headers: {
      Authorization: `Bearer ${accessToken.accessToken}`,
      'Cache-Control': 'no-cache'
    }
  } as AxiosRequestConfig;
};
