import React from 'react';
import { StatusContextValue, useContextStatus } from '../common/ContextStatus';
import { InputFilterResult, InputFilteringApi } from './InputFilteringApi';
import { useOktaAuth } from '@okta/okta-react';
import {useDeviceContext} from "../TelematicsDevice/TelematicsDeviceProvider";
import {DeviceStateApi} from "../TelematicsDevice/DeviceStateApi";
import {useAccessibilityContext} from "../AccessibilityProvider/AccessibilityProvider";

type InputFilterContextValue = StatusContextValue & {
  enabled: boolean | undefined;
  setEnabled: (enabled: boolean | undefined) => void;
};

const InputFilterContext = React.createContext<
  InputFilterContextValue | undefined
>(undefined);

type InputFilterProviderProps = {
  children: React.ReactNode;
};

const InputFilterProvider = (props: InputFilterProviderProps) => {
  const [enabled, setEnabled] = React.useState<boolean | undefined>(undefined);

  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });

  const value: InputFilterContextValue = React.useMemo(
    () => ({
      enabled,
      setEnabled,
      contextStatus,
      setContextStatus
    }),
    [enabled, contextStatus]
  );
  return <InputFilterContext.Provider value={value} {...props} />;
};

const useInputFilterContext = () => {
  const context = React.useContext(InputFilterContext);
  const { authState } = useOktaAuth();

  if (!context) {
    throw new Error(
      'useInputFilterContext must be used within an InputFilterProvider'
    );
  }

  const status = useContextStatus(context);
  const { enabled, setEnabled, contextStatus } = context;
  const { identity} = useDeviceContext();
  const {accessibility} = useAccessibilityContext();

  const load = (serialNumber: string | undefined): Promise<void> => {
    status.loadingStarted();

    if (serialNumber === undefined) {
      setEnabled(false);
      return Promise.resolve();
    } else {
      return InputFilteringApi.get(authState?.accessToken, serialNumber)
        .then((response: InputFilterResult) => {
          if (response.enabled === undefined || response.enabled === null) {
            setEnabled(undefined);
            status.succeed();
          } else {
            setEnabled(response.enabled);
            status.succeed();
          }
          return Promise.resolve();
        })
        .catch(err => {
          status.failed(err.message, 'Could not load input filter information');
          return Promise.reject();
        });
    }
  };

  const save = (unitId: string, newEnabled: boolean) => {
    status.savingStarted();

    if ( !accessibility?.inputFiltering.visible || newEnabled === undefined) {
      return Promise.reject('User not allowed to toggle the input filtering');
    }
    if( accessibility?.inputFiltering.writeSource === "IRIS" ) {
      DeviceStateApi.updateState(authState?.accessToken, identity.id,
        {
          io: {
            inputFiltering: {
              enabled: newEnabled ? 'true' : 'false'
            }
          }
        })
        .then(() => {
          setEnabled(newEnabled);
        })

    }
    else { // Legacy flow
      return InputFilteringApi.update(authState?.accessToken, {
        unitId,
        enabled: newEnabled
      })
        .then(() => {
          setEnabled(newEnabled);
        })
      }
  };

  return {
    enabled,

    load,
    save,

    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { InputFilterProvider, useInputFilterContext };
