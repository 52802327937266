import * as React from 'react';
import { Fade, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

import styles from './LoadingSpinner.module.scss';

interface Props {
  center?: boolean;
  white?: boolean;
}
export const LoadingSpinner: React.SFC<Props> = props => {
  return (
    <div
      className={classNames(styles.spinner, {
        [styles.centered]: props.center
      })}
    >
      <Fade
        in={true}
        style={{
          transitionDelay: '500ms',
          textAlign: 'center',
          color: props.white ? 'white' : '#FF7373'
        }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
    </div>
  );
};
