import { AxiosRequest } from './AxiosRequest';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {AccessToken} from "@okta/okta-auth-js";

export type HALLink = {
  href: string;
  title: string;
};

export type HALLinkUpdate = {
  link: HALLink;
  body: {};
};

export type HALLinkRemove = {
  link: HALLink;
};

function get<T>(accessToken: AccessToken | undefined, link: HALLink): Promise<T> {
  if( !accessToken)
    throw new Error("User not authenticated");

  return AxiosRequest.get(
    accessToken,
    link.href.replace('http://', 'https://'),
    result => result.data as T
  );
}

function set(accessToken: AccessToken | undefined, link: HALLink, body: {}): Promise<void> {
  if( !accessToken)
    throw new Error("User not authenticated");

  return AxiosRequest.put(
    accessToken,
    link.href.replace('http://', 'https://'),
    body
  );
}

function remove(accessToken: AccessToken | undefined, link: HALLink): Promise<void> {
  if( !accessToken)
    throw new Error("User not authenticated");

  return AxiosRequest.remove(accessToken, link.href.replace('http://', 'https://'));
}

function useFetch<T>(link: HALLink | undefined) {
  const [data, setData] = React.useState<T>();
  const { authState } = useOktaAuth();

  React.useEffect(() => {
    if (link) {
      HALLLinkResolver.get<T>(authState?.accessToken, link)
        .then(response => setData(response))
        .catch(error => {
          console.error(error);
          setData(undefined);
        });
    } else {
      setData(undefined);
    }
  }, [link, authState?.accessToken]);

  return data;
}

export const HALLLinkResolver = { get, set, remove, useFetch };
