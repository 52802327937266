import React from "react";
import './StateStatus.scss';
import {useLegacyTelematicsDeviceContext} from "../../stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider";
import {GatewayStateEnum} from "../../stores/LegacyTelematicsDevice/LegacyTelematicsDeviceApi";
import {useDeviceContext} from "../../stores/TelematicsDevice/TelematicsDeviceProvider";
import {Gateway} from "../../stores/TelematicsDevice/DeviceStateApi";

interface StateStatusProps {
  getIdentityFromDesiredState: () => string | undefined;
  getIdentityFromReportedState: () => string | undefined;
  getGatewayUsedFromDesiredMetadataState: () => string | undefined;
  pendingText?: string,
  doneText?: string
  showDone?: boolean,
}
export const StateStatus : React.FC<StateStatusProps> = props => {
  const {telematicsDevice} = useLegacyTelematicsDeviceContext();
  const {hasTlvSupport} = useDeviceContext();

  return <>
    {(telematicsDevice?.gatewayState === GatewayStateEnum.Iris && hasTlvSupport && props.getGatewayUsedFromDesiredMetadataState() === Gateway.IRIS) &&
      <>
        { props.getIdentityFromDesiredState() !== props.getIdentityFromReportedState() &&
          <div className="state">
              <div className="pending"><div>{props.pendingText || "pending"}</div></div>
          </div>
        }
        {props.showDone && props.getIdentityFromDesiredState() === props.getIdentityFromReportedState() &&
          <div className="state">
            <div className="done">
              <div>{props.doneText || "succeeded"}</div>
            </div>
          </div>
        }
      </>
    }
  </>
}