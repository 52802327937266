const isManitouTestUrl = () => {
  return window.location.search.includes('customer=manitou');
};

const isServedOnManitou = () => {
  return window.location.host === 'verify-easymanager.manitou.com';
};

const isManitou = () => {
    return isServedOnManitou() || isManitouTestUrl();
}

const isServedOnLocalhost = () => {
  return window.location.host.includes('localhost') || window.location.host.includes('127.0.0.1');
};

const isServedOnTrackunit = () => {
  return window.location.host === 'verify.trackunit.com';
};

const isServedOnTemporaryInstall = () => {
  return window.location.host === 'install.trackunit.com';
};

export const LocationService = {
  isManitou,
  isManitouTestUrl,
  isServedOnLocalhost,
  isServedOnManitou,
  isServedOnTrackunit,
  isServedOnTemporaryInstall
};
