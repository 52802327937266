import React from 'react';
import {Typography} from '@material-ui/core';
import MachineData from '../MachineData/MachineData';
import {UnitDetails} from '../UnitDetails/UnitDetails';
import {UnitFields} from '../UnitFields/UnitFields';
import {UnitInputs} from '../UnitInputs/UnitInputs';
import {SuccessDialog} from '../SuccessDialog';
import {useDeviceContext} from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import InputFiltering from '../InputFiltering/InputFiltering';
import {InstallationInfo} from '../Installation/InstallationInfo';
import './UnitOverview.scss';
import Save from '../Save/Save';
import GenericConfigOverview from '../GenericConfiguration/GenericConfigOverview';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {withOktaAuth} from "@okta/okta-react";
import DeviceAccessControl from "../DeviceAccessControl/DeviceAccessControl";
import OutputMode from "../OutputMode/OutputMode";
import DigitalKey from "../DigitalKey/DigitalKey";
import {AdvancedSensorsData} from "../AdvancedSensorsData/AdvancedSensorsData";
import DeviceIdentity from "../DeviceIdentity/DeviceIdentity";
import RelayConfiguration from '../RelayConfiguration/RelayConfiguration';

export const UnitOverview: React.FC<IOktaContext> = props => {
  const unitInfoContext = useDeviceContext();

  return (
    <>
      {unitInfoContext.contextStatus.success && (
        <SuccessDialog
          message={unitInfoContext.contextStatus.success.message}
          onDismis={() => unitInfoContext.dismissSuccess()}
        />
      )}
      {unitInfoContext.contextStatus.hasContent && (
        <div className="fullWidth">
          <hr />
          <Typography variant="h5" gutterBottom>
            Unit Info
          </Typography>
          <DeviceIdentity />
          <InstallationInfo />
          <UnitDetails />
          <AdvancedSensorsData />
          <UnitInputs />
          <InputFiltering />
          <OutputMode />
          <DigitalKey />
          <RelayConfiguration />
          <DeviceAccessControl />
          <UnitFields />
          <Save />
          <GenericConfigOverview />
          <MachineData />
        </div>
      )}
    </>
  );
};

export default withOktaAuth(UnitOverview)
