import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormHelperText,
  Grid,
  Switch
} from '@material-ui/core';
import './RelayConfiguration.scss';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { withOktaAuth } from "@okta/okta-react";
import { useAccessibilityContext } from "../../stores/AccessibilityProvider/AccessibilityProvider";
import { StateStatus } from "../DeviceState/StateStatus";
import { OutputPolarityEnum } from "../../stores/TelematicsDevice/DeviceStateApi";
import { useRelayConfigurationContext } from 'pages/Unitlookup/stores/RelayConfiguration/RelayConfigurationProvider';

const NC_LABEL = "Normally Closed (NC) (87a)";
const NO_LABEL = "Normally Open (NO) (87)"

const RelayConfiguration: React.FC<IOktaContext> = props => {

  const relayContext = useRelayConfigurationContext();
  const { accessibility } = useAccessibilityContext();
  const analytics = useAnalytics();
  const { identity, state, reloadState } = useDeviceContext();
  const [checked, setChecked] = React.useState<boolean>(false);
  const [confirmChange, setConfirmChange] = React.useState(false);

  function showConfirm() {
    setConfirmChange(true);
  }

  function handleConfirm() {
    analytics.traceEvent(
        Promise.all([relayContext.save(!checked ? OutputPolarityEnum.INVERT : OutputPolarityEnum.NORMAL), setConfirmChange(false)])
            .then(() => {
              setConfirmChange(false);
              setTimeout(reloadState, 1000);
            }),
        AnalyticsEvents.ChangeRelayConfiguration,
        {
          serial: identity.serialNumber,
          state: checked ? NC_LABEL : NO_LABEL
        }
    );
  }

  function handleCancel() {
    setConfirmChange(false);
  }

  React.useEffect(() => {
      setChecked(state.desired.io?.output1?.polarity === OutputPolarityEnum.INVERT)
  }, [state, accessibility?.outputMode, relayContext.enabled]);


  return accessibility?.relayConfiguration.visible ? (
          <div className="relay-configuration-wrapper">
          <FormControl variant="outlined" margin="dense">
            <dl className="staticSection m0 relay-configuration">
              <dt>
                <Grid component="label" container alignItems="center">
                  <Grid item>Relay</Grid>
                </Grid>
              </dt>
              <dd>
                <Grid component="label" container alignItems="center">
                  <Grid item>{NO_LABEL}</Grid>
                  <Grid item>
                    <Switch
                      disabled={accessibility?.relayConfiguration.readonly}
                      onChange={showConfirm}
                      color="primary"
                      checked={checked}
                    />
                  </Grid>
                  <Grid item>{NC_LABEL}</Grid>
                </Grid>
              </dd>
            </dl>
            {accessibility.relayConfiguration.accessReason === "NOT_OWNER_ACCOUNT" ? (
                <FormHelperText>Can only be changed by owner</FormHelperText>) : undefined}
          </FormControl>
          <StateStatus
            getIdentityFromDesiredState={() => state.desired.io?.output1?.polarity?.toString()}
            getIdentityFromReportedState={() => state.reported.io?.output1?.polarity?.toString()}
            getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.io?.output1?.gateway}
          />
          <Dialog
            open={confirmChange}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            <DialogTitle id="dialog-title">{`Are you sure you want to set relay configuration to ${!checked ? NC_LABEL : NO_LABEL}?`}</DialogTitle>
            <DialogActions>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleConfirm}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          </div>
  ) : null;
};

export default withOktaAuth(RelayConfiguration);
