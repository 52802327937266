export enum AnalyticsEvents {
  FindUnit = 'FindUnit',
  MachineMetadataLoaded = 'MachineMetadataLoaded',
  MachineMetadataSaved = 'MachineMetadataSaved',
  UpdateUnit = 'UpdateUnit',
  UpdatePowerProfile = 'UpdatePowerProfile',
  ChangeInFilt = 'ChangeInFilt',
  ChangeOutput1 = 'ChangeOutput1',
  ChangeDigitalKey = 'ChangeDigitalKey',
  ChangeRelayConfiguration = 'ChangeRelayConfiguration',
  ToggleInstallation = 'ToggleInstallation',
  CalibrateTiltSensor = 'CalibrateTiltSensor',
  ConfigureSkyjackBms = 'ConfigureSkyjackBms',
  CustomerCommand = 'CustomerCommand',
  UnitReplace = 'UnitReplace',
  UnitReplaceVerification = 'UnitReplaceVerification',
  UnitReplaceButtonPressed = 'UnitReplaceButtonPressed',
  UnitInputConfigSave = 'UnitInputConfigSave',
  UnitInputConfigRefresh = 'UnitInputConfigRefresh',
  UnitReboot = 'UnitReboot',
  UpdateFirmware = 'UpdateFirmware',
  EnableBluetoothTlvCommunication = 'EnableBluetoothTlvCommunication',
  BleScanButtonPressed = 'BleScanButtonPressed',
  HiltiServiceVanConfiguration = 'HiltiServiceVanConfiguration',
  Configuration = 'PublicApiConfiguration'
}
