import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CategoriesApi, Category } from 'pages/Unitlookup/stores/CategoryApi';
import { OutlinedInput } from '@material-ui/core';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { withOktaAuth } from '@okta/okta-react';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";

const Categories: React.FC<IOktaContext> = props => {
  const [categories, setCategories] = React.useState<Category[]>([]);
  const { unitInfo, setCategoryId } = useDeviceContext();

  React.useEffect(() => {
    let isCancelled = false;

    CategoriesApi.getCategories(props.authState?.accessToken).then(
      categories => {
        if (!isCancelled) {
          setCategories(categories);
        }
      }
    );

    return () => {
      isCancelled = true;
    };
  }, [props.authState?.accessToken]);

  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <InputLabel htmlFor="categoriess">Category</InputLabel>
      <Select
        value={unitInfo.categoryId ? unitInfo.categoryId : ''}
        input={
          <OutlinedInput labelWidth={62} name="categories" id="categories" />
        }
        onChange={(evt: any) => setCategoryId(evt.target.value)}
      >
        <MenuItem key={0} value={undefined}>
          No category
        </MenuItem>
        {categories.map(category => (
          <MenuItem key={category.id.toString()} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withOktaAuth(Categories);
