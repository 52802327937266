import { getEnviroment } from 'env';
import { AxiosRequest } from '../common/AxiosRequest';
import {AccessToken} from "@okta/okta-auth-js";

export interface InputFilterResult {
  unitId: string;
  enabled: boolean | undefined;
}

function get(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<InputFilterResult> {
  return AxiosRequest.get(
    accessToken,
    `${
      getEnviroment().endpoints.unitInfo
    }?includeinfilt=true&SerialNumber=${serialNumber}`,
    result => {
      const data = result.data.list[0];
      return {
        unitId: data.id,
        enabled: data.inFilt
      } as InputFilterResult;
    }
  );
}

export interface UpdateInputFilterRequest {
  unitId: string;
  enabled: boolean;
}

function update(
  accessToken: AccessToken | undefined,
  request: UpdateInputFilterRequest
): Promise<void> {
  if (request && request.unitId) {
    return AxiosRequest.get(
      accessToken,
      `${getEnviroment().endpoints.updateInputFilter}?unitid=${
        request.unitId
      }&value=${request.enabled}`
    );
  } else {
    throw new Error(
      `Unable to update input filter for unit id ${request.unitId}`
    );
  }
}

export const InputFilteringApi = { get, update };
