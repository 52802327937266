import Axios from 'axios';
import { getEnviroment } from 'env';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import { AxiosRequest } from '../common/AxiosRequest';
import {AccessToken} from "@okta/okta-auth-js";

export interface Group {
  id: string;
  name: string;
  selected: boolean;
}

function getGroups(accessToken: AccessToken | undefined, unitId: string): Promise<Group[]> {
  return AxiosRequest.get(
    accessToken,
    getEnviroment().endpoints.groups,
    result => {
      return (result.data.list as {
        id: string;
        name: string;
        units: string[];
      }[]).map(
        group =>
          ({
            id: group.id,
            name: group.name,
            selected: group.units.some(
              unitIdInGroup => unitIdInGroup === unitId
            )
          } as Group)
      );
    }
  );
}

export interface UpdateGroupRequest {
  id: string;
  RemoveUnits: string[];
  AddUnits: string[];
}

function update(
  accessToken: AccessToken | undefined,
  request: UpdateGroupRequest
): Promise<any> {
  if (request && request.id) {
    return Axios.post<void>(
      getEnviroment().endpoints.updateGroup,
      request,
      defaultAxiosOptions(accessToken)
    );
  } else {
    throw new Error(`Unable to update group: ${request.id}`);
  }
}

export const UpdateGroupApi = { getGroups, update };
