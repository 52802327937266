import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  Switch,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText
} from '@material-ui/core';
import { useInputFilterContext } from 'pages/Unitlookup/stores/InputFiltering/InputFilteringProvider';
import './InputFiltering.scss';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import {StateStatus} from "../DeviceState/StateStatus";
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {withOktaAuth} from "@okta/okta-react";
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";

const InputFiltering: React.FC<IOktaContext> = props => {
  const legacy = useInputFilterContext();
  const { unitInfo, identity, state , reloadState} = useDeviceContext();
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const analytics = useAnalytics();
  const {accessibility} = useAccessibilityContext();

  function showConfirm() {
    setConfirmChange(true);
  }

  function handleConfirm() {
    //setChecked(!checked);
    analytics.traceEvent(
      Promise.all([legacy.save(unitInfo.id, !checked), setConfirmChange(false)])
        .then(value => setTimeout(reloadState, 1000)),
      AnalyticsEvents.ChangeInFilt,
      {
        serial: identity.serialNumber,
        enabled: !checked
      }
    );
  }

  function handleCancel() {
    setConfirmChange(false);
  }

  React.useEffect(() => {
    if (accessibility?.inputFiltering.readSource === "IRIS") {
      setChecked(state.desired.io?.inputFiltering?.enabled === "true")
    }
    else {
      setChecked(legacy.enabled === true);
    }
  }, [state, legacy, accessibility?.inputFiltering]);

  return accessibility?.inputFiltering.visible ? (
    <div className="input-filtering-wrapper">
      <FormControl variant="outlined" margin="dense">
        <dl className="staticSection m0 input-filtering">
          <dt>Input Filtering:</dt>
          <dd>
            <Grid component="label" container alignItems="center">
              <Grid item>Off</Grid>
              <Grid item>
                <Switch
                  disabled={accessibility?.inputFiltering.readonly}
                  checked={checked}
                  onChange={showConfirm}
                  color="primary"
                />
              </Grid>
              <Grid item>On</Grid>
            </Grid>
          </dd>
        </dl>
      </FormControl>
      <StateStatus
        getIdentityFromDesiredState={() => (state.desired.io?.inputFiltering?.enabled === "true").toString()}
        getIdentityFromReportedState={() => (state.reported.io?.inputFiltering?.enabled === "true").toString()}
        getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.io?.inputFiltering?.gateway}
      />
      <Dialog
        open={confirmChange}
        onClose={handleCancel}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{`Are you sure you want to ${
          checked ? 'disable' : 'enable'
        } the input filtering?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            This will take effect on the next transmit for this unit. Refreshing
            the page will show the old value until the transmit has been
            completed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleConfirm}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : <></>;
};

export default withOktaAuth(InputFiltering);
