import React, { ReactNode } from 'react';
import { formatDateTime } from 'components/TimeAndDate';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useLegacyTelematicsDeviceContext } from '../../stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import {
  Battery30,
  Battery60,
  Battery90,
  BatteryCharging30,
  BatteryCharging60,
  BatteryCharging90,
  BatteryChargingFull,
  BatteryFull,
  BatteryUnknown,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularConnectedNoInternet0Bar
} from '@material-ui/icons';
import './UnitDetails.scss'

export const UnitDetails = () => {
  const {
    unitInfo,
    state,
    telemetry,
  } = useDeviceContext();

  const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
  const [firmwareLabel, setFirmwareLabel] = React.useState("");

  type NetworkTechnologyText = { [networkTechnology: string]: string };
  const networkTechnologyText: NetworkTechnologyText = {
    "NW_TECH_2G": "2G",
    "NW_TECH_3G": "3G",
    "NW_TECH_4G": "4G",
  };

  type SignalQualityText = { [signalQuality: number]: string };
  const signalQualityText: SignalQualityText = {
    1: "Poor",
    2: "Fair",
    3: "Good",
    4: "Excellent",
  };

  const getCelsius = (temperature: number | undefined) => {
      return temperature ? temperature.toFixed(1) : 0;
  }

  const getFahrenheit = (temperature: number | undefined) => {
      return temperature ? ((temperature * 1.8) + 32).toFixed(1) : 0;
  }

  React.useEffect(() => {
    if( !state.reported.firmware?.version) {
      setFirmwareLabel(telematicsDevice && telematicsDevice.firmwareVersion ? telematicsDevice.firmwareVersion.toString() : "");
    }
    else if( state.desired.firmware?.version &&
      state.desired.firmware?.version !== state.reported.firmware?.version) {
      setFirmwareLabel((state.reported.firmware?.version ?? "") + " (pending update)")
    }
    else {
      setFirmwareLabel(state.reported.firmware?.version ?? "");
    }
  }, [state, telematicsDevice]);

  const getNetworkTechnologyIcon = (networkTechnology: string | undefined) : ReactNode => {
    if (!networkTechnology || !networkTechnologyText[networkTechnology]) {
      return <></>;
    }
    return <span className="gsmNwTechIcon">{networkTechnologyText[networkTechnology]}</span>
  }

  const getSignalQualityIcon = (signalQuality: number | undefined) : ReactNode => {
    switch (signalQuality) {
      case 1:
        return <SignalCellular1Bar className="signalStrength1" />;
      case 2:
        return <SignalCellular2Bar className="signalStrength2" />;
      case 3:
        return <SignalCellular3Bar className="signalStrength3" />;
      case 4:
        return <SignalCellular4Bar className="signalStrength4" />;
      case 0:
      default:
        return <SignalCellularConnectedNoInternet0Bar className="signalStrength0" />;
    }
  }

  const getSignalQualityText = (signalQuality: number | undefined) : string => {
    if (!signalQuality || !signalQualityText[signalQuality]) {
      return "None";
    }
    return signalQualityText[signalQuality];
  }


  const getGSMSignalQuality = (signalQuality: number | undefined, networkTechnologyId: string | undefined) : ReactNode => {
    const signalQualityIcon = getSignalQualityIcon(signalQuality);
    const networkTechnologyIcon = getNetworkTechnologyIcon(networkTechnologyId);
    const signalQualityText = getSignalQualityText(signalQuality);

    return <>
      <span className="gsmQualityIcon">
        { signalQualityIcon }
      </span>
      { networkTechnologyIcon }
      <span className="gsmQualityText">
        { signalQualityText }
      </span>
    </>
  }

  const getBatteryPercentage = (percentage: number | undefined, charging: boolean | undefined) : ReactNode => {
    let icon;
    if (!percentage) {
      icon = <BatteryUnknown className="batteryUnknown" />;
    } else if (percentage < 30) {
      icon = charging ? <BatteryCharging30 className="batteryBelow30"/> : <Battery30 className="batteryBelow30"/>;
    } else if (percentage < 60) {
      icon = charging ? <BatteryCharging60 className="batteryBelow60"/> : <Battery60 className="batteryBelow60"/>;
    } else if (percentage < 90) {
      icon = charging ? <BatteryCharging90 className="batteryBelow90"/> : <Battery90 className="batteryBelow90"/>;
    } else {
      icon = charging ? <BatteryChargingFull className="battery100"/> : <BatteryFull className="battery100"/>;
    }
    return <span className="batteryIcon">{icon}</span>;
  }

  return (
    <dl className="staticSection">
      <dt>Last unit data:</dt>
      <dd>{telemetry.connectivity.transmissions.latestReceptionTime ? formatDateTime(telemetry.connectivity.transmissions.latestReceptionTime) : ''}</dd>
      <dt>Last GPS Fix:</dt>
      <dd>{telemetry.connectivity.gps.latestFixTime ? formatDateTime(telemetry.connectivity.gps.latestFixTime) : ''}</dd>
      <dt>Satellites:</dt>
      <dd>{telemetry.connectivity.gps.satellites ?? '0'}</dd>
      <dt>GPS signal:</dt>
      <dd className="labeled">
        <span>{telemetry.connectivity.gps.signalQuality || '0'}</span>
        <span>{'( > 30 = Good )'}</span>
      </dd>
      <dt>Mobile signal:</dt>
      <dd className="gsmQuality">
        {getGSMSignalQuality(telemetry.connectivity.gsm.signalQuality, telemetry.connectivity.gsm.networkTechnology)}
      </dd>
      <dt>Mobile number:</dt>
      <dd>{unitInfo.phoneNumber ? `+${unitInfo.phoneNumber}` : '0'}</dd>
      <dt>Power supply:</dt>
      <dd>{telemetry.power.external.supply ? `${(telemetry.power.external.supply / 1000).toFixed(1)}V` : '0V'}</dd>
      <dt>Battery:</dt>
      <dd className="battery">
        {getBatteryPercentage(telemetry.power.internal.percentage, telemetry.power.internal.charging)}
        {telemetry.power.internal.supply ? `${(telemetry.power.internal.supply / 1000).toFixed(1)}V` : '0V'}
      </dd>
        {telemetry.sensor.temperature1.value !== undefined && (
            <>
                <dt>Temp sensor 1:</dt>
                <dd className="labeled">
                    <span>{getCelsius(telemetry.sensor.temperature1.value)}&#8451;</span>
                    <span>( {getFahrenheit(telemetry.sensor.temperature1.value)}&#8457; )</span>
                </dd>
            </>
        )}
        {telemetry.sensor.temperature2.value !== undefined && (
            <>
                <dt>Temp sensor 2:</dt>
                <dd className="labeled">
                    <span>{getCelsius(telemetry.sensor.temperature2.value)}&#8451;</span>
                    <span>( {getFahrenheit(telemetry.sensor.temperature2.value)}&#8457; )</span>
                </dd>
            </>
        )}
      <dt>Firmware:</dt>
      <dd>{firmwareLabel}</dd>
    </dl>
  );
};
