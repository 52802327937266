import {DeviceState, DeviceStateApi, ModbusState} from "../TelematicsDevice/DeviceStateApi";
import {parseProfileToState} from "../CanProfile/CanProfileProvider";
import {AuthState} from "@okta/okta-auth-js";

export const parseProfileToModbusState = (
  modbusProfileId: string | undefined,
  state: DeviceState) : ModbusState | undefined => {

  const profileAsState = parseProfileToState(modbusProfileId, state.desired.modbus?.profile?.id);
  if( profileAsState ) {
    return {
      modbus: {
        ...profileAsState
      }
    };
  }

  return undefined;
}

export const saveModbusProfile = (
  authState: AuthState | null,
  modbusProfileId: string | undefined,
  deviceId: string,
  state: DeviceState) => {

  const desiredState = parseProfileToModbusState(modbusProfileId, state);

  if( desiredState)
  {
    return DeviceStateApi.updateState(authState?.accessToken, deviceId, {...desiredState});
  }

  return Promise.resolve();
}