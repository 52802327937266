import {
  DeviceState,
  DeviceStateApi,
  DeviceStateType,
  InputState,
  IOState,
  ReportProtocolEnum
} from "../TelematicsDevice/DeviceStateApi";
import {AuthState} from "@okta/okta-auth-js";

export type InputConfig = {
  high?: string,
  low?: string,
  invert?: boolean
}

export const millisToVolts = (input: number | string | undefined) => {
  if (input === undefined) {
    return undefined;
  } else {
    const num = typeof input === "number" ? input : parseFloat(input);
    const str = (num / 1000.0).toFixed(1);
    return Number.parseFloat(str);
  }
}

export const voltsToMillis = (input: string | undefined) => {
  if (input === undefined) {
    return undefined;
  }
  const inputNumeric = Number.parseFloat(input);
  return Math.floor(inputNumeric * 1000.0);
}

export const hasPending = (deviceState: DeviceState) => {
  return hasPendingInput(deviceState.desired.io?.input1, deviceState.reported.io?.input1)
    || hasPendingInput(deviceState.desired.io?.input2, deviceState.reported.io?.input2)
    || hasPendingInput(deviceState.desired.io?.input3, deviceState.reported.io?.input3)
    || hasPendingInput(deviceState.desired.io?.input4, deviceState.reported.io?.input4)
    || hasPendingInput(deviceState.desired.io?.input5, deviceState.reported.io?.input5)
    || hasPendingInput(deviceState.desired.io?.input6, deviceState.reported.io?.input6)
}

const hasPendingInput = (desiredInputState: InputState | undefined, reportedInputState: InputState | undefined) => {
  return (desiredInputState && desiredInputState.thresholds?.low && desiredInputState.thresholds.low !== reportedInputState?.thresholds?.low)
    || (desiredInputState && desiredInputState.thresholds?.high && desiredInputState.thresholds.high !== reportedInputState?.thresholds?.high)
    || (desiredInputState && desiredInputState.invert?.enabled !== reportedInputState?.invert?.enabled)
}

const convertToInputState = (input: InputConfig | undefined) : InputState | undefined => {
  if( input === undefined || (!input.high && !input.low && input.invert === undefined) )
    return undefined;

  const highMillis = voltsToMillis(input.high);
  const lowMillis = voltsToMillis(input.low);

  let state: InputState | undefined = {};
  if( input.high ) {
    state = {thresholds: {...state.thresholds, high: highMillis ? highMillis.toString() : undefined}}
  }

  if( input.low ) {
    state = {thresholds: {...state.thresholds, low: lowMillis ? lowMillis.toString() : undefined}}
  }

  if( input.invert !== undefined ) {
    state = {...state,
      invert: {
        enabled: input.invert ? "true" : "false"}}
  }

  return state;
}

export const convertToDesiredState = (inputConfigs: (InputConfig | undefined)[]) : IOState => {
  const desiredState: IOState = {};

  inputConfigs.forEach((input, index) => {
    const inputState = convertToInputState(input);
    if (inputState !== undefined) {
      desiredState.io = { ...desiredState.io, [`input${index + 1}`]: inputState };
    }
  });

  return desiredState;
}

export const DEFAULT_THRESHOLD_HIGH = "9500";
export const DEFAULT_THRESHOLD_LOW = "8000";

export const decorateWithDefault = (newIOState: IOState, desiredIOState: IOState | undefined): IOState => {

  const setDefaultThresholds = (inputState: InputState | undefined, desiredInputState: InputState | undefined): void => {
    if (inputState?.thresholds?.high !== undefined && !inputState.thresholds.low) {
      inputState.thresholds.low = desiredInputState?.thresholds?.low ?? DEFAULT_THRESHOLD_LOW;
    }

    if (inputState?.thresholds?.low && !inputState.thresholds?.high) {
      inputState.thresholds.high = desiredInputState?.thresholds?.high ?? DEFAULT_THRESHOLD_HIGH;
    }
  };

  setDefaultThresholds(newIOState.io?.input1, desiredIOState?.io?.input1);
  setDefaultThresholds(newIOState.io?.input2, desiredIOState?.io?.input2);
  setDefaultThresholds(newIOState.io?.input3, desiredIOState?.io?.input3);
  setDefaultThresholds(newIOState.io?.input4, desiredIOState?.io?.input4);
  setDefaultThresholds(newIOState.io?.input5, desiredIOState?.io?.input5);
  setDefaultThresholds(newIOState.io?.input6, desiredIOState?.io?.input6);

  return newIOState;
}

export const saveInputConfiguration = (
  authState: AuthState | null,
  deviceId: string,
  currentDesiredIOState: IOState | undefined,
  inputs: (InputConfig | undefined)[]): Promise<any> => {

  let ioState = convertToDesiredState( inputs );
  ioState = decorateWithDefault(ioState, currentDesiredIOState);
  if( ioState) {
    return DeviceStateApi.updateState(authState?.accessToken, deviceId, {...ioState});
  }
  return Promise.resolve();
}

export const switchToProtocol6 = (
  authState: AuthState | null,
  deviceId: string
) : Promise<any> => {
  return DeviceStateApi.updateState(authState?.accessToken, deviceId, { protocol: {report: {version: ReportProtocolEnum.P6}}});
}