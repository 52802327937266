import * as React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, CircularProgress} from '@material-ui/core';

interface Props {
    open: boolean;
    onClose: any;
    success: boolean;
    error: boolean;
    successText?: string;
    errorText?: string;
}
export const TUSpinnerProgress: React.SFC<Props> = props => {
  return (
  <>
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
          <DialogContent>
            {!props.success && !props.error && (
                <CircularProgress />
            )}
            {props.success && (
                <DialogContentText id="dialog-description">
                  {props.successText ? props.successText : 'Request was sent successfully'}
                </DialogContentText>
            )}
            {props.error && (
                <DialogContentText id="dialog-description">
                  {props.errorText ? props.errorText : 'There was an error while sending request'}
                </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            {(props.success || props.error) && (
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={props.onClose}
                    autoFocus
                >
                  OK
                </Button>
            )}
          </DialogActions>
        </Dialog>
        </>
  );
};
