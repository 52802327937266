import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { OutlinedInput } from '@material-ui/core';
import {
  PowerProfile,
  PowerProfilesApi
} from 'pages/Unitlookup/stores/TelematicsDeviceConfiguration/PowerProfileApi';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import {
  HALLink,
  HALLLinkResolver
} from 'pages/Unitlookup/stores/common/HALLinkResolver';
import { formatDateTime } from 'components/TimeAndDate';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withOktaAuth } from '@okta/okta-react';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";

type PowerConfig = {
  id: string;
  requestTime: string | undefined;
};

const PowerProfiles: React.FC<IOktaContext> = props => {
  const { telematicsDevice, queueUpdate } = useLegacyTelematicsDeviceContext();

  const [link, setLink] = React.useState<HALLink | undefined>(undefined);
  const [powerProfiles, setPowerProfiles] = React.useState<
    PowerProfile[] | undefined
  >([]);
  const data = HALLLinkResolver.useFetch<PowerConfig | undefined>(link);
  const [selectedProfileId, setSelectedProfileId] = React.useState<
    string | undefined
  >(undefined);

  const [disabled, setDisabled] = React.useState<boolean>(true);

  const FIRMWARE_VERSION_LIMIT = 62.06;

  // Get the HALLink to the Power Configuration when the Telematics Device changes
  React.useEffect(() => {
    if (telematicsDevice !== undefined) {
      const link:
        | HALLink
        | undefined = telematicsDevice._links.configurations.find(
        config => config.title === 'power-configuration'
      );
      setLink(link);

      setDisabled(telematicsDevice.firmwareVersion < FIRMWARE_VERSION_LIMIT);
    } else {
      setLink(undefined);
    }
  }, [telematicsDevice]);

  React.useEffect(() => {
    if (link) {
      // Load list of Power Profiles
      PowerProfilesApi.getPowerProfiles(props.authState?.accessToken)
        .then(profiles => {
          setPowerProfiles(profiles);
        })
        .catch(error => {
          setPowerProfiles(undefined);
        });
    } else {
      setPowerProfiles(undefined);
    }
  }, [props.authState?.accessToken, link]);

  React.useEffect(() => {
    setSelectedProfileId((data && data.id) || undefined);
  }, [data]);

  const onSelectPowerProfile = (id: string) => {
    if (link) {
      setSelectedProfileId(id);
      queueUpdate(link, { id });
    }
  };

  return link === undefined ? (
    <></>
  ) : (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        margin="normal"
        disabled={disabled}
      >
        <InputLabel htmlFor="power-profiles">Power Profile</InputLabel>
        {powerProfiles && (
          <Select
            value={selectedProfileId || ''}
            input={
              <OutlinedInput
                labelWidth={95}
                name="powerProfiles"
                id="power-profiles"
              />
            }
            onChange={(evt: any) => onSelectPowerProfile(evt.target.value)}
          >
            {powerProfiles.map(profile => (
              <MenuItem key={profile.id} value={profile.id}>
                {profile.name}
              </MenuItem>
            ))}
          </Select>
        )}
        <FormHelperText>
          {disabled && (
            <>Requires firmware version: {FIRMWARE_VERSION_LIMIT} or higher</>
          )}
          {!disabled && data && data.requestTime && (
            <>Last changed on {formatDateTime(data.requestTime)}</>
          )}
        </FormHelperText>
      </FormControl>
    </>
  );
};

export default withOktaAuth(PowerProfiles);
