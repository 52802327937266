import { HALLLinkResolver, HALLink } from '../common/HALLinkResolver';
import { TelematicsDevice } from '../LegacyTelematicsDevice/LegacyTelematicsDeviceApi';
import {AccessToken} from "@okta/okta-auth-js";

const TILT_SENSOR_CALIBRATE_CONFIGURATION_ID: string =
  'c2e7984a-5442-40b5-9b3d-f1e6e81d20fa';

const calibrateTiltSensor = (
  accessToken: AccessToken | undefined,
  link: HALLink
): Promise<void> => {
  return HALLLinkResolver.set(accessToken, link, {
    id: TILT_SENSOR_CALIBRATE_CONFIGURATION_ID
  });
};

const configureSkyjackBms = (
  accessToken: AccessToken | undefined,
  link: HALLink
): Promise<void> => {
  return HALLLinkResolver.set(accessToken, link, {
    id: ''
  });
};

const hasGenericConfiguration = (
  telematicsDevice: TelematicsDevice
): Boolean => {
  return telematicsDevice._links.configurations.some(
    c => c.title === 'generic-configuration'
  );
};

const hasSkyjackBmsConfiguration = (
  telematicsDevice: TelematicsDevice
): Boolean => {
  return telematicsDevice._links.configurations.some(
    c => c.title === 'skyjack-bms-configuration'
  );
};

export const GenericConfigurationProvider = {
  calibrateTiltSensor,
  configureSkyjackBms,
  hasGenericConfiguration,
  hasSkyjackBmsConfiguration
};
