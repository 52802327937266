import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

export interface ISparklineChartProps {
    data: any[];
    interval?: { from: Date; to: Date };
    height: number;
    width?: number;
}

export const Timeline = ({ data, interval, height, width }: ISparklineChartProps) => {
    const option: Highcharts.Options = React.useMemo(
        () => ({
            chart: {
                height: height,
                width: width,
                backgroundColor: "transparent",
            },
            title: {
                text: "",
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                enabled: true,
                formatter: function() {
                    const values: {x: number, y: number} = this as any;
                    return new Date(values.x).toLocaleString() + "<br/><b>" + Highcharts.numberFormat(values.y, 2) + "</b>";
                },
            },
            exporting: {
                enabled: false,
            },
            xAxis: {
                type: "datetime",
                labels: {
                    enabled: false,

                },
                lineColor: "transparent",
                tickLength: 0,
                min: interval?.from.getTime(),
                max: interval?.to.getTime(),
            },
            yAxis: {
                title: {
                    text: "",
                },
                labels: {
                    enabled: false,
                },
                gridLineColor: "transparent",
            },
            series: [
                {
                    type: "area",
                    enableMouseTracking: true,
                    /*color: DEFAULT_CHART_COLORS[0],*/
                    marker: {
                        enabled: true,
                    },
                    data
                },
            ],
            plotOptions: {
                area: {
                    marker: {
                        radius: 2,
                    },
                    lineWidth: 2,
                    fillOpacity: 0.1,
                    threshold: null,
                    boostThreshold: 0,
                    findNearestPointBy: "x",
                },
            },
            credits: {
                enabled: false
            }
        }),
        [data, height, interval?.from, interval?.to, width]
    );

    return data?.length > 1 ? <HighchartsReact
        highcharts={Highcharts}
        options={option} /> : null;
};
