import { LocationService } from './components/Location/LocationService';

interface environment {
  cspAllowedUrls: string[];
  oktaClientId: string;
  oktaApiKey: string;
  amplitudeApiKey?: string;
  endpoints: {
    auth: string;
    unitInfo: string;
    updateUnitVerifyTool: string;
    canProfiles: string;
    canConfig: string;
    categories: string;
    graphQlManager: string;
    groups: string;
    updateGroup: string;
    updateInputFilter: string;
    updateOutputMode: string;
    machine: string;
    telematicsDevice: string;
    telematicsDeviceV2: string;
    units: string;
    deviceAccessControl: string;
  };
}

type EnvironmentType = 'manitouProd' | 'prod' | 'stage' | 'dev' | 'local';

let environments: { [key: string]: environment } = {
  local: {
    cspAllowedUrls: [
      'https://*.trackunit.com/',
      'https://trackunit-portal.okta.com/',
      'https://api.amplitude.com/',
      'https://8kqztgrd458c.statuspage.io/'
    ],
    oktaClientId: '0oa3tk0dt9wEPF6tt357',
    oktaApiKey: 'aus2u1ctyrhWjlJXa356',
    //oktaApiKey: 'aus2u3jix1dWU7fwt356', // Temporary to test Okta SSO
    //    amplitudeApiKey: undefined,
    amplitudeApiKey: 'f1c4f26b44a3d6481e2a034dac19b24c',
    endpoints: {
      auth: 'https://irisapi.trackunit.com/authenticate',
      unitInfo: 'https://sprintapi.trackunit.com/private-v2/GetUnit',
      updateUnitVerifyTool:
        'https://sprintapi.trackunit.com/private-v2/UpdateUnitVerifyTool/',
      canProfiles:
        'https://sprintapi.trackunit.com/private-v2/GetCanProfileList',
      canConfig: 'https://dev.awsapi.trackunit.com/public/api/can-config',
      categories: 'https://sprintapi.trackunit.com/public/category',
      graphQlManager: 'https://dev.awsapi.trackunit.com/public/api/gql',
      groups: 'https://sprintapi.trackunit.com/public/group',
      updateGroup: 'https://sprintapi.trackunit.com/public/updategroup',
      updateInputFilter:
        'https://sprintapi.trackunit.com/private-v2/SetUnitInFilt',
      updateOutputMode:
        'https://sprintapi.trackunit.com/private-v2/SetUnitOutput1',
      machine: 'https://dev.awsapi.trackunit.com/public/api/machine',
      telematicsDevice:
        'https://dev.awsapi.trackunit.com/public/api/telematics-device-api',
      telematicsDeviceV2: 'https://dev.awsapi.trackunit.com/public/api/telematics-device-api/v2',
      deviceAccessControl:
        'https://dev.awsapi.trackunit.com/public/api/device-access-control-api/device-access-control',
      //units: 'https://irisapi-dev.trackunit.com/units/'
      units: 'https://irisapi.trackunit.com/units/' // Temporary to test Okta SSO
    }
  },
  dev: {
    cspAllowedUrls: [
      'https://*.trackunit.com/',
      'https://trackunit-portal.okta.com/',
      'https://api.amplitude.com/',
      'https://8kqztgrd458c.statuspage.io/'
    ],
    oktaClientId: '0oa3tk0770WZSX0tC357',
    oktaApiKey: 'aus2u1ctyrhWjlJXa356',
    //oktaApiKey: 'aus2u3jix1dWU7fwt356', // Temporary to test Okta SSO
    amplitudeApiKey: 'f1c4f26b44a3d6481e2a034dac19b24c',
    endpoints: {
      auth: 'https://irisapi.trackunit.com/authenticate',
      unitInfo: 'https://sprintapi.trackunit.com/private-v2/GetUnit',
      updateUnitVerifyTool:
        'https://sprintapi.trackunit.com/private-v2/UpdateUnitVerifyTool/',
      canProfiles:
        'https://sprintapi.trackunit.com/private-v2/GetCanProfileList',
      canConfig: 'https://dev.awsapi.trackunit.com/public/api/can-config',
      categories: 'https://sprintapi.trackunit.com/public/category',
      graphQlManager: 'https://dev.awsapi.trackunit.com/public/api/gql',
      groups: 'https://sprintapi.trackunit.com/public/group',
      updateGroup: 'https://sprintapi.trackunit.com/public/updategroup',
      updateInputFilter:
        'https://sprintapi.trackunit.com/private-v2/SetUnitInFilt',
      updateOutputMode:
        'https://sprintapi.trackunit.com/private-v2/SetUnitOutput1',
      machine: 'https://dev.awsapi.trackunit.com/public/api/machine',
      telematicsDevice:
        'https://dev.awsapi.trackunit.com/public/api/telematics-device-api',
      telematicsDeviceV2: 'https://dev.awsapi.trackunit.com/public/api/telematics-device-api/v2',
      deviceAccessControl:
        'https://dev.awsapi.trackunit.com/public/api/device-access-control-api/device-access-control',
      // units: 'https://irisapi-dev.trackunit.com/units/'
      units: 'https://irisapi.trackunit.com/units/' // Temporary to test Okta SSO
    }
  },
  stage: {
    cspAllowedUrls: [
      'https://*.trackunit.com/',
      'https://trackunit-portal.okta.com/',
      'https://api.amplitude.com/',
      'https://8kqztgrd458c.statuspage.io/'
    ],
    oktaClientId: '0oa3tk11ccEINvnzo357',
    oktaApiKey: 'aus2u2bi02B7IYj00356',
    amplitudeApiKey: '24b4b83775e4280da8d09d59973ba43c',
    endpoints: {
      auth: 'https://irisapi.trackunit.com/authenticate',
      unitInfo: 'https://api.trackunit.com/private-v2/GetUnit',
      updateUnitVerifyTool:
        'https://api.trackunit.com/private-v2/UpdateUnitVerifyTool/',
      canProfiles: 'https://api.trackunit.com/private-v2/GetCanProfileList',
      canConfig: 'https://stage.awsapi.trackunit.com/public/api/can-config',
      categories: 'https://api.trackunit.com/public/category',
      graphQlManager: 'https://stage.awsapi.trackunit.com/public/api/gql',
      groups: 'https://api.trackunit.com/public/group',
      updateGroup: 'https://api.trackunit.com/public/updategroup',
      updateInputFilter: 'https://api.trackunit.com/private-v2/SetUnitInFilt',
      updateOutputMode: 'https://api.trackunit.com/private-v2/SetUnitOutput1',
      machine: 'https://stage.awsapi.trackunit.com/public/api/machine',
      telematicsDevice:
        'https://stage.awsapi.trackunit.com/public/api/telematics-device-api',
      telematicsDeviceV2: 'https://stage.awsapi.trackunit.com/public/api/telematics-device-api/v2',
      deviceAccessControl:
        'https://stage.awsapi.trackunit.com/public/api/device-access-control-api/device-access-control',
      units: 'https://irisapi-dev.trackunit.com/units/'
    }
  },
  prod: {
    cspAllowedUrls: [
      'https://*.trackunit.com/',
      'https://trackunit-portal.okta.com/',
      'https://verify-easymanager.manitou.com/',
      'https://api.amplitude.com/',
      'https://8kqztgrd458c.statuspage.io/'
    ],
    oktaClientId: '0oa3tk8l7ay8qRLG9357',
    oktaApiKey: 'aus2u3jix1dWU7fwt356',
    amplitudeApiKey: '02a01403bf674bc3ff676471fda11874',
    endpoints: {
      auth: 'https://irisapi.trackunit.com/authenticate',
      unitInfo: 'https://api.trackunit.com/private-v2/GetUnit',
      updateUnitVerifyTool:
        'https://api.trackunit.com/private-v2/UpdateUnitVerifyTool/',
      canProfiles: 'https://api.trackunit.com/private-v2/GetCanProfileList',
      canConfig: 'https://prod.awsapi.trackunit.com/public/api/can-config',
      categories: 'https://api.trackunit.com/public/category',
      graphQlManager: 'https://iris.trackunit.com/api/graphql',
      groups: 'https://api.trackunit.com/public/group',
      updateGroup: 'https://api.trackunit.com/public/updategroup',
      updateInputFilter: 'https://api.trackunit.com/private-v2/SetUnitInFilt',
      updateOutputMode: 'https://api.trackunit.com/private-v2/SetUnitOutput1',
      machine: 'https://prod.awsapi.trackunit.com/public/api/machine',
      telematicsDevice:
        'https://prod.awsapi.trackunit.com/public/api/telematics-device-api',
      telematicsDeviceV2: 'https://prod.awsapi.trackunit.com/public/api/telematics-device-api/v2',
      deviceAccessControl:
          'https://prod.awsapi.trackunit.com/public/api/device-access-control-api/device-access-control',
      units: 'https://irisapi.trackunit.com/units/'
    }
  }
};

environments['manitouProd'] = {
  ...environments['prod']
  //oktaClientId: '0oa3tk0dt9wEPF6tt357' // Feature request could be to override the Okta client id to restrict user access
};

export const getEnviroment = (): environment => {
  const environmentType = detectEnvironment();

  if (environmentType && environmentType in environments) {
    return environments[environmentType] as environment;
  } else {
    throw new Error('Not able to map url to environment');
  }
};

function detectEnvironment(): EnvironmentType {
  const host = window.location.host;

  if (LocationService.isServedOnLocalhost()) {
    return 'local';
  }

  if (
    LocationService.isServedOnManitou() ||
    LocationService.isManitouTestUrl()
  ) {
    return 'manitouProd';
  }

  if (
    LocationService.isServedOnTrackunit() ||
    LocationService.isServedOnTemporaryInstall()
  ) {
    return 'prod';
  }

  const k8sEnvRegex = /(?:new-verify|verify).(prod|stage|dev).awsapi.trackunit.com/i;
  const hostMatches = k8sEnvRegex.exec(host);
  if (hostMatches != null) {
    return hostMatches[1] as EnvironmentType; // Index 1 in a result list is always the first matched group
  }
  throw new Error('Could not determine which endpoint to use');
}
