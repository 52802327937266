import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#f9423a'
    }
  },
  typography: {
    fontFamily: [
      'GT-Walsheim',
      'Helvetica Neue',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'sans-serif'
    ].join(',') // This shold match index.scss
  },
  overrides: {
    MuiTypography: {
      h5: {
        margin: '30px 0 0 0'
      }
    },
    MuiButton: {
      contained: {
        margin: '4px 10px'
      }
    }
  }
});
