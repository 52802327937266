import axios from 'axios';
import { getEnviroment } from 'env';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import { AccessToken } from "@okta/okta-auth-js";

const telematicsDeviceV2Endpoint = getEnviroment().endpoints.telematicsDeviceV2;

export interface DeviceTelemetry {
  id: string,
  power: {
    external: {
      supply?: number,
    },
    internal: {
      supply?: number,
      percentage?: number,
      charging?: boolean,
    },
  },
  connectivity: {
    gps: {
      satellites?: number,
      latestFixTime?: string,
      signalQuality?: number,
    },
    gsm: {
      signalQuality?: number,
      networkTechnology?: string,
    },
    transmissions: {
      latestReceptionTime?: string
    },
  },
  io: {
    input1: {
      active?: boolean,
      voltage?: number
    },
    input2: {
      active?: boolean,
      voltage?: number
    },
    input3: {
      active?: boolean,
      voltage?: number
    },
    input4: {
      active?: boolean,
      voltage?: number
    },
    input5?: {
      active?: boolean,
      voltage?: number
    },
    input6?: {
      active?: boolean,
      voltage?: number
    },
    output1: {
      active?: boolean,
      loadDetect?: boolean,
    },
  },
  sensor: {
    temperature1: {
      value?: number,
    },
    temperature2: {
      value?: number,
    },
    light: {
      active?: boolean,
    },
  },
}

async function getTelemetry(
  accessToken: AccessToken | undefined,
  deviceId: string
): Promise<DeviceTelemetry> {
  try {
    return axios
      .get<DeviceTelemetry>(`${ telematicsDeviceV2Endpoint }/devices/${ deviceId }/telemetry`, defaultAxiosOptions(accessToken))
      .then(result => Promise.resolve(result.data))
      .catch(err => Promise.resolve( {
        id: deviceId,
        power: {
          external: {},
          internal: {},
        },
        connectivity: {
          gps: {},
          gsm: {},
          transmissions: {},
        },
        io: {
          input1: {},
          input2: {},
          input3: {},
          input4: {},
          output1: {},
        },
        sensor: {
          temperature1: {},
          temperature2: {},
          light: {},
        }
      }));

  } catch (error) {
    throw new Error(`An Unexpected error occurred while trying to fetch device telemetry of '${ deviceId } - server returned no data`);
  }
}

export const DeviceTelemetryApi = { getTelemetry };
