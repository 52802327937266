import React from 'react';
import { Button } from '@material-ui/core';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useGroupContext } from 'pages/Unitlookup/stores/Group/GroupProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import { HALLink } from 'pages/Unitlookup/stores/common/HALLinkResolver';
import { withOktaAuth } from '@okta/okta-react';

import {
  InstallationProvider,
  Installation
} from 'pages/Unitlookup/stores/InstallationProvider/InstallationProvider';
import {IOktaContext} from '@okta/okta-react/bundles/types/OktaContext';
import {useDeviceAccessControlContext} from '../../stores/DeviceAccessControl/DeviceAccessControlProvider';
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";

const Save: React.FC<IOktaContext> = props => {
  const deviceContext = useDeviceContext();
  const groupContext = useGroupContext();
  const telematicsDeviceContext = useLegacyTelematicsDeviceContext();
  const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
  const deviceAccessControlContext = useDeviceAccessControlContext();
  const analytics = useAnalytics();
  const { accessibility } = useAccessibilityContext();

  const [installationLink, setInstallationLink] = React.useState<
    HALLink | undefined
  >(undefined);

  const installation: Installation | undefined = InstallationProvider.useFetch(
    installationLink
  );

  React.useEffect(() => {
    if (telematicsDevice !== undefined) {
      const link = telematicsDevice._links.installation;
      setInstallationLink(link);
    } else {
      setInstallationLink(undefined);
    }
  }, [telematicsDevice]);

  const save = () => {
    return analytics.traceEvent(
      Promise.all([
        deviceContext.save(),
        telematicsDeviceContext.save(deviceContext.identity.serialNumber),

        groupContext.save(deviceContext.unitInfo.id.toString())
      ])
        .then(() => {
          if ( accessibility?.classicAccessControl.visible && !accessibility.classicAccessControl.readonly )
            deviceAccessControlContext.save(deviceContext.identity.serialNumber)
        }),
      AnalyticsEvents.UpdateUnit,
      {
        serial: deviceContext.identity.serialNumber
      }
    );
  };

  const saveAndToggleInstall = () => {
    if (!installationLink)
      throw new Error('User not allowed to Install/Uninstall');

    InstallationProvider.toggleInstalled(
      props.authState?.accessToken,
      installationLink,
      installation
    )
      .then(() => {
        analytics.logEvent(AnalyticsEvents.ToggleInstallation, {
          success: true,
          serial: deviceContext.identity.serialNumber,
          installed: !(installation && installation.installed)
        });

        // Save all other unit information
        save();

        // Reload the Telematics Device Context to get the latest installation information
        telematicsDeviceContext.load(deviceContext.identity.serialNumber);
      })
      .catch(() =>
        analytics.logEvent(AnalyticsEvents.ToggleInstallation, {
          success: false,
          serial: deviceContext.identity.serialNumber
        })
      );
  };

  if (!deviceContext.contextStatus.hasContent) return <></>;

  return (
    <>
      <Button onClick={save} variant="contained" size="large" color="primary">
        Save
      </Button>
      {installationLink && (
        <Button
          onClick={saveAndToggleInstall}
          variant="contained"
          size="large"
          color="primary"
        >
          Save and{' '}
          {installation && installation.installed ? 'Uninstall' : 'Install'}
        </Button>
      )}
    </>
  );
};

export default withOktaAuth(Save);
