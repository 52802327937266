import * as React from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import Button, { ButtonProps } from '@material-ui/core/Button';

interface Props {
  loading?: boolean;
}
export const TUButton: React.SFC<Props & ButtonProps> = ({
  loading,
  children,
  ...other
}) => {
  if (loading) {
    other.disabled = true;
    return <LoadingSpinner />;
  }

  return (
    <Button {...other} variant="contained" size="large" color="primary">
      {children}
    </Button>
  );
};
