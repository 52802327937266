import {AuthState} from "@okta/okta-auth-js";
import {
  Profile, CanbusState,
  DeviceState,
  DeviceStateApi, ModbusState
} from "../TelematicsDevice/DeviceStateApi";
import {UnitInfo} from "../TelematicsDevice/ClassicUnitApi";

export const parseProfileToState = (
  profileId: string | undefined,
  currentProfileId: string | undefined) : Profile | undefined => {

  if( (profileId ?? "0") !== (currentProfileId ?? "0" )) {
    return {
      profile: {
        id: profileId ?? "0"
      }
    } as Profile
  }

  return undefined;
}

export const getCurrentProfileId = (
  deviceStateSource: string | undefined) : string => {

  return deviceStateSource ?? "0";
}

export const parseTU600ProfileToCanbusState = (
  canProfileId: string | undefined,
  state: DeviceState,
  unitInfo: UnitInfo) : CanbusState | undefined => {

  const profileAsState = parseProfileToState(canProfileId, getCurrentProfileId(state.desired.canbus?.profile?.id));
  if( profileAsState ) {
    return {
      canbus: {
        ...profileAsState
      }
    };
  }

  return undefined;
}

export const parseGriffinProfilesToCanbusState = (
  can1ProfileId: string | undefined,
  can2ProfileId: string | undefined,
  state: DeviceState) : CanbusState | undefined => {

  const profile1AsState = parseProfileToState(can1ProfileId, getCurrentProfileId(state.desired.canbus?.can1?.profile?.id));
  const profile2AsState = parseProfileToState(can2ProfileId, getCurrentProfileId(state.desired.canbus?.can2?.profile?.id));

  if (profile1AsState && profile2AsState) {
    return {
      canbus: {
        can1: { ...profile1AsState },
        can2: { ...profile2AsState },
      },
    };
  } else if (profile1AsState) {
    return {
      canbus: {
        can1: { ...profile1AsState },
      },
    };
  } else if (profile2AsState) {
    return {
      canbus: {
        can2: { ...profile2AsState },
      },
    };
  }

  return undefined;
}

export const saveCanProfiles = (
  authState: AuthState | null,
  can1ProfileId: string | undefined,
  can2ProfileId: string | undefined,
  deviceId: string,
  state: DeviceState,
  unitInfo: UnitInfo,
  isGriffinDevice: boolean

) : Promise<any> => {

  let desiredState : undefined | CanbusState = undefined;
  if(isGriffinDevice){
    desiredState = parseGriffinProfilesToCanbusState(can1ProfileId, can2ProfileId, state);
  }
  else {
    desiredState = parseTU600ProfileToCanbusState(can1ProfileId, state, unitInfo)
  }

  if( desiredState )
  {
    return DeviceStateApi.updateState(authState?.accessToken, deviceId, {...desiredState});
  }

  return Promise.resolve();
}