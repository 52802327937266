import { getEnviroment } from 'env';
import { AxiosRequest } from '../common/AxiosRequest';
import { AccessToken } from '@okta/okta-auth-js';

const telematicsDeviceConfigurationApiBaseUrl = getEnviroment().endpoints.telematicsDevice;
const telematicsDeviceConfigurationApiUrl = telematicsDeviceConfigurationApiBaseUrl + '/v2beta1/telematics-devices';


export enum IdleCalculationModeId {
    CAR = 'CAR',
    MACHINE = 'MACHINE',
    ENGINE_RPM = 'ENGINE_RPM',
    DISABLED = 'DISABLED'
}

export enum ProcessingConfigurationDataMappingField {
    NONE = 'NONE',
    INPUT_1 = 'INPUT_1',
    INPUT_2 = 'INPUT_2',
    INPUT_3 = 'INPUT_3',
    INPUT_4 = 'INPUT_4',
    RUN_ODO = 'RUN_ODO'
}

export class ProcessingConfigurationDataMappings {
    public operatingHours?: ProcessingConfigurationDataMappingField;
    public productiveHours?: ProcessingConfigurationDataMappingField;
}

export class ProcessingConfiguration {
    public idleCalculationMode?: IdleCalculationModeId;
    public idleMaxThreshold?: number;
    public dataMappings?: ProcessingConfigurationDataMappings;
}


function updateInputProcessingConfiguration(accessToken: AccessToken | undefined, serialNumber: string, inputProcessingConfiguration: ProcessingConfiguration) {
    const updateInputProcessingConfigurationUrl = `${telematicsDeviceConfigurationApiUrl}/${serialNumber}/input-processing-configuration`;
    return AxiosRequest.put(accessToken, `${updateInputProcessingConfigurationUrl}`, inputProcessingConfiguration);
}

export const TelematicsDeviceInputConfigurationApi = {
    updateInputProcessingConfiguration
};
