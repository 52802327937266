import * as React from "react";
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {OutlinedInput} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {DocumentationParameter} from "../../stores/TelematicsDevice/DeviceStateApi";
import {StateStatus} from "../DeviceState/StateStatus";
import {useDeviceContext} from "../../stores/TelematicsDevice/TelematicsDeviceProvider";
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";
import {withOktaAuth} from "@okta/okta-react";

const ModbusProfiles: React.FC<IOktaContext> = props => {
  const {
    isGriffinDevice,
    state,
    identity,
    stateDocumentation,
    setModbusProfileId
  } = useDeviceContext();

  const { accessibility } = useAccessibilityContext();

  return accessibility?.modbusProfile.visible ?
  (
    <div>
      <div className="stateWrapper">
        <FormControl variant="outlined" fullWidth margin="normal" disabled={accessibility?.canProfile1.readonly}>
          <InputLabel htmlFor="modbus-profiles">Modbus profile</InputLabel>
          <Select
            defaultValue={state.desired.modbus?.profile?.id}
            input={
              <OutlinedInput labelWidth={110} name="modbusProfiles"
                             id="modbus-profiles"/>
            }
            readOnly={accessibility?.modbusProfile.readonly}
            onChange={(evt: any) => {
              const newProfileId = evt.target.value;
              setModbusProfileId(newProfileId);
            }
            }
          >
            <MenuItem key={0} value={undefined}>
              No Modbus Profile
            </MenuItem>
            {stateDocumentation && stateDocumentation.modbus?.profiles && stateDocumentation.modbus.profiles
              .sort((a: DocumentationParameter, b: DocumentationParameter) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
              .map((profileItem: DocumentationParameter) => (
                <MenuItem key={profileItem.id.toString()} value={profileItem.id.toString()}>
                  {profileItem.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <StateStatus
          getIdentityFromDesiredState={() => state.desired.modbus?.profile && state.desired.modbus?.profile.id?.toString()}
          getIdentityFromReportedState={() => state.reported.modbus?.profile && state.reported.modbus?.profile.id?.toString()}
          getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.modbus?.profile?.gateway}
        />
      </div>
    </div>) : (<></>)
}

export default withOktaAuth(ModbusProfiles);