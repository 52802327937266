import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch
} from '@material-ui/core';
import './OutputMode.scss';
import {useDeviceContext} from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import {useOutputModeContext} from 'pages/Unitlookup/stores/OutputMode/OutputModeProvider';
import {useAnalytics} from 'components/analytics/analytics';
import {AnalyticsEvents} from 'components/analytics/analyticsEvents';
import FormHelperText from '@material-ui/core/FormHelperText';
import {StateStatus} from "../DeviceState/StateStatus";
import {OutputModeEnum} from "../../stores/TelematicsDevice/DeviceStateApi";
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {withOktaAuth} from "@okta/okta-react";
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";

const OutputMode: React.FC<IOktaContext> = props => {
  const legacy = useOutputModeContext();
  const { unitInfo, identity, state , reloadState} = useDeviceContext();
  const [confirmChange, setConfirmChange] = React.useState(false);
  const [checked, setChecked] = React.useState<boolean>(false);
  const analytics = useAnalytics();
  const { accessibility } = useAccessibilityContext();

  function showConfirm() {
    setConfirmChange(true);
  }

  function handleConfirm() {
    analytics.traceEvent(
      Promise.all([legacy.save( unitInfo.id, !checked), setConfirmChange(false)])
        .then(value => setTimeout(reloadState, 1000)),
      AnalyticsEvents.ChangeOutput1,
      {
        serial: identity.serialNumber,
        enabled: !checked
      }
    );
  }

  function handleCancel() {
    setConfirmChange(false);
  }

  React.useEffect(() => {
    if (accessibility?.outputMode.readSource === "IRIS") {
      setChecked(state.desired.io?.output1?.mode === OutputModeEnum.HIGH)
    }
    else {
      setChecked(legacy.enabled === true);
    }
  }, [state, accessibility?.outputMode, legacy.enabled]);

  return accessibility?.outputMode.visible ? (
    <div className="output-mode-wrapper">
      <FormControl variant="outlined" margin="dense">
        <dl className="staticSection m0 output-mode">
          <dt>Output Mode:</dt>
          <dd>
            <Grid component="label" container alignItems="center">
              <Grid item>Off</Grid>
              <Grid item>
                <Switch
                  disabled={accessibility?.outputMode.readonly}
                  checked={checked}
                  onChange={showConfirm}
                  color="primary"
                />
              </Grid>
              <Grid item>On</Grid>
            </Grid>
          </dd>
        </dl>
        {accessibility?.outputMode.readonly && (
          <FormHelperText>Can only be changed by owner</FormHelperText>
        )}
      </FormControl>
      <StateStatus
        getIdentityFromDesiredState={() => state.desired.io?.output1?.mode?.toString()}
        getIdentityFromReportedState={() => state.reported.io?.output1?.mode?.toString()}
        getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.io?.output1?.gateway}
      />
      <Dialog
        open={confirmChange}
        onClose={handleCancel}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{`Are you sure you want to ${
          checked ? 'disable' : 'enable'
        } the output mode?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            This will take effect on the next transmit for this unit. Refreshing
            the page will show the old value until the transmit has been
            completed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleConfirm}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : <></>;
};

export default withOktaAuth(OutputMode);
