import axios from 'axios';
import { getEnviroment } from 'env';
import {
  defaultAxiosOptions,
  axiosNoCacheOptions
} from '../common/DefaultAxiosOptions';
import { AxiosRequest } from '../common/AxiosRequest';
import { sortByString } from '../common/Sorting';
import {AccessToken} from "@okta/okta-auth-js";

const telematicsDevicesEndpoint = getEnviroment().endpoints.telematicsDevice + '/telematics-devices';
const machineEndpoint = getEnviroment().endpoints.machine;

export interface MachineMetaData {
  brand: string;
  model: string;
  category: string;
  vin: string;
  productionDate: string;
}

export interface MachineExtendedMetaData {
  extendedInformation: Array<ExtendedInfo>;
}

export interface ExtendedInfo {
  key: string;
  value: string;
}

export interface MachineInfo extends MachineMetaData, MachineExtendedMetaData {}

export interface UpdateMachineMetaData extends MachineMetaData {
  machineId: number;
}

export interface Brand {
  name: string;
}

export interface Category {
  id: number;
  name: string;
}

let cachedBrands: Brand[];
let cachedCategories: Category[];

function getMachineId(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<string> {
  return axios
    .get<any>(
      `${telematicsDevicesEndpoint}/${serialNumber}/machine-id`,
      axiosNoCacheOptions(accessToken)
    )
    .then(restResult => {
      if (200 <= restResult.status && restResult.status < 300) {
        return restResult.data as string;
      } else {
        console.error('Unexpected response: ', restResult);
        throw new Error(
          `An unexpected error occured while trying to fetch the machine id of unit '${serialNumber}'`
        );
      }
    })
    .catch(error => {
      if (!error.response) {
        throw new Error(
          `An unexpected error occured while trying to fetch the machine id of unit '${serialNumber}' - server returned no data`
        );
      } else {
        switch (error.response.data.responseStatus.errorCode) {
          case 'UnitNotFound':
            throw new Error(
              `The requested unit ('${serialNumber}') was not found`
            );
          default:
            throw new Error(
              `An unexpected error occured while trying to fetch the machine id of unit '${serialNumber}`
            );
        }
      }
    });
}

function getMachineMetadataForId(
  accessToken: AccessToken | undefined,
  machineId: string
): Promise<MachineMetaData> {
  return axios.get<MachineMetaData>(
      `${machineEndpoint}/machines/${machineId}`,
      defaultAxiosOptions(accessToken)
    )
    .then(restResult => {
      if (200 <= restResult.status && restResult.status < 300) {
        return restResult.data as MachineMetaData;
      } else {
        console.error('Unexpected response: ', restResult);
        throw new Error(
          `An unexpected error occured while trying to fetch machine '${machineId}`
        );
      }
    })
    .catch(error => {
      if (!error.response) {
        throw new Error(
          `An unexpected error occured while trying to fetch machine '${machineId} - server returned no data`
        );
      } else {
        switch (error.response.data.responseStatus.errorCode) {
          case 'UnitNotFound':
            throw new Error(
              `The requested machine ('${machineId}') was not found`
            );
          default:
            throw new Error(
              `An unexpected error occured while trying to fetch machine '${machineId}`
            );
        }
      }
    });
}

function mapToExtendedInfoArray(obj: Object): Array<ExtendedInfo> {
  return Object.entries(obj).map(([key, value]) => {
    return { key, value } as ExtendedInfo;
  });
}

function mapToExtendedInfoMap(extendedInfo: Array<ExtendedInfo>): Object {
  const map: any = {};
  extendedInfo.forEach(e => {
    map[e.key] = e.value;
  });
  return map;
}

function getMachineExtendedInfo(
  accessToken: AccessToken | undefined,
  machineId: string
): Promise<MachineExtendedMetaData> {
  return axios.get<MachineExtendedMetaData>(
      `${machineEndpoint}/machines/${machineId}/extended-information`,
      defaultAxiosOptions(accessToken)
    )
    .then(restResult => {
      if (200 <= restResult.status && restResult.status < 300) {
        return {
          extendedInformation: mapToExtendedInfoArray(
            restResult.data.extendedInformation
          )
        } as MachineExtendedMetaData;
      } else {
        console.error('Unexpected response: ', restResult);
        throw new Error(
          `An unexpected error occured while trying to fetch machine '${machineId}`
        );
      }
    })
    .catch(error => {
      if (!error.response) {
        throw new Error(
          `An unexpected error occured while trying to fetch machine '${machineId} - server returned no data`
        );
      } else {
        switch (error.response.data.responseStatus.errorCode) {
          case 'UnitNotFound':
            throw new Error(
              `The requested machine ('${machineId}') was not found`
            );
          default:
            throw new Error(
              `An unexpected error occured while trying to fetch machine '${machineId}`
            );
        }
      }
    });
}

function updateMachineMetadataForId(
  accessToken: AccessToken | undefined,
  machineId: string,
  machineMetadata: MachineMetaData
) {
  const payload: UpdateMachineMetaData = {
    machineId: parseInt(machineId),
    model: machineMetadata.model,
    brand: machineMetadata.brand,
    category: machineMetadata.category,
    vin: machineMetadata.vin,
    productionDate: machineMetadata.productionDate
  };
  return AxiosRequest.put(
    accessToken,
    `${machineEndpoint}/machines/${machineId}`,
    payload
  );
}

function updateMachineExtendedMetadataForId(
  accessToken: AccessToken | undefined,
  machineId: string,
  extendedMetadata: MachineExtendedMetaData
) {
  const payload = {
    _links: {
      self: {
        href: ''
      }
    },
    extendedInformation: mapToExtendedInfoMap(
      extendedMetadata.extendedInformation
    )
  };
  return AxiosRequest.put(
    accessToken,
    `${machineEndpoint}/machines/${machineId}/extended-information`,
    payload
  );
}

function getMachineInfo(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<MachineInfo> {
  return getMachineId(accessToken, serialNumber).then(
    async id => {
      return Promise.all([
        getMachineMetadataForId(accessToken, id),
        getMachineExtendedInfo(accessToken, id)
      ]).then(([machineMetaData, machineExtendedMetaData]) => {
        return { ...machineMetaData, ...machineExtendedMetaData };
      });
    }
  );
}

function updateMachineInfo(
  accessToken: AccessToken | undefined,
  serialNumber: string,
  machineInfo: MachineInfo,
  saveExtendedMetadata: boolean
) {
  return getMachineId(accessToken, serialNumber).then(
    machineId => {
      const promises = [
        updateMachineMetadataForId(
          accessToken,
          machineId,
          machineInfo as MachineMetaData
        )
      ];
      if (saveExtendedMetadata && machineInfo.extendedInformation) {
        promises.push(
          updateMachineExtendedMetadataForId(
            accessToken,
            machineId,
            machineInfo as MachineExtendedMetaData
          )
        );
      }
      return Promise.all(promises);
    }
  );
}

function getBrands(accessToken: AccessToken | undefined): Promise<Brand[]> {
  if (cachedBrands) {
    return Promise.resolve(cachedBrands);
  }

  return AxiosRequest.get(accessToken, `${machineEndpoint}/brands`, result => {
    const brands = result.data.brands as Brand[];
    cachedBrands = sortByString(brands, brand => brand.name);
    return cachedBrands;
  });
}

function getCategories(accessToken: AccessToken | undefined): Promise<Category[]> {
  if (cachedCategories) {
    return Promise.resolve(cachedCategories);
  }

  return AxiosRequest.get(
    accessToken,
    `${machineEndpoint}/categories`,
    result => {
      const categories = result.data.categories as Category[];
      cachedCategories = sortByString(categories, category => category.name);
      return cachedCategories;
    }
  );
}

export const MachineInfoApi = {
  getMachineInfo,
  updateMachineInfo,
  getBrands,
  getCategories
};
