import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {
  useDeviceContext
} from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import './GenericConfigButton.scss';
import {UnitInfo} from "../../stores/TelematicsDevice/ClassicUnitApi";

interface Props {
  visible: Boolean;
  analyticsEvent: AnalyticsEvents;
  buttonLabel: String;
  confirmLabel: String;
  performConfiguration(): Promise<void>;
  prerequisiteFulfilled?(unitInfo: UnitInfo): Boolean;
  prerequisiteFailureLabel?: String;
}

export const GenericConfigButton: React.FC<Props> = props => {
  const deviceContext = useDeviceContext();
  const analytics = useAnalytics();

  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showConfirmNotification, setShowExecuted] = React.useState(false);
  const [showPrerequisiteFailure, setShowPrerequisiteFailure] = React.useState(
    false
  );
  const [showFailed, setShowFailed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | JSX.Element>('');

  const cancel = () => setShowConfirm(false);
  const closeConfirmNotification = () => setShowExecuted(false);
  const closePrerequisiteFailure = () => setShowPrerequisiteFailure(false);
  const closeFailed = () => {
    setShowFailed(false);
    setErrorMessage('');
  }

  const confirm = () => {
    if (
      props.prerequisiteFulfilled &&
      props.prerequisiteFailureLabel &&
      !props.prerequisiteFulfilled(deviceContext.unitInfo)
    ) {
      setShowPrerequisiteFailure(true);
    } else {
      setShowConfirm(true);
    }
  };

  const configure = () => {
    setShowConfirm(false);

    props
      .performConfiguration()
      .then(() => {
        setShowExecuted(true);
        analytics.logEvent(props.analyticsEvent, {
          success: true,
          serial: deviceContext.identity.serialNumber
        });
      })
      .catch((ex) => {
        setErrorMessage(decorateError( ex.message));
        setShowFailed(true);

        analytics.logEvent(props.analyticsEvent, {
          success: false,
          serial: deviceContext.identity.serialNumber
        })
      }
    );
  };

  const decorateError = (error: string) => {
    let description;
    let action;

    if( error.includes("not allowed for the current CAN profile") ) {
      description = "This might be caused by a can profile currently being deployed.";
      action = "Wait a few seconds and try again."
    }

    return (
      <div className="error">
        <div className="title">{error}</div>
        {description && (<div className="description">{description}</div>)}
        {action && (<div className="action">{action}</div>)}
      </div>
    );
  }

  return (
    props.visible && (
      <>
        <Button
          onClick={confirm}
          variant="contained"
          size="large"
          color="primary"
        >
          {props.buttonLabel}
        </Button>

        <Dialog
          open={showConfirm}
          onClose={cancel}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{props.confirmLabel}</DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={cancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={configure}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showConfirmNotification}
          onClose={closeConfirmNotification}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{`Configuration is being processed.`}</DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={closeConfirmNotification}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showPrerequisiteFailure}
          onClose={closePrerequisiteFailure}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">
            {props.prerequisiteFailureLabel}
          </DialogTitle>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={closePrerequisiteFailure}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showFailed}
          onClose={closeFailed}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">Error</DialogTitle>
          <DialogContent>
            {errorMessage}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={closeFailed}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  );
};

export default GenericConfigButton;
