import { getEnviroment } from 'env';
import { AxiosRequest } from '../common/AxiosRequest';
import { HALLink } from '../common/HALLinkResolver';
import { AccessToken } from "@okta/okta-auth-js";

export type AsyncOperationResponse = {
    trackingId: string;
};

export type PredefinedCustomerCommand = {
    commandId: string;
    label: string;
    customerId: number;
    sortOrder: number;
    canProfileId: number;
    minimumFirmwareVersion: number;
    maximumFirmwareVersion: number;
    groupId: number;
    groupLabel: string;
    link: HALLink;
};

export type PredefinedCustomerCommandRequest = {
    commandId: string;
    serialNumbers: string[];
};

export type DeviceReplaceRequest = {
    fromSerialNumber: string;
    toSerialNumber: string;
    outputMode: boolean;
    inputFiltering: boolean;
};

export type InputsAdcRepresentation = {
    adcInput1: number;
    adcInput2: number;
    adcInput3: number;
    adcInput4: number;
    updatedAt: Date;
};

export type UnitStatusesRepresentation = {
    isPhysicalInput1High: boolean;
    isLogicalInput1High: boolean;
    isPhysicalInput2High: boolean;
    isLogicalInput2High: boolean;
    isPhysicalInput3High: boolean;
    isLogicalInput3High: boolean;
    isPhysicalInput4High: boolean;
    isLogicalInput4High: boolean;
    isLight: boolean;
    isOutLoadDetected: boolean;
};

export type InputConfigRepresentation = {
    lowThreshold: number | undefined;
    highThreshold: number | undefined;
    enable: number;
    logicNegative: number ;
    updatedAt: Date | undefined;
};

export type InputsConfigRepresentation = {
    input1Config: InputConfigRepresentation;
    input2Config: InputConfigRepresentation;
    input3Config: InputConfigRepresentation;
    input4Config: InputConfigRepresentation;
};

export type FeatureStatusRepresentation = {
    bluetoothTlvCommunication: boolean | undefined;
    updatedAt: Date;
};

export type BluetoothInfoRepresentation = {
    publicAddress: string | undefined;
    updatedAt: Date;
};

export type SensorTemperatureRepresentation = {
    sensor1InCelsius: number;
    sensor2InCelsius: number;
    updatedAt: Date;
}

export type DeviceSetupStatusRepresentation = {
    configurationEventType: string;
    updatedAt: Date;
}

export type TelematicsDeviceSetup = {
    inputsAdc: InputsAdcRepresentation;
    unitStatuses: UnitStatusesRepresentation;
    input1Config: InputConfigRepresentation;
    input2Config: InputConfigRepresentation;
    input3Config: InputConfigRepresentation;
    input4Config: InputConfigRepresentation;
    featureStatus: FeatureStatusRepresentation;
    bluetoothInfo: BluetoothInfoRepresentation;
    sensorTemperature: SensorTemperatureRepresentation;
    deviceSetupStatusRepresentation: DeviceSetupStatusRepresentation;
};

const telematicsDeviceApiUrl = getEnviroment().endpoints.telematicsDevice + '/telematics-devices';
const predefinedCommandsApiUrl = getEnviroment().endpoints.telematicsDevice + '/commands/predefined';

function getPredefinedCustomerCommands(accessToken: AccessToken | undefined): Promise<PredefinedCustomerCommand[]> {
    return AxiosRequest.get(
        accessToken,
        `${predefinedCommandsApiUrl}`,
        result => {
            return result.data as PredefinedCustomerCommand[];
        }
    );
}

function executePredefinedCustomerCommand(accessToken: AccessToken | undefined, serialNumber: string, commandId: string) {
    const serialNumbers: string[] = [];
    serialNumbers.push(serialNumber);

    const predefinedCustomerCommandRequest: PredefinedCustomerCommandRequest = {
        commandId,
        serialNumbers
    };

    return AxiosRequest.post(
        accessToken,
        `${predefinedCommandsApiUrl}`,
        predefinedCustomerCommandRequest
    );
}

function executeReplace(accessToken: AccessToken | undefined,
                        fromSerialNumber: string,
                        toSerialNumber: string,
                        outputMode: boolean,
                        inputFiltering: boolean) {
    const deviceReplaceApiUrl = telematicsDeviceApiUrl + '/replacements';

    const deviceReplaceRequest: DeviceReplaceRequest = {
        fromSerialNumber,
        toSerialNumber,
        outputMode,
        inputFiltering
    };

    return AxiosRequest.post(accessToken, `${deviceReplaceApiUrl}`, deviceReplaceRequest);
}

function deviceSetupRefresh(accessToken: AccessToken | undefined,
                      serialNumber: string): Promise<AsyncOperationResponse> {
    const inputConfigApiUrl = `${telematicsDeviceApiUrl}/${serialNumber}/device-setup/refresh`;

    return AxiosRequest.post(
        accessToken,
        `${inputConfigApiUrl}`,
        {},
        result => {
            return result.data as AsyncOperationResponse;
        });
}

function getDeviceSetupLatest(
    accessToken: AccessToken | undefined,
    serialNumber: string): Promise<TelematicsDeviceSetup> {
    return AxiosRequest.get(
        accessToken,
        `${telematicsDeviceApiUrl}/${serialNumber}/device-setup`,

        result => {
            return result.data as TelematicsDeviceSetup;
        }
    );
}

function deviceReboot(accessToken: AccessToken | undefined,
                serialNumber: string) {
    const inputConfigApiUrl = `${telematicsDeviceApiUrl}/${serialNumber}/reboot`;

    return AxiosRequest.post(accessToken, `${inputConfigApiUrl}`);
}

function setFeatureStatus(accessToken: AccessToken | undefined,
                       serialNumber: string,
                       bluetoothTlvCommunication: boolean,) {
    const featureSetupApiUrl = `${telematicsDeviceApiUrl}/${serialNumber}/device-setup/feature-setup`;

    const telematicsDeviceFeatureStatus: FeatureStatusRepresentation = {
        bluetoothTlvCommunication,
        updatedAt: new Date()
    };

    return AxiosRequest.put(accessToken, `${featureSetupApiUrl}`, telematicsDeviceFeatureStatus);
}

function setInputsConfig(accessToken: AccessToken | undefined,
                        serialNumber: string,
                        input1Config: InputConfigRepresentation,
                        input2Config: InputConfigRepresentation,
                        input3Config: InputConfigRepresentation,
                        input4Config: InputConfigRepresentation) {
    const inputConfigApiUrl = `${telematicsDeviceApiUrl}/${serialNumber}/device-setup/inputs`;

    const telematicsDeviceInputs: InputsConfigRepresentation = {
        input1Config,
        input2Config,
        input3Config,
        input4Config
    };

    return AxiosRequest.put(accessToken, `${inputConfigApiUrl}`, telematicsDeviceInputs);
}

export const TelematicsDeviceConfigurationApi = {
    getPredefinedCustomerCommands,
    executePredefinedCustomerCommand,
    executeReplace,
    deviceSetupRefresh,
    getDeviceSetupLatest,
    deviceReboot,
    setInputsConfig,
    setFeatureStatus
};
