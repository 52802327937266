import { CUSTOMER_TERBERG, CUSTOMER_TRACKUNIT_CONSTRUCTION } from './Customers';

export interface Component {
  type: string;
  values: ComponentName[];
}

export interface ComponentName {
  name: string;
  prefix?: string;
}

export interface ComponentTypeMap {
  [key: string]: Component;
}

const createComponent = (name: string): ComponentName => {
  return {
    name: name
  };
};

const createComponentWithPrefix = (
  name: string,
  prefix: string
): ComponentName => {
  return {
    name: name,
    prefix: prefix
  };
};

const terbergComponentTypeMap: ComponentTypeMap = {
  'engine.brand': {
    type: 'Engine.Brand',
    values: [
      createComponent('Caterpillar'),
      createComponent('Cummins'),
      createComponent('Mercedes'),
      createComponent('Volvo Penta'),
      createComponent('ZF'),
      createComponent('Weichai')
    ]
  },
  'engine.model': {
    type: 'Engine.Model',
    values: [
      createComponentWithPrefix('C7.1', 'Caterpillar'),
      createComponentWithPrefix('C9', 'Caterpillar'),
      createComponentWithPrefix('C6.6', 'Caterpillar'),
      createComponentWithPrefix('Stage V', 'Cummins'),
      createComponentWithPrefix('ISBe5', 'Cummins'),
      createComponentWithPrefix('QSB QSM T3', 'Cummins'),
      createComponentWithPrefix('T4F', 'Cummins'),
      createComponentWithPrefix('Stage 4 CPC', 'Mercedes'),
      createComponentWithPrefix('T3 T4i ADM3', 'Mercedes'),
      createComponentWithPrefix('D11 Stage V', 'Volvo Penta'),
      createComponentWithPrefix('D8 T3 Stage 4', 'Volvo Penta'),
      createComponentWithPrefix('D8 Stage V', 'Volvo Penta'),
      createComponentWithPrefix('Cetrax Inverter', 'ZF'),
      createComponentWithPrefix('WP7NG', 'Weichai')
    ]
  },
  'transmission.brand': {
    type: 'Transmission.Brand',
    values: [createComponent('ZF'), createComponent('Allison')]
  },
  'transmission.model': {
    type: 'Transmission.Model',
    values: [
      createComponentWithPrefix('WG161 310', 'ZF'),
      createComponentWithPrefix('AS Tronic', 'ZF'),
      createComponentWithPrefix('Gen 5', 'Allison')
    ]
  },
  'bodyController.brand': {
    type: 'BodyController.Brand',
    values: [createComponent('Terberg')]
  },
  'bodyController.model': {
    type: 'BodyController.Model',
    values: [
      createComponentWithPrefix('CC (RTxx3)', 'Terberg'),
      createComponentWithPrefix('BCC2', 'Terberg'),
      createComponentWithPrefix('CC2 (YTxx3)', 'Terberg'),
      createComponentWithPrefix('CC (YTxx2/DTxx3)', 'Terberg')
    ]
  }
};

function getComponentTypes(customerId: number): ComponentTypeMap {
  return customerId === CUSTOMER_TRACKUNIT_CONSTRUCTION ||
    customerId === CUSTOMER_TERBERG
    ? terbergComponentTypeMap
    : {};
}

export const CustomerComponentTypes = {
  getComponentTypes
};
