import * as React from 'react';
import { AppLogo } from '../AppLogo';
import './TopBar.css';
import UserInfo from './components/UserInfo';

export const TopBar: React.FC = () => {
  return (
    <div className="TopBar">
      <AppLogo />
      <UserInfo />
    </div>
  );
};
