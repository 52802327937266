import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Route } from 'react-router-dom';
import { Login } from './Login';

interface Props {
  path: string;
  component: React.FC;
  logo?: string;
  idps?: { type: string; text: string; id: string }[];
  oktaConfig: {issuer: string, clientId: string, pkce: boolean, redirectUri: string, scopes: string[]}
}

export const BrandedSecureRoute: React.FC<Props> = props => {
  const { authState } = useOktaAuth();

  return (
    <Route
      path={props.path}
      render={() => {
        if (authState?.isAuthenticated === null || authState?.isPending) {
          return <></>;
        }
        if (authState?.isAuthenticated === false) {
          return (
            <Login
              logo={props.logo}
              idps={props.idps}
              oktaConfig={props.oktaConfig}
            />
          );
        }

        return <props.component />;
      }}
    />
  );
};
