import {getEnviroment} from 'env';
import {AxiosRequest} from '../common/AxiosRequest';
import {AccessToken} from '@okta/okta-auth-js';
import {AccessControlMode} from '../../components/DeviceAccessControl/DeviceAccessControl';

function update(
    accessToken: AccessToken | undefined,
    serialNumber: string,
    accessControlMode: AccessControlMode
) : Promise<void> {
    const payload = {
        accessControlType: accessControlMode
    };
    return AxiosRequest.put(
        accessToken,
        `${getEnviroment().endpoints.deviceAccessControl}/${serialNumber}`,
        payload
    );
}

export const DeviceAccessControlApi = {update};
