export enum DeviceTypeEnum {
  TU500 = 'TU500',
  TU501 = 'TU501',
  TU600 = 'TU600',
  TU700 = 'TU700',
  GRIFFIN = 'GRIFFIN',
  GW100 = 'GW100',
  KIN = 'KIN',
  BLUETOOTH_TAG = 'BLUETOOTH_TAG',
  SPOT = 'SPOT',
  SPOT2 = 'SPOT2'
}

const DEVICE_TYPES_WITHOUT_OFFSET_SUPPORT = [
  DeviceTypeEnum.GW100,
  DeviceTypeEnum.KIN,
  DeviceTypeEnum.BLUETOOTH_TAG,
  DeviceTypeEnum.SPOT,
  DeviceTypeEnum.SPOT2
];

export const isOffsetSupported = (deviceType: DeviceTypeEnum) => {
  return !DEVICE_TYPES_WITHOUT_OFFSET_SUPPORT.includes(deviceType);
}