import { getEnviroment } from 'env';
import { sortByString } from './common/Sorting';
import { AxiosRequest } from './common/AxiosRequest';
import {AccessToken} from "@okta/okta-auth-js";

export interface Category {
  id: number;
  name: string;
}

function getCategories(accessToken: AccessToken | undefined): Promise<Category[]> {
  return AxiosRequest.get(
    accessToken,
    getEnviroment().endpoints.categories,
    result => {
      const categories = result.data.list as Category[];
      return sortByString(categories, category => category.name);
    }
  );
}

export const CategoriesApi = { getCategories };
