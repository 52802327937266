import React from 'react';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { HALLink } from 'pages/Unitlookup/stores/common/HALLinkResolver';
import { GenericConfigButton } from './GenericConfigButton';
import { withOktaAuth } from '@okta/okta-react';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {AccessToken} from "@okta/okta-auth-js";
import {UnitInfo} from "../../stores/TelematicsDevice/ClassicUnitApi";

interface Props {
  visible: Boolean;
  configName: String;
  analyticsEvent: AnalyticsEvents;
  buttonLabel: String;
  confirmLabel: String;
  performConfiguration(accessToken: AccessToken |undefined, genericConfigurationLink: HALLink): Promise<void>;
  prerequisiteFulfilled?(unitInfo: UnitInfo): Boolean;
  prerequisiteFailureLabel?: String;
}

export const HALLinkGenericConfigButton: React.FC<Props & IOktaContext> = props => {
  const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
  const [
    genericConfigurationLink,
    setGenericConfigurationLink
  ] = React.useState<HALLink | undefined>(undefined);

  React.useEffect(() => {
    if (telematicsDevice !== undefined) {
      const link = telematicsDevice._links.configurations.find(
        c => c.title === props.configName
      );
      setGenericConfigurationLink(link);
    } else {
      setGenericConfigurationLink(undefined);
    }
  }, [telematicsDevice, props.configName]);

  return (
    <GenericConfigButton
      visible={props.visible}
      analyticsEvent={props.analyticsEvent}
      buttonLabel={props.buttonLabel}
      confirmLabel={props.confirmLabel}
      performConfiguration={() => {
        if (!genericConfigurationLink)
          throw new Error('User not allowed to perform this configuration');

        return props.performConfiguration(props.authState?.accessToken, genericConfigurationLink);
      }}
      prerequisiteFulfilled={props.prerequisiteFulfilled}
      prerequisiteFailureLabel={props.prerequisiteFailureLabel}
    />
  );
};

export default withOktaAuth(HALLinkGenericConfigButton);
