import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { TopBar } from 'components/TopBar/TopBar';
import { Unitlookup } from 'pages/Unitlookup';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from 'components/TrackUnitTheme';
import { useAnalytics } from 'components/analytics/analytics';
import { loggerStategyFactory } from 'components/analytics/logger';
import { getUserInfo } from 'pages/Login/AuthenticationService';
import {
  BrandingContext,
  Branding,
  manitouBrand,
  trackunitBrand
} from './components/BrandingProvider';
import { LocationService } from './components/Location/LocationService';
import ImportValues from './pages/ImportValues/ImportValues';
import { TelematicsDeviceProvider } from './pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { MachineInfoProvider } from './pages/Unitlookup/stores/MachineInfo/MachineInfoProvider';
import { GroupProvider } from './pages/Unitlookup/stores/Group/GroupProvider';
import { withOktaAuth } from '@okta/okta-react';
import {AuthState} from "@okta/okta-auth-js";
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";

interface Props {
  authState: AuthState;
}

const App: React.FC<Props & IOktaContext> = props => {
  useAnalytics().setLoggerStrategy(loggerStategyFactory());
  useAnalytics().updateUserInformation(
    getUserInfo(props.authState?.accessToken)
  );

  const [brand, setBrand] = React.useState<Branding>(trackunitBrand);

  React.useEffect(() => {
    if (
      LocationService.isManitouTestUrl() ||
      LocationService.isServedOnManitou()
    ) {
      setBrand(manitouBrand);
    }
  }, []);

  React.useEffect(() => {
    document.title = brand.title;
  }, [brand]);

  return (
    <MuiThemeProvider theme={theme}>
      <BrandingContext.Provider value={brand}>
        <TelematicsDeviceProvider>
            <MachineInfoProvider>
              <GroupProvider>
                <div className="main">
                  <Router>
                    <TopBar />
                    <Route path="/" exact component={Unitlookup} />
                    <Route path="/:arg" exact component={Unitlookup} />
                    <Route path="/import/values" exact component={ImportValues} />
                  </Router>
                </div>
              </GroupProvider>
            </MachineInfoProvider>
        </TelematicsDeviceProvider>
      </BrandingContext.Provider>
    </MuiThemeProvider>
  );
};

export default withOktaAuth(App);
