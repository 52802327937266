import * as React from 'react';
import { useAdvancedSensorsData } from '../../stores/AdvancedSensorsData/AdvancedSensorsDataProvider';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AdvancedSensorsDataList } from './AdvancedSensorsDataList';
import './AdvancedSensorsData.scss';
import { useDeviceContext } from "../../stores/TelematicsDevice/TelematicsDeviceProvider";
import { TUButton } from "../../../../components/TUButton";
import { Refresh } from "@material-ui/icons";
import { isFirmwareGreaterThanOrEqualTo } from "../../stores/TelematicsDevice/FirmwareComparison";
import { ActivityDetectionType } from "../../stores/TelematicsDevice/DeviceStateApi";
import { useLegacyTelematicsDeviceContext } from "../../stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider";
import { useAccessibilityContext } from "../../stores/AccessibilityProvider/AccessibilityProvider";
import { DeviceTypeEnum } from '../../stores/TelematicsDevice/DeviceTypeEnum';
import { AdvancedSensorData } from "../../stores/AdvancedSensorsData/AdvancedSensorsDataApi";

export const AdvancedSensorsData: React.FC = () => {
    const {identity, state} = useDeviceContext();
    const {accessibility} = useAccessibilityContext();
    const {advancedSensorsData, isLoading, reFetchLatestData} = useAdvancedSensorsData(identity.serialNumber);
    const [lastCan1DataDate, setLastCan1DataDate] = React.useState(new Date(0));
    const [lastCan2DataDate, setLastCan2DataDate] = React.useState(new Date(0));
    const [lastModbusDataDate, setLastModbusDataDate] = React.useState(new Date(0));
    const [can1Data, setCan1Data] = React.useState<AdvancedSensorData[]>([]);
    const [can2Data, setCan2Data] = React.useState<AdvancedSensorData[]>([]);
    const [modbusData, setModbusData] = React.useState<AdvancedSensorData[]>([]);
    const [selectedAdvancedSensorData, setSelectedAdvancedSensorData] = React.useState<AdvancedSensorData[]>([]);
    const [selectedAdvancedSensorHeader, setSelectedAdvancedSensorHeader] = React.useState<string>("");

    const [showAdvancedSensorsDataDialog, setShowAdvancedSensorsDataDialog] = React.useState(false);
    const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
    const handleOpenDialog = (data: AdvancedSensorData[], dialogHeader: string) => {
        setSelectedAdvancedSensorData(data);
        setShowAdvancedSensorsDataDialog(true);
        setSelectedAdvancedSensorHeader(dialogHeader);
    }
    const handleCloseDialog = () => {
        setShowAdvancedSensorsDataDialog(false);
        setSelectedAdvancedSensorData([]);
        setSelectedAdvancedSensorHeader("");
    }

    const getLatestDate = (data: AdvancedSensorData[], dataFilter: (data: AdvancedSensorData)  => boolean): Date => {
        return data
          .filter(dataFilter)
          .map(d => d.latestData.timestamp)
          .reduce((a, b) => a > b ? a : b, new Date(0));
    }

    React.useEffect(() => {
        if (advancedSensorsData && advancedSensorsData.length > 0) {
            setLastCan1DataDate(getLatestDate(advancedSensorsData, d => d.type === "CAN_SENSOR" && (d.instance === "CAN1" || d.instance === undefined)));
            setLastCan2DataDate(getLatestDate(advancedSensorsData, d => d.type === "CAN_SENSOR" && d.instance === "CAN2"));
            setLastModbusDataDate(getLatestDate(advancedSensorsData,d => d.type === "MODBUS_SENSOR"));

            setCan1Data(advancedSensorsData.filter(d => d.type === "CAN_SENSOR" && (d.instance === "CAN1" || d.instance === undefined)));
            setCan2Data(advancedSensorsData.filter(d => d.type === "CAN_SENSOR" && d.instance === "CAN2"));
            setModbusData(advancedSensorsData.filter(d => d.type === "MODBUS_SENSOR"));
        }
    }, [advancedSensorsData]);

    const isTU700 = () : boolean => {
        return identity.deviceType === DeviceTypeEnum.TU700 || identity.deviceType === DeviceTypeEnum.GRIFFIN;
    }

    const parseCanActivityDetectionState = (activityDetectionState: ActivityDetectionType | undefined) : string => {
        if(
          isTU700()
          || (isFirmwareGreaterThanOrEqualTo(state.reported.firmware?.version ?? telematicsDevice?.firmwareVersion , 64, 4)
            && (identity.deviceType === DeviceTypeEnum.TU500
              || identity.deviceType === DeviceTypeEnum.TU501
              || identity.deviceType === DeviceTypeEnum.TU600))) {

            switch (activityDetectionState) {
                case ActivityDetectionType.ACTIVITY_DETECTED:
                    return "Yes";
                case ActivityDetectionType.NO_ACTIVITY_DETECTED:
                    return "No";
                default:
                    return "Unknown";
            }
        }
        else {
            return "Unsupported";
        }
    }

    const getBackwardCompatibleDetectionState = () : ActivityDetectionType | undefined => {
        // There's two formatting of the Activity Detection State.
        // The below code will handle both and enable a smooth transition to the new syntax

        // Handle V1 syntax
        if (state.reported.canbus?.activityDetection && !state.reported.canbus.activityDetection?.hasOwnProperty("state") ) {
            return state.reported.canbus.activityDetection;
        }

        // Handle V2 syntax
        if (state.reported.canbus?.activity?.canActivityState )
            return state.reported.canbus.activity.canActivityState;

        // Handle V3 syntax
        return state.reported.canbus?.activityDetection?.state;
    }

    const renderAdvancedSensorRow = (header: string,
                                     lastDataDate: string,
                                     advancedSensorData: AdvancedSensorData[],
                                     showActivity: boolean | undefined,
                                     activityState: string | null
                                     ) => {
        return (
        <dl className="staticSection advanced-sensor-data-row">
            <dt>{header}:</dt>
            <dd className="advanced-sensor-data-cell">
                <div>
                    <span className="advanced-sensor-detail-label">Last data:</span>
                    {!isLoading() && advancedSensorData && advancedSensorData.length > 0 ? (
                      <span>
                      {lastDataDate.toLocaleString()}
                          <IconButton
                            onClick={reFetchLatestData}
                            aria-label="more"
                            className="advanced-sensor-data-details"
                          >
                              <Refresh color="primary"/>
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenDialog(advancedSensorData, header)}
                            aria-label="more"
                            className="advanced-sensor-data-details"
                          >
                              <SvgIcon color="primary">
                                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
                              </SvgIcon>
                          </IconButton>
                      </span>
                    ) : isLoading() ? (
                      <CircularProgress size={13}/>
                    ) : (
                      <span>
                        No data received
                        <IconButton
                          onClick={reFetchLatestData}
                          aria-label="more"
                          className="advanced-sensor-data-details"
                        >
                            <Refresh color="primary"/>
                        </IconButton>
                      </span>
                    )
                    }
                </div>
                <div>
                    {showActivity && (
                      <span className="advanced-sensor-activity">
                        <span className="advanced-sensor-detail-label">Activity:</span>
                        <span>{activityState}</span>
                    </span>
                    )}
                </div>
            </dd>
        </dl>
        );
    }

    return (
      <>
          {
              renderAdvancedSensorRow(`CAN${isTU700() ? " #1" : ""}`,
                lastCan1DataDate.toLocaleString(),
                can1Data,
                accessibility?.activityDetection.visible || accessibility?.can1ActivityDetection.visible,
                    accessibility?.activityDetection.visible ?
                      parseCanActivityDetectionState(getBackwardCompatibleDetectionState()) :
                      accessibility?.can1ActivityDetection.visible ?
                        parseCanActivityDetectionState(state.reported.canbus?.can1?.activityDetection?.state) :
                        null
                )
            }

            { isTU700() &&
                renderAdvancedSensorRow("CAN #2",
                  lastCan2DataDate.toLocaleString(),
                  can2Data,
                  accessibility?.can2ActivityDetection.visible,
                  accessibility?.can2ActivityDetection.visible ?
                    parseCanActivityDetectionState(state.reported.canbus?.can2?.activityDetection?.state) :
                    null
                )
            }

            { isTU700() &&
              renderAdvancedSensorRow("MODBUS",
                lastModbusDataDate.toLocaleString(),
                modbusData,
                false,
                null
              )
            }

            <Dialog
              open={showAdvancedSensorsDataDialog}
              onClose={handleCloseDialog}
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">{`${selectedAdvancedSensorHeader} data`}</DialogTitle>
                <DialogContent>
                    <AdvancedSensorsDataList advancedSensorsData={selectedAdvancedSensorData}/>
                </DialogContent>
                <DialogActions>
                    <TUButton
                      loading={isLoading()}
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={reFetchLatestData}
                      autoFocus
                    >
                        Refresh
                    </TUButton>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleCloseDialog}
                      autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
