import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress, Switch,
  TextField
} from '@material-ui/core';
import {
  useDeviceContext
} from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import './ReplaceButton.scss';
import {TelematicsDeviceConfigurationApi} from "../../stores/TelematicsDeviceConfiguration/TelematicsDeviceConfigurationApi";
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {withOktaAuth} from "@okta/okta-react";
import {useOutputModeContext} from "../../stores/OutputMode/OutputModeProvider";
import {useInputFilterContext} from "../../stores/InputFiltering/InputFilteringProvider";
import {useMachineInfoContext} from "../../stores/MachineInfo/MachineInfoProvider";
import {ArrowForward} from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";

const useStyles = makeStyles((theme) => ({
  input: {
    background: '#b9f6ca'
  }
}));

export const ReplaceButton: React.FC<IOktaContext> = props => {
  const classes = useStyles();

  const { validate: uuidValidate } = require('uuid');
  const REPLACE_TIMEOUT = 20; // timeout in seconds
  const deviceContext = useDeviceContext();
  const machineInfoContext = useMachineInfoContext();
  const analytics = useAnalytics();
  const { accessibility } = useAccessibilityContext();

  // force extra verification when replacing unit
  const requiresExtraVerification = [
    {brand: 'JLG', productionYearBefore: 2021, modelIncludes: 'ES', text: 'Electrical scissorlifts are known to provide negative hours. Make sure to invert hours, if necessary.'},
  ].filter(r =>
      machineInfoContext.brand?.toUpperCase().trim() === r.brand.toUpperCase() &&
      machineInfoContext.model?.toUpperCase().includes(r.modelIncludes.toUpperCase()) &&
      new Date(machineInfoContext.productionDate).getFullYear() < r.productionYearBefore
  );

  const outputModeContext = useOutputModeContext();
  const inputFilteringContext = useInputFilterContext();

  const [newSerialNumber, setNewSerialNumber] = React.useState<string>('');
  const [isReplacing, setIsReplacing] = React.useState(false);
  const [replaceProgress, setReplaceProgress] = React.useState(0);
  const [showReplace, setShowReplace] = React.useState(false);
  const [showConfirmReplace, setShowConfirmReplace] = React.useState(false);
  const [replacementVerified, setReplacementVerified] = React.useState(false);
  const [showExecuted, setShowExecuted] = React.useState(false);
  const [showFailed, setShowFailed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | JSX.Element>('');

  const cancel = () => {
    setShowReplace(false);
    setShowConfirmReplace(false);
  }

  const closeConfirmNotification = () => setShowExecuted(false);

  const closeConfirmNotificationAndRefreshWithNewSerialNumber = () => {
    closeConfirmNotification();
    window.location.replace(newSerialNumber);
  }

  const closeFailed = () => {
    setShowFailed(false);
    setShowConfirmReplace(false);
    setErrorMessage('');
  }

  const showReplaceDialog = () => {
    analytics.logEvent(AnalyticsEvents.UnitReplaceButtonPressed, {
      serialNumberFrom: deviceContext.identity.serialNumber
    });
    setNewSerialNumber('');
    setShowReplace(true);
  }

  const showConfirmReplaceDialog = () => {
    setShowReplace(false);
    setReplacementVerified(false);
    setShowConfirmReplace(true);
  }

  function toggleVerification() {
    const verified = !replacementVerified;

    analytics.logEvent(AnalyticsEvents.UnitReplaceVerification, {
      verified: verified,
      serialNumberFrom: deviceContext.identity.serialNumber,
      serialNumberTo: newSerialNumber
    });

    setReplacementVerified(verified);
  }

  const replace = () => {
    setReplaceProgress(0)
    setIsReplacing(true);

    const replaceTimer = setInterval(() =>
      setReplaceProgress((oldProgress) => {
        const diff = 100 / REPLACE_TIMEOUT;
        return Math.min(oldProgress + diff, 100);
      }), 1000);

    TelematicsDeviceConfigurationApi.executeReplace(
        props.authState?.accessToken,
        deviceContext.identity.serialNumber,
        newSerialNumber,
        (outputModeContext.enabled !== undefined ? outputModeContext.enabled : false) as boolean,
        (inputFilteringContext.enabled !== undefined ?  inputFilteringContext.enabled : false) as boolean
      )
      .then(() => {
        clearTimeout(replaceTimer);
        setIsReplacing(false);
        setShowExecuted(true);

        analytics.logEvent(AnalyticsEvents.UnitReplace, {
          success: true,
          serialNumberFrom: deviceContext.identity.serialNumber,
          serialNumberTo: newSerialNumber
        });
      })
      .catch((ex) => {
        clearTimeout(replaceTimer);
        setIsReplacing(false);
        setErrorMessage(decorateError( ex.message));
        setShowFailed(true);

        analytics.logEvent(AnalyticsEvents.UnitReplace, {
          success: false,
          serialNumberFrom: deviceContext.identity.serialNumber,
          serialNumberTo: newSerialNumber
        })
      }
    );
  };

  const decorateError = (error: string) => {
    let description;
    let action;

    if (uuidValidate(error)) {
      action = 'Kindly provide the tracking id when contacting Support.';
    }

    return (
      <div className="error">
        <div className="title">{error}</div>
        {description && (<div className="description">{description}</div>)}
        {action && (<div className="action">{action}</div>)}
      </div>
    );
  }

  return accessibility?.replace.visible ? (
        <>
          <Button
            onClick={showReplaceDialog}
            variant="contained"
            size="large"
            color="primary">
            Replace
          </Button>

          <Dialog
              open={showReplace}
              onClose={closeFailed}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description">
            <DialogTitle id="dialog-title">{`Device replacement`}</DialogTitle>
            <DialogContent>
              <TextField
                  id="search"
                  label="New Device Serial Number"
                  fullWidth
                  variant="outlined"
                  value={newSerialNumber}
                  onChange={evt => setNewSerialNumber(evt.target.value)}
              />
              <div className="explanationNote">
                Configurations that will be carried over to new (replacing) unit:
                <ul id="retainedDataList">
                  <li>Input naming</li>
                  <li>Group settings</li>
                  <li>Hours / mileage offset</li>
                  <li>CAN profile</li>
                </ul>
              </div>
              <div className="explanationNote bold">
                All unit historical data will also be carried over to new unit.
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={cancel}
                  disabled={isReplacing}
              >
                Cancel
              </Button>
              <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={showConfirmReplaceDialog}
                  disabled={isReplacing || newSerialNumber.length < 6}
                  autoFocus
              >
                Next
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
              open={showConfirmReplace}
              onClose={closeFailed}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description">
            <DialogTitle id="dialog-title">{`Confirm device replacement`}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                <Grid item xs={12} sm={5}>
                  <TextField
                      id="oldDeviceSerialNumber"
                      disabled={true}
                      label=""
                      variant="outlined"
                      fullWidth
                      value={deviceContext.identity.serialNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Grid container justifyContent="center" spacing={2}>
                    <ArrowForward fontSize="large" />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                      id="newDeviceSerialNumber"
                      disabled={true}
                      label=""
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        className: classes.input
                      }}
                      value={newSerialNumber}
                  />
                </Grid>
              </Grid>
              <div className="explanationNote">
                Please check and confirm that serials are correct, before making the replacement.
              </div>
              <div className="explanationNote bold">
                This action cannot be reverted.
              </div>
              {requiresExtraVerification.length > 0 && <div>
                <div className="explanationNote bold">
                  {requiresExtraVerification[0].text}
                </div>
                <div className="explanationNote normal">
                Verified
                <Switch
                checked={replacementVerified}
                onChange={toggleVerification}
                color="primary"
                />
                </div>
              </div>
              }
            </DialogContent>
            <DialogActions>
              <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={cancel}
                  disabled={isReplacing}
              >
                Cancel
              </Button>
              <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={replace}
                  disabled={isReplacing || (!replacementVerified && requiresExtraVerification.length > 0)}
                  autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
            <div className="progressBar bold" style={{display: isReplacing ? 'block' : 'none' }}>
              <p>{replaceProgress} %</p>
              <LinearProgress variant="determinate" value={replaceProgress} />
            </div>
          </Dialog>

          <Dialog
            open={showExecuted}
            onClose={closeConfirmNotification}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description">
            <DialogTitle id="dialog-title">{`Unit replacement successful.`}</DialogTitle>
            <DialogContent>
              Page will be refreshed with the new serial number.
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={closeConfirmNotificationAndRefreshWithNewSerialNumber}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showFailed}
            onClose={closeFailed}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            <DialogTitle id="dialog-title">Unit replacement failed</DialogTitle>
            <DialogContent>
              {errorMessage}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={closeFailed}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
    ) : <></>;
};

export default withOktaAuth(ReplaceButton);
