import React from 'react';
import { ExtendedInfo, MachineInfo, MachineInfoApi } from './MachineInfoApi';
import { useOktaAuth } from '@okta/okta-react';

type MachineInfoContextValue = {
  machineInfo: MachineInfo;
  setMachineInfo: (machineInfo: MachineInfo) => void;

  loading: boolean;
  setLoading: (loading: boolean) => void;

  hasContent: boolean;
  setHasContent: (hasContent: boolean) => void;
};

const MachineInfoContext = React.createContext<
  MachineInfoContextValue | undefined
>(undefined);

type MachineInfoProviderProps = {
  children: React.ReactNode;
};

export const MachineInfoProvider = (props: MachineInfoProviderProps) => {
  const [machineInfo, setMachineInfo] = React.useState<MachineInfo>({
    brand: '',
    model: '',
    category: '',
    productionDate: '',
    vin: '',
    extendedInformation: []
  });
  const [loading, setLoading] = React.useState<boolean>(true);
  const [hasContent, setHasContent] = React.useState<boolean>(false);

  const value: MachineInfoContextValue = React.useMemo(
    () => ({
      machineInfo,
      setMachineInfo,
      loading,
      setLoading,
      hasContent,
      setHasContent
    }),
    [machineInfo, loading, hasContent]
  );
  return <MachineInfoContext.Provider value={value} {...props} />;
};

export const useMachineInfoContext = () => {
  const context = React.useContext(MachineInfoContext);
  const { authState } = useOktaAuth();

  if (!context) {
    throw new Error(
      'useMachineInfoContext must be used within a MachineInfoProvider'
    );
  }

  const {
    machineInfo,
    setMachineInfo,
    loading,
    setLoading,
    hasContent,
    setHasContent
  } = context;

  const reset = () => {
    setMachineInfo({
      brand: '',
      model: '',
      category: '',
      productionDate: '',
      vin: '',
      extendedInformation: []
    });
  };

  const load = (serialNumber: string) => {
    setLoading(true);
    setHasContent(false);
    reset();
    return MachineInfoApi.getMachineInfo(
      authState?.accessToken,
      serialNumber
    )
      .then(m => {
        setMachineInfo(m);
        setHasContent(true);
        setLoading(false);
      })
      .catch(err => {
        console.error('Failed to load machine info', err);
        setLoading(false);
      });
  };

  const save = (
    serialNumber: string,
    customerId: number,
    userId: number,
    saveExtendedMetadata: boolean
  ) => {
    return MachineInfoApi.updateMachineInfo(
      authState?.accessToken,
      serialNumber,
      machineInfo,
      saveExtendedMetadata
    );
  };

  const setBrand = (brand: string) => setMachineInfo({ ...machineInfo, brand });
  const setModel = (model: string) => setMachineInfo({ ...machineInfo, model });
  const setCategory = (category: string) =>
    setMachineInfo({ ...machineInfo, category });
  const setProductionDate = (productionDate: string) =>
    setMachineInfo({ ...machineInfo, productionDate });
  const setVin = (vin: string) => setMachineInfo({ ...machineInfo, vin });

  const addExtendedMetadata = (info: ExtendedInfo) => {
    const extendedInformation = machineInfo.extendedInformation;
    extendedInformation.push(info);
    setMachineInfo({ ...machineInfo, extendedInformation });
  };
  const updateExtendedMetadata = (idx: number, info: ExtendedInfo) => {
    const extendedInformation = machineInfo.extendedInformation;
    extendedInformation[idx] = info;
    setMachineInfo({ ...machineInfo, extendedInformation });
  };
  const deleteExtendedMetadata = (idx: number) => {
    const extendedInformation = machineInfo.extendedInformation;
    extendedInformation.splice(idx, 1);
    setMachineInfo({ ...machineInfo, extendedInformation });
  };

  return {
    ...machineInfo,
    setMachineInfo,

    setBrand,
    setModel,
    setCategory,
    setProductionDate,
    setVin,
    addExtendedMetadata,
    updateExtendedMetadata,
    deleteExtendedMetadata,

    loading,
    hasContent,

    load,
    save
  };
};
