import * as React from 'react';
import { Branding, BrandingContext } from './BrandingProvider';

export const AppLogo: React.FC = () => {
  const brandingContext: Branding = React.useContext(BrandingContext);

  return (
    <a href="/">
      <img src={brandingContext.logo} alt={brandingContext.company} />
    </a>
  );
};
