import { getEnviroment } from 'env';
import { AxiosRequest } from '../common/AxiosRequest';
import { AccessToken } from '@okta/okta-auth-js';

const telematicsDeviceConfigurationApiBaseUrl = getEnviroment().endpoints.telematicsDevice + '/v2beta1';
const telematicsDeviceConfigurationApiUrl = telematicsDeviceConfigurationApiBaseUrl + '/telematics-devices';

export type ConfigurationTemplate = {
    name: string;
    href: string;
};


function getDeviceConfigurationTemplates(accessToken: AccessToken | undefined,
                                         serialNumber: string): Promise<ConfigurationTemplate[]> {
    const configurationTemplatesUrl = `${telematicsDeviceConfigurationApiUrl}/${serialNumber}/configuration-templates`;

    return AxiosRequest.get(
        accessToken,
        `${configurationTemplatesUrl}`,
        result => {
            return result.data as ConfigurationTemplate[];
        });
}

function pushConfigurationToDevice(accessToken: AccessToken | undefined, configurationTemplate: ConfigurationTemplate, serialNumber: string) {
    let pushConfigurationUrl = `${telematicsDeviceConfigurationApiBaseUrl}${configurationTemplate.href}`;
    pushConfigurationUrl = pushConfigurationUrl.replace('{serialNumber}',serialNumber);
    return AxiosRequest.post(accessToken, `${pushConfigurationUrl}`, {});
}

export const TelematicsDeviceConfigurationV2Api = {
    getDeviceConfigurationTemplates,
    pushConfigurationToDevice
};
