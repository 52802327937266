import * as React from 'react';
import { useLegacyTelematicsDeviceContext } from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import { HALLink } from 'pages/Unitlookup/stores/common/HALLinkResolver';
import { formatDateTime } from 'components/TimeAndDate';
import {
  InstallationProvider,
  Installation
} from 'pages/Unitlookup/stores/InstallationProvider/InstallationProvider';

export const InstallationInfo: React.FC = () => {
  const { telematicsDevice } = useLegacyTelematicsDeviceContext();

  const [link, setLink] = React.useState<HALLink | undefined>(undefined);
  const installationInfo = InstallationProvider.useFetch(link);

  const getInstallationText = (config: Installation | undefined) => {
    if (config === undefined) return 'Unknown installation date';
    return `${
      config.installed ? 'Installed' : 'Uninstalled'
    } at ${formatDateTime(config.time)}`;
  };

  // Load Installation when the HALLink changes
  React.useEffect(() => {
    if (telematicsDevice !== undefined) {
      const link = telematicsDevice._links.installation;
      setLink(link);
    } else {
      setLink(undefined);
    }
  }, [telematicsDevice]);

  return !link ? null : (
    <dl className="staticSection">
      <dt>Installed:</dt>
      <dd>{getInstallationText(installationInfo)}</dd>
    </dl>
  );
};
