import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  OutlinedInput
} from '@material-ui/core';
import { useGroupContext } from 'pages/Unitlookup/stores/Group/GroupProvider';

export const Groups: React.FC = () => {
  const { groups, onGroupSelected } = useGroupContext();

  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <InputLabel htmlFor="select-groups">Groups</InputLabel>
      <Select
        multiple
        value={groups.filter(group => group.selected).map(group => group.name)}
        input={<OutlinedInput labelWidth={52} id="select-groups" />}
        renderValue={selected =>
          selected instanceof Array ? selected.join(', ') : ''
        }
      >
        {groups.map(group => (
          <MenuItem key={group.id} value={group.id}>
            <Checkbox
              checked={group.selected}
              onChange={evt =>
                onGroupSelected(group.id, evt.currentTarget.checked)
              }
            />
            <ListItemText primary={group.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
