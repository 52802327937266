import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Switch
} from '@material-ui/core';
import './DigitalKey.scss';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import {useOktaAuth, withOktaAuth} from "@okta/okta-react";
import { Accessibility, ArrowForward } from "@material-ui/icons";
import { useAccessibilityContext } from "../../stores/AccessibilityProvider/AccessibilityProvider";
import { StateStatus } from "../DeviceState/StateStatus";
import {DeviceStateApi, DigitalKeyModeEnum} from "../../stores/TelematicsDevice/DeviceStateApi";
import FormHelperText from "@material-ui/core/FormHelperText";

const DigitalKey: React.FC<IOktaContext> = props => {
  const {authState} = useOktaAuth();
  const {accessibility} = useAccessibilityContext();
  const analytics = useAnalytics();
  const {identity, state, stateDocumentation, reloadState} = useDeviceContext();
  const [checked, setChecked] = React.useState<boolean>(false);
  const [confirmChange, setConfirmChange] = React.useState(false);

  function showConfirm() {
    setConfirmChange(true);
  }

  function handleConfirm() {
    analytics.traceEvent(
        Promise.all([save(!checked)])
            .then(value => {
              setConfirmChange(false);
              setTimeout(reloadState, 1000);
            }),
        AnalyticsEvents.ChangeDigitalKey,
        {
          serial: identity.serialNumber,
          enabled: !checked
        }
    );
  }

  const save = (newEnabled: boolean) => {
    if (!accessibility?.digitalKey.visible) {
      return Promise.reject('User not allowed to toggle the digital key mode');
    }

    DeviceStateApi.updateState(authState?.accessToken, identity.id,
        {
          accessControl: {
            mode: newEnabled ? DigitalKeyModeEnum.UNLOCKED_FOR_ALL_FIXED : DigitalKeyModeEnum.DISABLED
          }
        })
  };

  function handleCancel() {
    setConfirmChange(false);
  }

  React.useEffect(() => {
    setChecked(state.desired.accessControl?.mode !== DigitalKeyModeEnum.DISABLED)
  }, [state.desired.accessControl]);

  return accessibility?.digitalKey.visible ? (
          <div className="digital-key-wrapper">
          <FormControl variant="outlined" margin="dense">
            <dl className="staticSection m0 digital-key">
              <dt>
                <Grid component="label" container alignItems="center">
                  <Grid item>IN2</Grid>
                  <Grid item><ArrowForward fontSize="small" /></Grid>
                  <Grid item>OUT1</Grid>
                </Grid>
              </dt>
              <dd>
                <Grid component="label" container alignItems="center">
                  <Grid item>Off</Grid>
                  <Grid item>
                    <Switch
                      defaultChecked={(state.desired.accessControl?.mode ?? state.reported.accessControl?.mode) !== DigitalKeyModeEnum.DISABLED}
                      disabled={accessibility.digitalKey.readonly}
                      onChange={showConfirm}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>On</Grid>
                </Grid>
              </dd>
            </dl>
            {accessibility.digitalKey.readonly && (
                <FormHelperText>{
                  accessibility.digitalKey.accessReason === "HAS_CLASSIC_ACCESS_CONTROL" 
                  ? "Locked for changes because Classic Access Control is being used" 
                  : accessibility.digitalKey.accessReason === "ACCESS_MANAGEMENT_ENABLED"  ? 
                  "Locked for changes because Access Management is enabled" :
                  "You do not have the necessary permissions to make this change" }</FormHelperText>
            )}
          </FormControl>
          <StateStatus
              getIdentityFromDesiredState={() => state.desired.accessControl?.mode?.toString()}
              getIdentityFromReportedState={() => state.reported.accessControl?.mode?.toString()}
              getGatewayUsedFromDesiredMetadataState={() => state.metadata.desired.accessControl?.gateway}
          />
          <Dialog
            open={confirmChange}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            <DialogTitle id="dialog-title">{`Are you sure you want to ${
                checked ? 'disable' : 'enable'
            } Output1 to follow Input2?`}</DialogTitle>

            <DialogActions>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleConfirm}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          </div>
  ) : <></>;
};

export default withOktaAuth(DigitalKey);
