import React from "react";
import {useDeviceContext} from "../../stores/TelematicsDevice/TelematicsDeviceProvider";

export const AutoRefreshState: React.FC = () => {
  const {
    contextStatus,
    reloadState,
  } = useDeviceContext();
  const refreshStateIntervalRef = React.useRef<null | NodeJS.Timeout>(null);

  React.useEffect(() => {
    if( refreshStateIntervalRef.current )
    {
      clearInterval(refreshStateIntervalRef.current as NodeJS.Timeout);
    }
    else {
    }
    refreshStateIntervalRef.current = setInterval(() => refresh(), 5000);
  }, [contextStatus])

  const refresh = () => {
    if( document.visibilityState === "visible"
      && document.hasFocus()) {
      if( contextStatus.loading || contextStatus.saving) {
        return;
      }
      try {
        reloadState();
      }
      catch {
        // Stop auto fetch on error
        // Most likely the session has timed out
        clearInterval(refreshStateIntervalRef.current as NodeJS.Timeout);
      }
    }
    else {
    }
  }

  return <></>
};