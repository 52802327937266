import { getEnviroment } from 'env';
import { AxiosRequest } from '../common/AxiosRequest';
import {AccessToken} from "@okta/okta-auth-js";

export interface OutputModeResult {
  unitId: string;
  enabled: boolean;
  readonly: boolean;
}

function get(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<OutputModeResult> {
  return AxiosRequest.get(
    accessToken,
    `${getEnviroment().endpoints.unitInfo}?SerialNumber=${serialNumber}`,
    result => {
      const data = result.data.list[0];
      return {
        unitId: data.id,
        enabled: data.output1,
        readonly: !data.canEditOutput1
      } as OutputModeResult;
    }
  );
}

export interface UpdateOutputModeRequest {
  unitId: string;
  enabled: boolean;
}

function update(
  accessToken: AccessToken | undefined,
  request: UpdateOutputModeRequest
): Promise<void> {
  if (request && request.unitId) {
    return AxiosRequest.get(
      accessToken,
      `${getEnviroment().endpoints.updateOutputMode}?unitid=${
        request.unitId
      }&value=${request.enabled}`
    );
  } else {
    throw new Error(
      `Unable to update output mode for unit id ${request.unitId}`
    );
  }
}

export const OutputModeApi = { get, update };
