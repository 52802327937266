import * as React from 'react';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";
import {withOktaAuth} from "@okta/okta-react";
import {useDeviceContext} from "../../stores/TelematicsDevice/TelematicsDeviceProvider";

const DeviceIdentity: React.FC<IOktaContext> = props => {
  const { identity } = useDeviceContext();
  return !identity ? null : (
    <dl className="staticSection">
      <dt>Device type:</dt>
      <dd>{identity.deviceType}</dd>
    </dl>
  );
};

export default withOktaAuth(DeviceIdentity)