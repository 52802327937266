import { HALLLinkResolver, HALLink } from '../common/HALLinkResolver';
import {AccessToken} from "@okta/okta-auth-js";

export type Installation = {
  time: string;
  installed: boolean;
};

const useFetch = (link: HALLink | undefined) =>
  HALLLinkResolver.useFetch<Installation>(link);

const toggleInstalled = (
  accessToken: AccessToken | undefined,
  link: HALLink,
  installationInfo: Installation | undefined
): Promise<void> => {
  if (installationInfo && installationInfo.installed)
    return HALLLinkResolver.remove(accessToken, link);
  else return HALLLinkResolver.set(accessToken, link, {});
};

export const InstallationProvider = { useFetch, toggleInstalled };
