import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { TelematicsDeviceConfigurationApi } from "../../stores/TelematicsDeviceConfiguration/TelematicsDeviceConfigurationApi";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { withOktaAuth } from "@okta/okta-react";
import { TUSpinnerProgress } from "components/TUSpinnerProgress"

export const RebootButton: React.FC<IOktaContext> = props => {
  const deviceContext = useDeviceContext();
  const analytics = useAnalytics();

  const [confirmChange, setConfirmChange] = React.useState(false);

  const [showProgress, setShowProgress] = React.useState(false);
  const [wasSuccess, setWasSuccess] = React.useState(false);
  const [wasError, setWasError] = React.useState(false);

  function showConfirm() {
    setConfirmChange(true);
    setWasSuccess(false);
    setWasError(false);
  }

  function handleCancel() {
    setConfirmChange(false);
    setShowProgress(false);
  }

  function handleConfirm() {
    analytics.traceEvent(
        Promise.all([setConfirmChange(false), reboot(deviceContext.identity.serialNumber)]),
        AnalyticsEvents.UnitReboot,
        {
          serialNumber: deviceContext.identity.serialNumber
        }
    );
  }

  const reboot = (serialNumber: string) => {
    setShowProgress(true);
    return TelematicsDeviceConfigurationApi.deviceReboot(
        props.authState?.accessToken,
        serialNumber,
      )
      .then(() => {
        setWasSuccess(true);
      })
      .catch((ex) => {
        setWasError(true);
      }
    );
  };

  return (
      <>
        <Button
          onClick={showConfirm}
          variant="contained"
          size="large"
          color="primary">
          Reboot
        </Button>

        <TUSpinnerProgress
            open={showProgress}
            onClose={handleCancel}
            success={wasSuccess}
            error={wasError}
            successText="Reboot request was sent successfully"
            errorText="There was an error while sending reboot request"
        />

        <Dialog
            open={confirmChange && !showProgress}
            onClose={handleCancel}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{`Are you sure you want to reboot device?`}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              The reboot process might take some time (several minutes) to complete.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleConfirm}
                autoFocus
            >
              Reboot
            </Button>
          </DialogActions>
        </Dialog>
      </>
  );
};

export default withOktaAuth(RebootButton);
