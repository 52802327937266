import * as React from 'react';
import {HelpOutline} from '@material-ui/icons';
import {Typography} from '@material-ui/core';
import './HelpCenterLink.scss';

export const HelpCenterLink: React.FC = () => {
  return (
    <div className="root">
      <HelpOutline className="helpIcon" />
      <Typography variant="caption">
        <a href="https://help.trackunit.com/en/collections/213231-verify" target="_blank" rel="noreferrer">
          Click here for help and support
        </a>
      </Typography>
    </div>
  );
};
