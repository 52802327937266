import { getEnviroment } from 'env';
import { sortByString } from '../common/Sorting';
import { AxiosRequest } from '../common/AxiosRequest';
import {AccessToken} from "@okta/okta-auth-js";

export interface PowerProfile {
  id: string;
  name: string;
}

function getPowerProfiles(accessToken: AccessToken | undefined): Promise<PowerProfile[]> {
  return AxiosRequest.get(
    accessToken,
    getEnviroment().endpoints.telematicsDevice + '/telematics-devices/profiles',
    result => {
      const powerProfiles = result.data.profiles as PowerProfile[];
      return sortByString(powerProfiles, powerProfile => powerProfile.name);
    }
  );
}

export const PowerProfilesApi = { getPowerProfiles };
