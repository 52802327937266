import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { OutlinedInput } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { ComponentTypeMap } from './CustomerComponentTypes';
import { useMachineInfoContext } from '../../stores/MachineInfo/MachineInfoProvider';
import { sortByString } from '../../stores/common/Sorting';

interface EditComponentDialogProps {
  show: boolean;
  componentTypeMap: ComponentTypeMap;
  componentType: string;
  onComponentTypeChange: (newComponentType: string) => void;
  componentName: string;
  onComponentNameChange: (newComponentName: string) => void;
  editComponentIndex: number;
  onClose: () => void;
}

export const EditComponentDialog: React.FC<
  EditComponentDialogProps
> = props => {
  const {
    show,
    componentTypeMap,
    componentType,
    onComponentTypeChange,
    componentName,
    onComponentNameChange,
    editComponentIndex,
    onClose
  } = props;
  const machineInfoContext = useMachineInfoContext();

  return (
    <Dialog
      open={show}
      onClose={() => onClose()}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Extended metadata</DialogTitle>
      <DialogContent>
        <FormControl
          variant="outlined"
          fullWidth
          margin="normal"
          disabled={editComponentIndex !== -1}
        >
          <InputLabel htmlFor="ComponentTypes">Type</InputLabel>
          <Select
            value={componentType || ''}
            input={
              <OutlinedInput
                labelWidth={33}
                name="ComponentTypes"
                id="ComponentTypes"
              />
            }
            onChange={(evt: any) => {
              onComponentTypeChange(evt.target.value);
            }}
          >
            {Object.keys(componentTypeMap)
              .filter(key => {
                const extInfo = machineInfoContext.extendedInformation.find(
                  e => e.key === key
                );
                return (
                  extInfo === undefined ||
                  machineInfoContext.extendedInformation.indexOf(extInfo) ===
                    editComponentIndex
                );
              })
              .map(key => (
                <MenuItem key={key} value={key}>
                  {componentTypeMap[key].type}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {componentType && (
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel htmlFor="ComponentNames">Name</InputLabel>
            <Select
              value={componentName || ''}
              input={
                <OutlinedInput
                  labelWidth={42}
                  name="ComponentNames"
                  id="ComponentNames"
                />
              }
              onChange={(evt: any) => {
                onComponentNameChange(evt.target.value);
              }}
            >
              {componentTypeMap[componentType] &&
                sortByString(componentTypeMap[componentType].values, item =>
                  item.prefix ? item.prefix : item.name
                ).map(key => (
                  <MenuItem
                    key={componentType + ' - ' + key.name}
                    value={key.name}
                  >
                    {(key.prefix ? '(' + key.prefix + ') ' : '') + key.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (editComponentIndex === -1) {
              machineInfoContext.addExtendedMetadata({
                key: componentType,
                value: componentName
              });
            } else {
              machineInfoContext.updateExtendedMetadata(editComponentIndex, {
                key: componentType,
                value: componentName
              });
            }
            onClose();
          }}
          color="primary"
          autoFocus
          variant="contained"
          disabled={componentType.length === 0 || componentName.length === 0}
        >
          Close
        </Button>

        <Button onClick={() => onClose()} color="default" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
