import axios from 'axios';
import { getEnviroment } from 'env';
import { defaultAxiosOptions } from '../common/DefaultAxiosOptions';
import { AccessToken } from '@okta/okta-auth-js';
import { DeviceTypeEnum } from './DeviceTypeEnum';

const telematicsDeviceV2Endpoint = getEnviroment().endpoints.telematicsDeviceV2;

export interface DeviceIdentity {
  id: string,
  serialNumber: string,
  externalSerialNumber: string;
  productionDate?: string,
  deviceType: DeviceTypeEnum,
}

function getIdentity(
  accessToken: AccessToken | undefined,
  serialNumber: string
): Promise<DeviceIdentity> {
  return axios
    .get<any>(
      `${telematicsDeviceV2Endpoint}/oems/trackunit/devices/${serialNumber}`,
      defaultAxiosOptions(accessToken)
    )
    .then(identity => {
      return {
        id: identity.data.id,
        serialNumber,
        externalSerialNumber: identity.data.serialNumber,
        productionDate: identity.data.productionDate,
        deviceType: identity.data.deviceType
      } as DeviceIdentity;
    })
    .catch(error => {
      throw new Error(
        `An Unexpected error occurred while trying to fetch device identity of '${serialNumber} - server returned no data`
      );
    });
}

export const DeviceIdentityApi = { getIdentity };
