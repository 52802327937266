import {AuthState} from "@okta/okta-auth-js";
import {IOState, DeviceStateApi} from "../TelematicsDevice/DeviceStateApi";

export const convertToDesiredState = (currentDesiredIOState: IOState | undefined,
                                      input1Name?: string,
                                      input2Name?: string,
                                      input3Name?: string,
                                      input4Name?: string,
                                      input5Name?: string,
                                      input6Name?: string,
                                      preRun1?: number,
                                      preKm?: number,
                                      deviceTypeSupportsOffsets?: boolean) => {

  let desiredState: IOState = {};

  if( input1Name && input1Name != currentDesiredIOState?.io?.input1?.name) {
    desiredState = {io: {...desiredState.io, input1: {...desiredState?.io?.input1, name: input1Name}}}
  }

  if(deviceTypeSupportsOffsets && preRun1 !== undefined && preRun1 != currentDesiredIOState?.io?.input1?.offset) {
    desiredState = {io: {...desiredState.io, input1: {...desiredState?.io?.input1, offset: preRun1}}}
  }

  if( input2Name && input2Name != currentDesiredIOState?.io?.input2?.name) {
    desiredState = {io: {...desiredState.io, input2: {name: input2Name}}}
  }

  if( input3Name && input3Name != currentDesiredIOState?.io?.input3?.name) {
    desiredState = {io: {...desiredState.io, input3: {name: input3Name}}}
  }

  if( input4Name && input4Name != currentDesiredIOState?.io?.input4?.name) {
    desiredState = {io: {...desiredState.io, input4: {name: input4Name}}}
  }

  if( input5Name && input5Name != currentDesiredIOState?.io?.input5?.name) {
    desiredState = {io: {...desiredState.io, input5: {name: input5Name}}}
  }

  if( input6Name && input6Name != currentDesiredIOState?.io?.input6?.name) {
    desiredState = {io: {...desiredState.io, input6: {name: input6Name}}}
  }

  if (deviceTypeSupportsOffsets && preKm != undefined && preKm != currentDesiredIOState?.sensor?.distance?.offset) {
    desiredState = {...desiredState, sensor: { distance: { offset: preKm } } };
  }

  return desiredState;
}

export const saveDesiredState = (
  authState: AuthState | null,
  deviceId: string,
  currentDesiredIOState: IOState | undefined,
  input1Name?: string,
  input2Name?: string,
  input3Name?: string,
  input4Name?: string,
  input5Name?: string,
  input6Name?: string,
  preRun1?: number,
  preKm?: number,
  deviceTypeSupportsOffsets?: boolean,
)
  : Promise<any> => {

  const desiredState: IOState = convertToDesiredState(
    currentDesiredIOState,
    input1Name,
    input2Name,
    input3Name,
    input4Name,
    input5Name,
    input6Name,
    preRun1,
    preKm,
    deviceTypeSupportsOffsets,
  );

  return DeviceStateApi.updateState(
        authState?.accessToken,
        deviceId,
        desiredState
  );
}