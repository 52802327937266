import React, {useRef} from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '../../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './Login.css';
import { useOktaAuth } from '@okta/okta-react';
import { Tokens } from "@okta/okta-auth-js";
import { Redirect } from 'react-router-dom';

interface Props {
  logo?: string;
  idps?: { type: string; text: string; id: string }[];
  oktaConfig: {issuer: string, clientId: string, pkce: boolean, redirectUri: string, scopes: string[]}
}

export const Login: React.FC<Props> = props => {
  const { authState, oktaAuth } = useOktaAuth();

  const widgetRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const currentHref = window.location.href;
    const successLoginArg = 'fromLogin=true';
    if (currentHref.includes(successLoginArg)) {
      oktaAuth.session.get().then(session => {
        if (session && session.status === "ACTIVE") {
          oktaAuth.token.getWithoutPrompt({
            responseType: "id_token",
          }).then(tokenResponse => {
            oktaAuth.tokenManager.setTokens(tokenResponse.tokens);
            let originalUri = oktaAuth.getOriginalUri();
            if( originalUri !== undefined)
              window.location.replace(originalUri);
          });
        }
      });
    } else {
      if (!widgetRef.current)
        return;

      const config = {
        baseUrl: props.oktaConfig.issuer.split('/oauth2')[0],
        clientId: props.oktaConfig.clientId,
        redirectUri: props.oktaConfig.redirectUri,
        logo: props.logo,
        idps: props.idps,
        idpDisplay: 'PRIMARY', // Position of IdP (PRIMARY: top, SECONDARY: bottom)
        //username: 'abc',        // Option to pre-fill username
        features: {
          idpDiscovery: true,
          selfServiceUnlock: false,
          smsRecovery: false,
          callRecovery: false,
          emailRecovery: false
        },
        idpDiscovery: {
          requestContext: window.location.href
        },
        authParams: {
          issuer: props.oktaConfig.issuer,
          scopes: props.oktaConfig.scopes
        },
        //useClassicEngine: true
      };

      oktaAuth.setOriginalUri(window.location.href);
      const widget = new OktaSignIn(config);

      widget.showSignInToGetTokens({
        el: widgetRef.current,
      }).then((tokens: Tokens) => {
        oktaAuth.handleLoginRedirect(tokens);
      })
        .catch((err:any ) => {
          console.error('unexpected error', err)
        });

      return () => widget?.remove?.();
    }

  }, [oktaAuth]);

  if (authState?.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <div ref={widgetRef} />;
};
