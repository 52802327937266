import * as React from 'react';
import './AdvancedSensorsDataList.scss';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { Timeline } from "./Timeline";
import { AdvancedSensorData } from "../../stores/AdvancedSensorsData/AdvancedSensorsDataApi";


interface Props {
  advancedSensorsData: AdvancedSensorData[];
}

export const AdvancedSensorsDataList: React.FC<Props> = props => {
  return (
    <List className="can-data-list">
      {props.advancedSensorsData.map(advancedSensorData => (
        <ListItem key={advancedSensorData.sensorId} className="can-data-list-item">
          <ListItemText
            primary={
              <Typography component="span" variant="subtitle2">
                {advancedSensorData.sensorName}
              </Typography>
            }
            secondary={
              <>
                { advancedSensorData.latestData?.value != null ? (
                  <>
                    {advancedSensorData.latestData.timestamp && <Typography component={'span'} variant="caption">
                      {advancedSensorData.latestData.timestamp.toLocaleString()}
                    </Typography>}
                    <Typography component={'span'} variant="body1">
                      {advancedSensorData.latestData.value} {advancedSensorData.latestData.type !== "SensorEnumValue" && advancedSensorData.unit}
                    </Typography>
                    { advancedSensorData.data?.length > 1 && (
                      <Timeline data={advancedSensorData.data.filter(d => d.type === "SensorFloatValue" || d.type === "SensorBooleanValue").map(d => [d.timestamp.getTime(), d.value])} height={60} />
                    )}
                    </>
                ) : (
                  'No data'
                )}
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
