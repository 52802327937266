import * as React from 'react';
import './UserInfo.css';
import { Button } from '@material-ui/core';
import { decompileToken, getUserInfo } from 'pages/Login/AuthenticationService';
import { useAnalytics } from 'components/analytics/analytics';

import {withOktaAuth} from '@okta/okta-react';
import {AuthState, OktaAuth} from '@okta/okta-auth-js';

interface Props {
  authState: AuthState;
  oktaAuth: OktaAuth;
}

const UserInfo: React.FC<Props> = props => {
  const token = props.authState?.accessToken?.accessToken;
  const userIdentifier = token ? decompileToken(token).sub : null;
  const analytics = useAnalytics();

  const handleLogout = async () => {
    await props.oktaAuth.signOut()
    analytics.updateUserInformation(getUserInfo(props.authState?.accessToken));
  };

  return (
    <div className="UserInfo">
      <span>{userIdentifier} </span>
      <Button
        onClick={handleLogout}
        variant="contained"
        size="medium"
        color="primary"
      >
        Log out
      </Button>
    </div>
  );
};

export default withOktaAuth(UserInfo);
