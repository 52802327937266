import React from 'react';
import { StatusContextValue, useContextStatus } from '../common/ContextStatus';
import { useOktaAuth } from '@okta/okta-react';
import { DeviceAccessControlApi } from './DeviceAccessControlApi';
import {AccessControlMode} from '../../components/DeviceAccessControl/DeviceAccessControl';

type DeviceAccessControlContextValue = StatusContextValue & {
  mode: AccessControlMode | undefined;
  setMode: (mode: AccessControlMode | undefined) => void;
};

const DeviceAccessControlContext = React.createContext<
    DeviceAccessControlContextValue | undefined
>(undefined);

type DeviceAccessControlProviderProps = {
  children: React.ReactNode;
};

const DeviceAccessControlProvider = (props: DeviceAccessControlProviderProps) => {
  const [mode, setMode] = React.useState<AccessControlMode | undefined>(undefined);

  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });

  const value: DeviceAccessControlContextValue = React.useMemo(
    () => ({
      mode,
      setMode,
      contextStatus,
      setContextStatus
    }),
    [mode, contextStatus]
  );

  return <DeviceAccessControlContext.Provider value={value} {...props} />;
};

const useDeviceAccessControlContext = () => {
  const context = React.useContext(DeviceAccessControlContext);
  const { authState } = useOktaAuth();

  if (!context) {
    throw new Error(
      'useDeviceAccessControlContext must be used within an DeviceAccessControlProvider'
    );
  }

  const status = useContextStatus(context);
  const { mode, setMode, contextStatus } = context;

  const save = (serialNumber: string) => {
    status.savingStarted();

    if (mode === undefined) {
      return Promise.reject('Access control mode is undefined');
    }

    return DeviceAccessControlApi.update(authState?.accessToken, serialNumber, mode)
      .then(() => {
        status.succeed('Access control mode saved');
        setMode(mode);
      })
      .catch(err =>
        status.failed(err.message, 'Could not update the Access control mode')
      );
  };

  return {
    mode,
    setMode,

    save,

    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { DeviceAccessControlProvider, useDeviceAccessControlContext };
