import amplitude from 'amplitude-js';
import { getEnviroment } from 'env';

export interface LoggerStrategy {
  logEvent: (event: string, properties: any) => void;
  setUserInfo: (
    userInfo: { userId: number; customerId: number; login: string } | null
  ) => void;
}

export const localLogger = (): LoggerStrategy => {
  const logEvent = (event: string, properties: any) => {
    console.log('ANALYTICS.logEvent -> ', { event }, { properties });
  };

  const setUserInfo = (
    userInfo: { userId: number; customerId: number; login: string } | null
  ) => {
    // TODO: add user information
  };

  return { logEvent, setUserInfo };
};

export const amplitudeLogger = (apiKey: string): LoggerStrategy => {
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(apiKey);

  amplitudeInstance.setUserProperties({
    host: window.location.host
  });

  const setUserInfo = (
    userInfo: { userId: number; customerId: number; login: string } | null
  ) => {
    if (userInfo) {
      amplitudeInstance.setUserId(userInfo.customerId + '_' + userInfo.userId);
      amplitudeInstance.setUserProperties({
        '[TU] CustomerId': userInfo.customerId,
        '[TU] UserId': userInfo.userId,
        'user login': userInfo.login.toLowerCase(),
        host: window.location.host
      });
    } else {
      amplitudeInstance.setUserId('');
      amplitudeInstance.clearUserProperties();
    }
  };

  const logEvent = (event: string, properties: any) => {
    amplitudeInstance.logEvent(event, properties);
  };

  return { logEvent, setUserInfo };
};

let loggerStrategy: LoggerStrategy | undefined;
export const loggerStategyFactory = (): LoggerStrategy => {
  const amplitudeAppKey = getEnviroment().amplitudeApiKey;
  if (amplitudeAppKey === undefined) {
    loggerStrategy = localLogger();
  } else {
    loggerStrategy = amplitudeLogger(amplitudeAppKey);
  }

  return loggerStrategy;
};
