import DateDiff from "date-diff";

export const formatDateTime = (datetime: string) => {
  return new Date(datetime).toLocaleString();
};

export const dateDiff = (datetime1: string | undefined, datetime2: string | undefined) => {
  if (!datetime1 || !datetime2) {
    return undefined;
  }

  const date1 = new Date(datetime1);
  const date2 = new Date(datetime2);

  return new DateDiff(date1, date2);
}

export const formatDateAsISO8601 = (date: Date): string => {
  return (
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2)
  );
};

export const getDateOneDayBefore = (date: Date): Date => {
  const dayBefore: Date = new Date(date);
  dayBefore.setDate(date.getDate() - 1);
  return dayBefore;
};

export const getNowMinusMinutes = (minutes?: number): Date => {
  const minutesToSubstract = minutes ? minutes : 0;
  return new Date(Date.now() - (minutesToSubstract * 60_000));
}
