import { GET_LATEST_ADVANCED_SENSORS_DATA, GET_HISTORICAL_ADVANCED_SENSORS_DATA } from "./AdvancedSensorsData.graphql";
import { getNowMinusMinutes } from "../../../../components/TimeAndDate";
import { queryData } from "../ManagerGraphQLApi";

const GetVariables = {
    includeReportMessageSensors: false,
    systemOfMeasurement: "SI"
}

const GetHistoricalVariables = {
    interval: {
        from: getNowMinusMinutes(60),
        to: getNowMinusMinutes()
    },
    includeReportMessageSensors: false,
    granularity: "MAXONEPERMINUTE",
    systemOfMeasurement: "SI"
}

export type AdvancedSensorData = {
    sensorId: number;
    type: string;
    instance: string;
    dataType: string;
    sensorName: string;
    sensorType: string;
    unit: string;
    latestData: AdvancedSensorDataFloatValue | AdvancedSensorDataStringValue | AdvancedSensorDataBooleanValue | AdvancedSensorDataBinaryValue | AdvancedSensorDataEnumValue;
    data: [AdvancedSensorDataFloatValue, AdvancedSensorDataStringValue, AdvancedSensorDataBooleanValue, AdvancedSensorDataBinaryValue | AdvancedSensorDataEnumValue];
}

export type CanVariables = {
    sensorType: string,
    variableId: number,
    type: string
}

export interface AdvancedSensorDataFloatValue {
    timestamp: Date;
    value: number;
    type: string;
}

export interface AdvancedSensorDataStringValue {
    timestamp: Date;
    value: string;
    type: string;
}

export interface AdvancedSensorDataBooleanValue {
    timestamp: Date;
    value: boolean;
    type: string;
}

export interface AdvancedSensorDataBinaryValue {
    timestamp: Date;
    value: string;
    type: string;
}

export interface AdvancedSensorDataEnumValue {
    timestamp: Date;
    value: string;
    type: string;
}

const parseValue = (dataPoint: any) => {
    switch (dataPoint.__typename) {
        case "SensorFloatValue":
            return dataPoint.floatValue ? parseFloat(dataPoint.floatValue.toFixed(2)) : dataPoint.floatValue;
        case "SensorBooleanValue":
            return dataPoint.booleanValue ? 1 : 0;
        case "SensorStringValue":
            return dataPoint.stringValue;
        case "SensorBinaryValue":
            return dataPoint.binaryValue;
        case "SensorEnumValue":
            return dataPoint.enumValue.enumValue;
        default:
            return "NA";
    }
}

const getSensorName = (identifier: any) => {
    const sensorName = identifier.sensorName ? identifier.sensorName : identifier.customSensorName;

    if (!sensorName && identifier.sensorType) {
        return identifier.sensorType;
    }

    return sensorName;
}

const getUnit = (unit: any) => {
    const unitAsString = unit?.unit ? unit.unit : 'N/A';
    return unitAsString.replace('BOOLEAN', '');
}

const getLatestAdvancedSensorsData = (dataPoint: any) => {
    return dataPoint.length > 0 ? {
        timestamp: new Date(dataPoint[dataPoint.length - 1].timestamp),
        value: parseValue(dataPoint[dataPoint.length - 1])
    } : {};
}

export const fetchLatestData = async (idToken: string | undefined, assetId: string | undefined) => {
    const data = await queryData('LatestAdvancedSensors', GET_LATEST_ADVANCED_SENSORS_DATA, {
        ...GetVariables,
        assetId: assetId,
        first: 200
    }, idToken);

   return data.asset.advancedSensors.latest.edges?.map((data: any) => (
        {
            sensorId: data.node.classifier.identifier.sensorId ?? data.node.classifier.identifier.variableId,
            sensorType: data.node.classifier.identifier.sensorType,
            sensorName: getSensorName(data.node.classifier.identifier),
            type: data.node.classifier.type,
            instance: data.node.classifier.instance,
            unit: getUnit(data.node.unit),
            data: {...data.node.data},
            latestData: {
                timestamp: new Date(data.node.data.timestamp),
                value: parseValue(data.node.data),
                type: data.node.data.__typename
            }
        })
   ).filter((item: AdvancedSensorData) => item.sensorId != null);
}

export const fetchHistoricalData = async (idToken: string | undefined, assetId: string | undefined, canDataVariables: CanVariables[]) => {
    const data = await queryData('HistoricalAdvancedSensors', GET_HISTORICAL_ADVANCED_SENSORS_DATA,
        {
            ...GetHistoricalVariables,
            assetId: assetId,
            first: 200,
            ids: canDataVariables
        }, idToken);

    return data.asset.advancedSensors.historical.edges.map( (data: any) => (
        {
            sensorId: data.node.classifier.identifier.sensorId ?? data.node.classifier.identifier.variableId,
            sensorType: data.node.classifier.identifier.sensorType,
            sensorName: getSensorName(data.node.classifier.identifier),
            type: data.node.classifier.type,
            instance: data.node.classifier.instance,
            unit: getUnit(data.node.unit),
            data: data.node.data.map((dataPoint: any) => ({
                timestamp: new Date(dataPoint.timestamp),
                value: parseValue(dataPoint),
                type: dataPoint.__typename
            })),
            latestData: getLatestAdvancedSensorsData(data.node.data)
        }));
}
