import { LegacyTelematicsDeviceApi } from '../LegacyTelematicsDevice/LegacyTelematicsDeviceApi';
import {AccessToken} from "@okta/okta-auth-js";

export interface ImportedValues {
  serialNumber?: string;
  name?: string;
  engineHours?: string;
  group?: string;
  brand?: string;
  model?: string;
  category?: string;
  productionDate?: string;
  vin?: string;
  validation: ImportedValuesValidation;
}

export interface ImportedValuesValidation {
  engineHoursValid: boolean;
  groupValid: boolean;
  brandValid: boolean;
  categoryValid: boolean;
  productionDateValid: boolean;
  allValid: boolean;
}

const isValidDate = (input: string) => {
  const date = new Date(input);
  const validDate = !isNaN(date.valueOf());
  const validFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(input);
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 12);
  const dateMoreThanOneYearInFuture =
    date.setHours(0, 0, 0, 0) > maxDate.setHours(0, 0, 0, 0);
  return validDate && validFormat && !dateMoreThanOneYearInFuture;
};

const validateSerialNumber = (
  accessToken: AccessToken | undefined,
  serialNumber: string | null
): Promise<boolean> => {
  if (!serialNumber) {
    return Promise.resolve(false);
  }

  return LegacyTelematicsDeviceApi.getTelematicsDevice(accessToken, serialNumber)
    .then(() => true)
    .catch(() => false);
};

const getSerialNumber = (
  accessToken: AccessToken | undefined,
  searchParams: string
): Promise<string> => {
  const query = new URLSearchParams(searchParams);
  const serialNumber = query.get('serialNumber');

  return validateSerialNumber(accessToken, serialNumber).then(
    serialNumberValid => {
      return serialNumberValid
        ? Promise.resolve(serialNumber!)
        : Promise.reject();
    }
  );
};

const parseSearchParams = (
  searchParams: string,
  brandNames: string[],
  categoryNames: string[],
  groupNames: string[]
): ImportedValues => {
  const query = new URLSearchParams(searchParams);

  const serialNumber = query.get('serialNumber');
  const name = query.get('name');
  const engineHours = query.get('engineHours');
  const group = query.get('group');
  const brand = query.get('brand');
  const model = query.get('model');
  const category = query.get('category');
  const productionDate = query.get('productionDate');
  const vin = query.get('vin');

  const isValidEngineHours = (engineHours: string) => {
    if (/^-?\d+$/.test(engineHours)) {
      const value = Number.parseInt(engineHours);
      return value <= 596523 && value >= -596523;
    }
    return false;
  };

  const engineHoursValid = !engineHours || isValidEngineHours(engineHours);

  const groupValid = group
    ? groupNames.includes(decodeURIComponent(group))
    : true;

  const brandValid = brand
    ? brandNames.includes(decodeURIComponent(brand))
    : true;

  const categoryValid = category
    ? categoryNames.includes(decodeURIComponent(category))
    : true;

  const productionDateValid = productionDate
    ? isValidDate(productionDate)
    : true;

  return {
    serialNumber: serialNumber ? serialNumber : ' ',
    name: name ? decodeURIComponent(name) : undefined,
    engineHours: engineHours ? engineHours : undefined,
    group: group ? group : undefined,
    brand: brand ? decodeURIComponent(brand) : undefined,
    model: model ? decodeURIComponent(model) : undefined,
    category: category ? decodeURIComponent(category) : undefined,
    productionDate: productionDate ? productionDate : undefined,
    vin: vin ? vin : undefined,
    validation: {
      engineHoursValid,
      groupValid,
      brandValid,
      categoryValid,
      productionDateValid,
      allValid:
        brandValid && categoryValid && productionDateValid && engineHoursValid
    }
  };
};

export const ImportedValuesService = { getSerialNumber, parseSearchParams };
