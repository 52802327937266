import React from 'react';
import {useLegacyTelematicsDeviceContext} from 'pages/Unitlookup/stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import GenericConfigButton from '../GenericConfiguration/GenericConfigButton';
import {AnalyticsEvents} from '../../../../components/analytics/analyticsEvents';
import {
  ConfigurationTemplate,
  TelematicsDeviceConfigurationV2Api
} from '../../stores/TelematicsDeviceConfiguration/TelematicsDeviceConfigurationV2Api';
import {AuthState} from "@okta/okta-auth-js";

interface Props {
  authState: AuthState | null;
  configuration: ConfigurationEnum;
}

export enum ConfigurationEnum {
  HILTI_SERVICE_VAN_CONFIGURATION_NAME = 'setup-hilti-service-van',
  HILTI_BLE_SCANNING_CONFIGURATION_NAME = 'setup-hilti-ble-scanning',
}

const Configuration: React.FC<Props> = props => {
  const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
  const [configurationTemplate, setConfigurationTemplate] = React.useState<ConfigurationTemplate | null>(null);

  React.useEffect(() => {
    if (telematicsDevice) {
      TelematicsDeviceConfigurationV2Api.getDeviceConfigurationTemplates(
        props.authState?.accessToken,
        telematicsDevice.serialNumber
      ).then((configurationTemplates: ConfigurationTemplate[]) => {
        if( configurationTemplates) {
          const serviceVanTemplates = configurationTemplates.filter(configurationTemplate => configurationTemplate.href.includes(props.configuration));
          if (serviceVanTemplates.length === 1) {
            setConfigurationTemplate(serviceVanTemplates[0]);
          }
        }
      });
    } else {
      setConfigurationTemplate(null);
    }
  }, [telematicsDevice]);

  const executeConfiguration = () => {
    if (!!configurationTemplate && !!telematicsDevice) {
      const configuration$ = TelematicsDeviceConfigurationV2Api.pushConfigurationToDevice(props.authState?.accessToken, configurationTemplate, telematicsDevice.serialNumber);

      return Promise.all([configuration$])
          .then(() => Promise.resolve())
          .catch(() => Promise.reject(new Error(`${configurationTemplate?.name} configuration failed.`)));
    }
    return Promise.reject(new Error('Missing required data to perform device setup.'));
  };

  return (
      <GenericConfigButton
        key={configurationTemplate?.href}
        visible={!!configurationTemplate}
        buttonLabel={configurationTemplate?.name ?? ""}
        confirmLabel={`Are you sure you want to execute configuration: ${configurationTemplate?.name}?`}
        analyticsEvent={AnalyticsEvents.Configuration}
        performConfiguration={() => executeConfiguration()}
      />
  );
};

export default Configuration;
