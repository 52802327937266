import React from 'react';
import { Snackbar, Fade } from '@material-ui/core';

interface SuccessDialogProps {
  title?: string;
  message: string;
  onDismis?: () => void;
}

export const SuccessDialog: React.FC<SuccessDialogProps> = props => {
  const { message, onDismis } = props;
  const [open, setOpen] = React.useState<boolean>(true);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      autoHideDuration={3000}
      onClose={() => {
        setOpen(false);
        onDismis && onDismis();
      }}
      TransitionComponent={Fade}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={message}
    />
  );
};
