import { AnalyticsEvents } from './analyticsEvents';
import { LoggerStrategy } from './logger';

let logger: LoggerStrategy | undefined;

export const useAnalytics = () => {
  const setLoggerStrategy = (loggerStrategy: LoggerStrategy) => {
    logger = loggerStrategy;
  };

  const logEvent = (
    event: AnalyticsEvents,
    parameters: any,
    duration_ms?: number
  ) => {
    if (logger === undefined) throw new Error('Logger not defined');

    const properties = {
      host: window.location.host,
      search_params: window.location.search,
      duration_ms,
      ...parameters
    };

    logger.logEvent(event, properties);
  };

  const traceEvent = async (
    action: Promise<any>,
    event: AnalyticsEvents,
    data: any
  ) => {
    const startTime = new Date();

    try {
      await action;
      return logEvent(
        event,
        {
          ...data,
          success: true
        },
        Date.now().valueOf() - startTime.valueOf()
      );
    } catch (err) {
      logEvent(
        event,
        {
          ...data,
          success: false
        },
        Date.now().valueOf() - startTime.valueOf()
      );
    }
  };

  return {
    logEvent,
    traceEvent,
    setLoggerStrategy,
    updateUserInformation: logger
      ? logger.setUserInfo
      : () => {
          throw new Error('Logger not set');
        }
  };
};
