import React from 'react';
import {
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { useDeviceContext } from 'pages/Unitlookup/stores/TelematicsDevice/TelematicsDeviceProvider';
import {
  MachineInfoApi,
  ExtendedInfo,
  Brand,
  Category
} from 'pages/Unitlookup/stores/MachineInfo/MachineInfoApi';
import { getUserInfo } from 'pages/Login/AuthenticationService';
import './MachineData.scss';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import { formatDateAsISO8601 } from 'components/TimeAndDate';
import { useAnalytics } from 'components/analytics/analytics';
import { AnalyticsEvents } from 'components/analytics/analyticsEvents';
import { SuccessDialog } from '../SuccessDialog';
import { AlertDialog } from '../AlertDialog';
import { useMachineInfoContext } from '../../stores/MachineInfo/MachineInfoProvider';
import { withOktaAuth } from '@okta/okta-react';
import IconButton from '@material-ui/core/IconButton';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ComponentTypeMap,
  CustomerComponentTypes
} from './CustomerComponentTypes';
import { CUSTOMER_TERBERG, CUSTOMER_TRACKUNIT_CONSTRUCTION } from './Customers';
import { sortByString } from '../../stores/common/Sorting';
import { EditComponentDialog } from './EditComponentDialog';
import {IOktaContext} from "@okta/okta-react/bundles/types/OktaContext";

interface MachineMetadataSaved {
  success: boolean;
  errorMessage?: string;
}

const MachineData: React.FC<IOktaContext> = props => {
  const customersAllowEditExtendedMetadata = [
    CUSTOMER_TRACKUNIT_CONSTRUCTION,
    CUSTOMER_TERBERG
  ];
  const analytics = useAnalytics();
  const { identity } = useDeviceContext();
  const userInfo = getUserInfo(props.authState?.accessToken);
  if (!userInfo) {
    throw new Error('User login not found');
  }

  const { customerId, userId } = userInfo;

  const machineInfoContext = useMachineInfoContext();

  const [brands, setBrands] = React.useState<Brand[] | null>(null);
  const [categories, setCategories] = React.useState<Category[] | null>(null);
  const [productionDate, setProductionDate] = React.useState<Date | null>(null);
  const [machineMetadataSaved, setMachineMetadataSaved] = React.useState<
    MachineMetadataSaved | undefined
  >(undefined);
  const [saveButtonEnabled, setSaveButtonEnabled] = React.useState(true);
  const [initialDataLoaded, setInitialDataLoaded] = React.useState<boolean>(
    false
  );
  const [noAccess, setNoAccess] = React.useState<boolean>(false);

  const [componentDialogOpen, setComponentDialogOpen] = React.useState<boolean>(
    false
  );
  const [componentType, setComponentType] = React.useState<string>('');
  const [componentName, setComponentName] = React.useState<string>('');
  const [componentTypeMap, setComponentTypeMap] = React.useState<
    ComponentTypeMap
  >({});
  const [editComponentIndex, setEditComponentIndex] = React.useState<number>(
    -1
  );
  const [
    allowEditExtendedMetadata,
    setAllowEditExtendedMetadata
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    let isCancelled = false;

    Promise.all<Brand[], Category[]>([
      MachineInfoApi.getBrands(props.authState?.accessToken),
      MachineInfoApi.getCategories(props.authState?.accessToken)
    ]).then(([brands, categories]) => {
      if (!isCancelled) {
        setBrands(brands);
        setCategories(categories);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  React.useEffect(() => {
    setComponentTypeMap(CustomerComponentTypes.getComponentTypes(customerId));
    setAllowEditExtendedMetadata(
      customersAllowEditExtendedMetadata.includes(customerId)
    );
  }, [customerId]);

  React.useEffect(() => {
    if (machineInfoContext.hasContent && machineInfoContext.productionDate) {
      setProductionDate(new Date(machineInfoContext.productionDate));
    } else {
      setProductionDate(null);
    }
  }, [machineInfoContext.hasContent, machineInfoContext.productionDate]);

  React.useEffect(() => {
    setInitialDataLoaded(!machineInfoContext.loading);
    if (!machineInfoContext.loading) {
      if (machineInfoContext.hasContent) {
        setNoAccess(false);
      } else {
        setNoAccess(true);
      }
    }
  }, [machineInfoContext.loading, machineInfoContext.hasContent]);

  function capitalizeFirstLetter(key: string): string {
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  function resetComponent() {
    setEditComponentIndex(-1);
    setComponentType('');
    setComponentName('');
  }

  const extendedMetadataReadonlyView = (arr: Array<ExtendedInfo>) => (
    <span className="extended">
      {arr.map((item: ExtendedInfo) => (
        <span key={item.key} className="extendedItemReadonly">
          <span className="extendedKey">
            {capitalizeFirstLetter(item.key)}:
          </span>{' '}
          {item.value}
        </span>
      ))}
    </span>
  );

  const extendedMetadataEditableView = (arr: Array<ExtendedInfo>) =>
    arr.map((item: ExtendedInfo, idx: number) => (
      <div className="extendedItemEditable" key={item.key}>
        {!componentTypeMap[item.key] ? (
          <TextField
            value={item.value || ''}
            label={
              (componentTypeMap[item.key] && componentTypeMap[item.key].type) ||
              item.key
            }
            name={item.key}
            onChange={evt =>
              machineInfoContext.updateExtendedMetadata(idx, {
                key: item.key,
                value: evt.target.value
              })
            }
            fullWidth
            variant="outlined"
            margin="normal"
          />
        ) : (
          <TextField
            value={item.value || ''}
            label={
              (componentTypeMap[item.key] && componentTypeMap[item.key].type) ||
              item.key
            }
            name={item.key}
            onClick={() => {
              setEditComponentIndex(idx);
              setComponentType(item.key);
              setComponentName(item.value);
              setComponentDialogOpen(true);
            }}
            fullWidth
            variant="outlined"
            margin="normal"
            disabled={true}
          />
        )}
        <IconButton
          aria-label="delete"
          className="deleteButton"
          onClick={() => {
            machineInfoContext.deleteExtendedMetadata(idx);
          }}
        >
          <DeleteIcon className="deleteIcon" />
        </IconButton>
      </div>
    ));

  const listItems = (arr: Array<ExtendedInfo>) => {
    sortByString(arr, extendedInfo => extendedInfo.key);
    return allowEditExtendedMetadata
      ? extendedMetadataEditableView(arr)
      : extendedMetadataReadonlyView(arr);
  };

  function saveMachineMetadata() {
    setSaveButtonEnabled(false);
    machineInfoContext
      .save(identity.serialNumber, customerId, userId, allowEditExtendedMetadata)
      .then(() => {
        logAnalyticsEventSavedSuccessfully(true);
        setMachineMetadataSaved({ success: true });
      })
      .catch((error: any) => {
        logAnalyticsEventSavedSuccessfully(false);
        console.error('Failed to save machine metadata', error);
        setMachineMetadataSaved({
          success: false,
          errorMessage: error.message
        });
      })
      .finally(() => {
        setSaveButtonEnabled(true);
      });
  }

  function handleProductionDateChange(date: Date | null) {
    setProductionDate(date);
    if (date) {
      machineInfoContext.setProductionDate(formatDateAsISO8601(date));
    }
  }

  function getMaxDate(): Date {
    const date = new Date();
    date.setMonth(date.getMonth() + 12);
    return date;
  }

  function logAnalyticsEventSavedSuccessfully(saveSuccess: boolean) {
    analytics.logEvent(AnalyticsEvents.MachineMetadataSaved, {
      success: saveSuccess,
      serial: identity.serialNumber
    });
  }

  return (
    <>
      <Typography variant="h5">Machine Metadata</Typography>

      {!initialDataLoaded ? (
        <CircularProgress />
      ) : noAccess ? (
        <div className="noMetadata">
          Unable to load machine metadata for this device
        </div>
      ) : (
        <>
          {brands && (
            <TextField
              select
              value={machineInfoContext.brand || ''}
              label="Brand"
              name="brand"
              onChange={evt =>
                machineInfoContext.setBrand &&
                machineInfoContext.setBrand(evt.target.value)
              }
              margin="normal"
              variant="outlined"
              fullWidth
            >
              {brands.map((brand: Brand) => (
                <MenuItem key={brand.name} value={brand.name}>
                  {brand.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextField
            value={machineInfoContext.model || ''}
            label="Model"
            name="model"
            onChange={evt =>
              machineInfoContext.setModel &&
              machineInfoContext.setModel(evt.target.value)
            }
            fullWidth
            variant="outlined"
            margin="normal"
          />

          {categories && (
            <TextField
              select
              value={machineInfoContext.category || ''}
              label="Category"
              name="category"
              onChange={evt =>
                machineInfoContext.setCategory &&
                machineInfoContext.setCategory(evt.target.value)
              }
              margin="normal"
              variant="outlined"
              fullWidth
            >
              {categories.map((category: Category) => (
                <MenuItem key={category.name} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              maxDate={getMaxDate()}
              value={productionDate}
              label="Production date"
              onChange={handleProductionDateChange}
              format="yyyy-MM-dd"
              className="fullWidthDatePicker"
              inputVariant="outlined"
              margin="normal"
            />
          </MuiPickersUtilsProvider>

          <TextField
            value={machineInfoContext.vin || ''}
            label="VIN"
            name="vin"
            onChange={evt =>
              machineInfoContext.setVin &&
              machineInfoContext.setVin(evt.target.value)
            }
            fullWidth
            variant="outlined"
            margin="normal"
            inputProps={{ style: { textTransform: "uppercase"} }}
          />

          <Typography variant="h6">
            Extended Metadata{' '}
            {allowEditExtendedMetadata && (
              <IconButton
                aria-label="add"
                className="addButton"
                onClick={() => setComponentDialogOpen(true)}
              >
                <PlaylistAddIcon className="addIcon" fontSize="large" />
              </IconButton>
            )}
          </Typography>

          {machineInfoContext.extendedInformation &&
          machineInfoContext.extendedInformation.length > 0 ? (
            listItems(machineInfoContext.extendedInformation)
          ) : (
            <span className="noExtendedMetadata">None</span>
          )}

          <div className="saveButton">
            <Button
              onClick={saveMachineMetadata}
              variant="contained"
              size="large"
              color="primary"
              disabled={!saveButtonEnabled}
            >
              Save machine metadata
            </Button>
          </div>
        </>
      )}

      <EditComponentDialog
        show={componentDialogOpen}
        componentTypeMap={componentTypeMap}
        componentType={componentType}
        onComponentTypeChange={setComponentType}
        componentName={componentName}
        onComponentNameChange={setComponentName}
        editComponentIndex={editComponentIndex}
        onClose={() => {
          resetComponent();
          setComponentDialogOpen(false);
        }}
      />

      {machineMetadataSaved && machineMetadataSaved.success && (
        <SuccessDialog
          message={'Machine metadata saved successfully'}
          onDismis={() => setMachineMetadataSaved(undefined)}
        />
      )}

      {machineMetadataSaved && !machineMetadataSaved.success && (
        <AlertDialog
          title={'Failed to save machine metadata'}
          message={
            machineMetadataSaved.errorMessage
              ? machineMetadataSaved.errorMessage
              : 'Unknown error'
          }
          onDismis={() => setMachineMetadataSaved(undefined)}
        />
      )}
    </>
  );
};

export default withOktaAuth(MachineData);
