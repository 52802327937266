import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AdvancedSensorData, fetchHistoricalData, fetchLatestData } from "./AdvancedSensorsDataApi";
import { LegacyTelematicsDeviceApi } from "../LegacyTelematicsDevice/LegacyTelematicsDeviceApi";

export function useAdvancedSensorsData(serialNumber?: string) {
    const { authState } = useOktaAuth();
    const [latestAdvancedSensorsData, setLatestAdvancedSensorsData] = React.useState<AdvancedSensorData[]>([]);
    const [advancedSensorsData, setAdvancedSensorsData] = React.useState<AdvancedSensorData[]>([]);
    const [assetId, setAssetId] = React.useState<string | undefined>(undefined);
    const [loadingAssetId, setLoadingAssetId] = React.useState(false);
    const [loadingLatest, setLoadingLatest] = React.useState(false);
    const [loadingHistorical, setLoadingHistorical] = React.useState(false);

    const reset = () => {
        setAdvancedSensorsData([]);
        setLatestAdvancedSensorsData([]);
        setAssetId(undefined);
        setLoadingAssetId(false);
        setLoadingLatest(false);
        setLoadingHistorical(false);
    }

    const isLoading = () => {
        return loadingAssetId || loadingLatest || loadingHistorical;
    };

    const reFetchLatestData = () => {
        if (assetId) {
            setLoadingLatest(true);
            fetchLatestData(authState?.idToken?.idToken, assetId).then((data) => {
                setLatestAdvancedSensorsData(data);
                setAdvancedSensorsData(data);
            }).catch((ex) => {
                reset();
                console.log(ex);
            }).finally(() => setLoadingLatest(false))
        }
    }

    React.useEffect(() => {
        if (serialNumber) {
            setLoadingAssetId(true);
            LegacyTelematicsDeviceApi.getTelematicsDeviceAssetId(
                authState?.accessToken,
                serialNumber
            ).then((result: string) => {
                setAssetId(result);
            }).catch((ex) => {
                reset();
                console.log(ex);
            }).finally(() => setLoadingAssetId(false))
        }
    }, [authState?.accessToken, serialNumber]);

    React.useEffect(() => {
        if (assetId) {
            reFetchLatestData();
        }
    }, [authState?.accessToken, assetId]);

    React.useEffect(() => {
        if (latestAdvancedSensorsData && latestAdvancedSensorsData.length > 0) {
            setLoadingHistorical(true);

            const canDataVariables = latestAdvancedSensorsData.map( (advancedSensorData: AdvancedSensorData) => ({
                sensorType: advancedSensorData.sensorType,
                variableId: advancedSensorData.sensorId,
                type: advancedSensorData.type
            }));

            // enrich latest sensors data with historical sensors data
            fetchHistoricalData(authState?.idToken?.idToken, assetId, canDataVariables).then((data) => {
                if (data && data.length > 0) {
                    data.map((historical: AdvancedSensorData) => {
                        const latestData = latestAdvancedSensorsData.find(latest =>
                            (latest.sensorId && latest.sensorId === historical.sensorId) ||
                            (latest.sensorType && latest.sensorType === historical.sensorType)
                        );
                        if (latestData) {
                            latestData.data = historical.data
                        }
                    })
                    setAdvancedSensorsData(latestAdvancedSensorsData);
                }
            }).finally(() => setLoadingHistorical(false))
        }
    }, [authState?.accessToken, latestAdvancedSensorsData]);

    return {advancedSensorsData: advancedSensorsData, isLoading, reFetchLatestData};
}
