import React from 'react';
import { Group, UpdateGroupApi } from './GroupApi';
import { StatusContextValue, useContextStatus } from '../common/ContextStatus';
import { sortByString } from '../common/Sorting';
import { useOktaAuth } from '@okta/okta-react';

type GroupContextValue = StatusContextValue & {
  groups: Group[];
  setGroups: (groups: Group[]) => void;
};

const GroupContext = React.createContext<GroupContextValue | undefined>(
  undefined
);

type GroupProviderProps = {
  children: React.ReactNode;
};

const GroupProvider = (props: GroupProviderProps) => {
  const [groups, setGroups] = React.useState<Group[]>([]);

  const [contextStatus, setContextStatus] = React.useState({
    loading: false,
    saving: false,
    hasContent: false
  });

  const value: GroupContextValue = React.useMemo(
    () => ({ groups, setGroups, contextStatus, setContextStatus }),
    [groups, contextStatus]
  );
  return <GroupContext.Provider value={value} {...props} />;
};

const useGroupContext = () => {
  const context = React.useContext(GroupContext);
  const { authState } = useOktaAuth();

  if (!context) {
    throw new Error('useGroupContext must be used within a GroupProvider');
  }

  const status = useContextStatus(context);
  const { groups, setGroups, contextStatus } = context;

  const load = (unitId: string | undefined): Promise<void> => {
    status.loadingStarted();

    if (unitId === undefined) {
      setGroups([]);
      return Promise.resolve();
    } else
      return UpdateGroupApi.getGroups(authState?.accessToken, unitId.toString())
        .then(newGroups => {
          sortByString(newGroups, group => group.name);
          setGroups(newGroups);
          status.succeed();
        })
        .catch(err => status.failed(err.message, 'Could not load groups'));
  };
  const save = (unitId: string): Promise<void> => {
    status.savingStarted();

    return UpdateGroupApi.getGroups(
      authState?.accessToken,
      unitId
    ).then(
      (persistedGroups: Group[]): Promise<void> => {
        const selectedGroups = groups
          .filter(group => group.selected)
          .map(group => group.id);

        const groupsToRemove = persistedGroups.filter(
          group => group.selected && !selectedGroups.includes(group.id)
        );
        const groupsToAdd = persistedGroups.filter(
          group => !group.selected && selectedGroups.includes(group.id)
        );

        return Promise.all([
          ...groupsToRemove.map(group =>
            UpdateGroupApi.update(authState?.accessToken, {
              id: group.id,
              AddUnits: [],
              RemoveUnits: [unitId]
            })
          ),
          ...groupsToAdd.map(group =>
            UpdateGroupApi.update(authState?.accessToken, {
              id: group.id,
              AddUnits: [unitId],
              RemoveUnits: []
            })
          )
        ])
          .then(() => status.succeed('Groups was saved'))
          .catch(err => status.failed(err.message, 'Could not save groups'));
      }
    );
  };

  const onGroupSelected = (groupId: string, selected: boolean) =>
    setGroups(
      groups.map(group =>
        group.id === groupId ? { ...group, selected } : group
      )
    );

  const onGroupSelectedByName = (groupName: string, selected: boolean) =>
    setGroups(
      groups.map(group =>
        group.name === groupName ? { ...group, selected } : group
      )
    );

  return {
    groups,
    load,
    save,
    onGroupSelected,
    onGroupSelectedByName,
    contextStatus,
    dismissError: status.dismissError,
    dismissSuccess: status.dismissSuccess
  };
};

export { GroupProvider, useGroupContext };
