import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@material-ui/core';
import {useAnalytics} from 'components/analytics/analytics';
import {AnalyticsEvents} from 'components/analytics/analyticsEvents';
import {TelematicsDeviceConfigurationApi, PredefinedCustomerCommand} from "../../stores/TelematicsDeviceConfiguration/TelematicsDeviceConfigurationApi";
import MenuItem from "@material-ui/core/MenuItem";
import {AccessToken} from "@okta/okta-auth-js";

interface Props {
    analyticsEvent: AnalyticsEvents;
    accessToken: AccessToken | undefined;
    serialNumber: string;
    firmwareVersion: number;
    lastSavedCanProfileId?: number;
    commands: PredefinedCustomerCommand[];
    dropdownLabel: string;
}

const CustomerCommandDropdown: React.FC<Props> = props => {
    const analytics = useAnalytics();

    const [commandId, setCommandId] = React.useState('');
    const [commandLabel, setCommandLabel] = React.useState('');
    const [prerequisiteFailureLabel, setPrerequisiteFailureLabel] = React.useState('');

    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showConfirmNotification, setShowExecuted] = React.useState(false);
    const [showPrerequisiteFailure, setShowPrerequisiteFailure] = React.useState(
        false
    );
    const [showFailed, setShowFailed] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | JSX.Element>('');

    const resetStates = () => {
        setCommandId('');
        setErrorMessage('');
        setCommandLabel('');
    }

    const cancel = () => {
        setShowConfirm(false);
        resetStates();
    }
    const closeConfirmNotification = () => {
        setShowExecuted(false);
        resetStates();
    }
    const closePrerequisiteFailure = () => {
        setShowPrerequisiteFailure(false);
        resetStates();
    }
    const closeFailed = () => {
        setShowFailed(false);
        resetStates();
    }

    React.useEffect(() => {
        if (commandId && commandId !== '') {
            confirm();
        }
    }, [commandId]);

    const isValidFirmware = (command: PredefinedCustomerCommand) => {
        return props.firmwareVersion &&
        props.firmwareVersion >= command.minimumFirmwareVersion &&
        (command.maximumFirmwareVersion ? props.firmwareVersion <= command.maximumFirmwareVersion : true);
    }

    const isCanProfileSaved = (command: PredefinedCustomerCommand) => {
        return command.canProfileId ? command.canProfileId === props.lastSavedCanProfileId : true;
    }

    const getPrerequisiteFailureLabel = (command: PredefinedCustomerCommand) => {
        if (!isValidFirmware(command) && command.maximumFirmwareVersion) {
            return `Requires firmware version between ${command.minimumFirmwareVersion} and ${command.maximumFirmwareVersion}`;
        }
        if (!isValidFirmware(command)) {
            return `Requires firmware version ${command.minimumFirmwareVersion} or higher`;
        }
        if (!isCanProfileSaved(command)) {
            return 'Selected can profile has not been saved. Click the Save button before executing this action';
        }
        return 'Click the Save button before executing this action';
    }

    const confirm = () => {
        const command = props.commands.find(c => c.commandId === commandId);
        if (command) {
            if (isCanProfileSaved(command) && isValidFirmware(command)) {
                setCommandLabel(command.label);
                setShowConfirm(true);
            } else {
                setPrerequisiteFailureLabel(getPrerequisiteFailureLabel(command));
                setShowPrerequisiteFailure(true);
            }
        }
    };

    const configure = () => {
        setShowConfirm(false);

        return TelematicsDeviceConfigurationApi.executePredefinedCustomerCommand(
            props.accessToken,
            props.serialNumber,
            commandId
        ).then(() => {
            setShowExecuted(true);
            analytics.logEvent(props.analyticsEvent, {
                success: true,
                serial: props.serialNumber
            });
        })
        .catch((ex) => {
                setErrorMessage(decorateError(ex.message));
                setShowFailed(true);

                analytics.logEvent(props.analyticsEvent, {
                    success: false,
                    serial: props.serialNumber
                })
            }
        );
    };

    const decorateError = (error: string) => {
        let description;
        let action;

        if (error.includes("not allowed for the current CAN profile")) {
            description = "This might be caused by a can profile currently being deployed.";
            action = "Wait a few seconds and try again."
        }

        return (
            <div className="error">
                <div className="title">{error}</div>
                {description && (<div className="description">{description}</div>)}
                {action && (<div className="action">{action}</div>)}
            </div>
        );
    }

    return (
        <>
            <TextField
                select
                value={props.dropdownLabel}
                label={props.dropdownLabel}
                name={props.dropdownLabel}
                onChange={evt => setCommandId(evt.target.value)}
                margin="normal"
                variant="outlined"
                fullWidth
            >
                {props.commands.map((command: PredefinedCustomerCommand) => (
                    <MenuItem key={command.commandId} value={command.commandId}>
                        {command.label}
                    </MenuItem>
                ))}
            </TextField>

            <Dialog
                open={showConfirm}
                onClose={cancel}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle
                    id="dialog-title">{`Are you sure you want to execute the command: ${commandLabel}?`}</DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={configure}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showConfirmNotification}
                onClose={closeConfirmNotification}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">{`Configuration is being processed.`}</DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={closeConfirmNotification}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showPrerequisiteFailure}
                onClose={closePrerequisiteFailure}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">
                    {prerequisiteFailureLabel}
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={closePrerequisiteFailure}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showFailed}
                onClose={closeFailed}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">Error</DialogTitle>
                <DialogContent>
                    {errorMessage}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={closeFailed}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CustomerCommandDropdown;
